<template>
  <header id="header" v-if="profile.nav">
      <v-idle
        @idle="onidle"
        :loop="true"
        :duration="14400"
        style="display: none;" />
    <div @click="closeNav()">
        <router-link tag="a" to="/owner/dashboard" class="logo"><img src="@/assets/img/logo.png" /></router-link>
    </div>
    <nav class="d-none d-md-block d-lg-block" :class="{active: mobileNavToggle}">
        <router-link @click.native="openNav()" data-testid="tabDashboard" tag="a" to="/owner/dashboard">{{profile.nav.dashboard}}</router-link>
        <router-link @click.native="openNav()" data-testid="tabOwners" tag="a" to="/owner/owners">{{profile.parts.parts}}</router-link>
    </nav>
    <section class="utils">
        <span class="chat-toggle" @click="showUnreadChats = !showUnreadChats" :class="{active: showUnreadChats}" v-if="unreadChats.length"><i class="fas fa-comment"></i><b v-if="totalUnread">{{totalUnread}}</b></span>
        <span class="util util--language" v-if="langSwitch"><img v-if="locale === 'nl'" src="@/assets/icons/dutch.svg" /><img v-if="locale === 'en'" src="@/assets/icons/english.svg" />
            <ul class="dropdown-nav">
                <span class="util util--language" v-if="locale === 'en'" data-testid="ownerTabNl" @click="setLang('nl')"><img src="@/assets/icons/dutch.svg" /></span>
                <span class="util util--language" v-if="locale === 'nl'" data-testid="ownerTabEn" @click="setLang('en')"><img src="@/assets/icons/english.svg" /></span>
            </ul>
        </span>
        <span class="util util--user" data @click="showUtils = !showUtils"><i class="fas fa-user"></i></span>
        <ul class="dropdown-nav large main" v-show="showUtils">
            <router-link tag="li" data-testid="tabAccount" to="/owner/account" @click.native="closeNav()">{{profile.nav.account.account}}</router-link>
            <router-link tag="li" data-testid="tabAppointments"  to="/buildingmanager/daily-appointments" @click.native="closeNav()">{{profile.nav.daily_appointments}}</router-link>
            <li class="alt"><a href="/api/v1/logout" data-testid="tabLogout"><i class="fa-regular fa-right-from-bracket" style="margin-right: 3px;"></i> {{profile.nav.account.logout}}</a></li>
        </ul>
    </section>
    <div class="chats-window" v-if="showUnreadChats">
        <i class="fa fa-times" @click="showUnreadChats = false" style="position: absolute; right: 10px; top: 10px; cursor: pointer; font-size: 16px;"></i>
        <h3>{{profile.dashboard.building_manager.messages}}</h3>
        <p v-if="!totalUnread" style="padding-left: 10px; margin-bottom: 10px;">{{profile.dashboard.building_manager.messages_expla}}</p>
        <div class="unread-chats">
            <div class="unread-chat" v-for="(chat, index) in unreadChats" :key="index" v-show="chat.body || chat.media_url">
                <div @click="toIncident(chat, true, false)">
                    <div class="labels">
                        <span class="incident-id">{{chat.id_incident}}</span>
                        <div class="current-user"><i class="fas fa-user"></i> {{chat.assigned_to_name}}</div>
                    </div>
                    <div class="chat-item" v-if="chat.body ||chat.media_url">
                        <span class="rep-name" v-if="chat.reporter_name">{{chat.reporter_name}}</span>
                        <span class="rep-name" v-if="chat.servicedesk_name">{{chat.servicedesk_name}}</span>
                        <span class="role-label" v-if="chat.reporter_name">{{profile.incident_detail.incident_reporter}}</span>
                        <span class="role-label" v-if="chat.servicedesk_name">{{profile.incidents.incidenttable.client}}</span>
                        <div class="message-body">
                            <div v-if="chat.media_url">
                                <img :src="chat.media_url" v-if="chat.media_content_type && chat.media_content_type.includes('image/')" style="max-width: 50px; max-height: 50px; border-radius: 5px;" />
                                <video v-if="chat.media_content_type === 'video/mp4'" style="max-width: 50px; max-height: 50px; border-radius: 5px;" autoplay loop muted><source :src="chat.media_url" type="video/mp4"></video>
                            </div>
                            <p v-else>{{chat.body}}</p><i>•</i><span class="timedate" v-if="chat.date">{{chat.date}}</span>
                        </div>
                        <span class="setasread" @click.stop="setwhatsappasread(chat)"><img src="@/assets/icons/checkchat.svg"></span>
                        <span class="counter"><b>{{chat.count}}</b></span>
                    </div>
                </div>
                <span class="new-window" @click="toIncident(chat, true, true)"><img src="@/assets/icons/open.svg"></span>
            </div>
        </div>
    </div>
    <span class="open-nav d-block d-md-none" @click="openNav()" v-if="!mobileNavToggle"><i class="fas fa-bars"></i></span>
    <span class="open-nav d-block d-md-none" @click="openNav()" v-if="mobileNavToggle"><i class="fa fa-times"></i></span>
  </header>
</template>

<script>
import { mapState } from 'vuex';
import { EventBus } from '@/js/event-bus.js';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';

export default {
    name: 'Header',
    data: function () {
        return {
            mobileNavToggle: false,
            industry: '',
            profile: {},
            locale: '',
            bmInterval: null,
            langSwitch: true,
            authenticated: false,
            isVve: false,
            showUtils: false,
            totalUnread: 0,
            showUnreadChats: false,
            unreadChats: [],
        }
    },
    computed: mapState(['chatRead']),
    watch: {
        chatRead() {
            if(this.chatRead) {
                this.showUnread();
            }
        },
    },
    created() {
        if (window.location.href.includes('id')) {
            this.incidentId = this.getUrlParameter('id');
        }
        let self = this;
        fetch('/api/v1/isauthenticated')
        .then(response => { response.text().then(
            function(data) {
                if (data === 'false') {
                    if (self.incidentId) {
                        window.location = '/signin.html?id=' + self.incidentId;
                    }
                    else {
                        window.location = '/signin.html';
                    }
                }
            });
        });
        this.industry = 'housing';
        this.locale = 'nl';
        this.profile = require('../../assets/locales/' + this.locale + '/' + this.industry + '.json');
        this.showUnread();
    },
    beforeDestroy() {
        clearInterval(this.bmInterval);
    },
    methods: {
        getUrlParameter(name) {
            return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search)||[""])[1].replace(/\+/g, '%20'))||null;
        },
        onidle() {
            window.location = '/signin.html?timeout=true';
        },
        openNav() {
            this.mobileNavToggle = !this.mobileNavToggle;
            let el = document.body;
            if (this.mobileNavToggle) {
                el.classList.add("modal-open");
            }
            if (!this.mobileNavToggle) {
                el.classList.remove("modal-open");
            }
        },
        closeNav() {
            this.mobileNavToggle = false;
            let el = document.body;
            el.classList.remove("modal-open");
            this.showUtils = false;
        },
        setLang(lang) {
            this.locale = lang;
            let busObj = {
                locale: lang,
                industry: this.industry
            }
            localStorage.setItem('S1-locale', lang);
            EventBus.$emit('langChange', busObj);
            this.profile = require('../../assets/locales/' + this.locale + '/' + this.industry + '.json');
        },
        pad(n) {
            return n < 10 ? '0' + n : n
        },
        showUnread(){
            let self = this;
            fetch('/api/v1/chat/unreadall')
            .then(response => { response.json().then(
                function(data) {
                    self.$store.dispatch('updateChatRead2');
                    self.unreadChats = [];
                    self.totalUnread = 0;
                    
                    let serviceDeskChats = data.servicedesk;
                    if (serviceDeskChats.length) {
                        for (var i = 0; i < serviceDeskChats.length; i++) {
                            let item = serviceDeskChats[i];
                            let dateSent = new Date(item.created);
                            let month = dateSent.getMonth() + 1;
                            month = month.toString();
                            let dateStr = dateSent.getDate() + " " + self.profile.months_numbered[month]  + ", " + dateSent.getHours() + ":"+self.pad(dateSent.getMinutes());
                            item.date = dateStr;
                            item.channel = 'servicedesk';
                            self.unreadChats.push(item);
                        }
                    }
                    if (self.unreadChats.length) {
                        self.unreadChats = uniqBy(self.unreadChats, 'id_incident');
                        for (var k = 0; k < self.unreadChats.length; k++) {
                            let item = self.unreadChats[k];
                            if (item.body || item.media_content_type) {
                                let totalCount = item.count;
                                if (item.ownerCount && item.body !== item.ownerBody) {
                                    totalCount = totalCount + item.ownerCount;
                                }
                                self.totalUnread = self.totalUnread + totalCount;
                            }
                        }
                    }
                    self.unreadChats = sortBy(self.unreadChats, 'created');
                });
            });
        },
        toIncident(incident, openChat, newWindow) {
            let openChatParam = '';
            let currentIncidentId = '';
            if (window.location.href.includes('id=')) {
                currentIncidentId = this.getUrlParameter('id');
            }
            if (openChat) {
                if (incident.type === 'service_desk_to_owneruser' || incident.type === 'firestore') {
                    openChatParam = 'servicedeskChat';
                }
            }
            if (newWindow) {
                if (currentIncidentId && currentIncidentId !== incident.id_incident) {
                    const routeData = this.$router.resolve({path: '/owner/incident-detail', query: {id: incident.id_incident, openchat: openChatParam}});
                    window.open(routeData.href, '_blank');
                }
                if (!currentIncidentId) {
                    const routeData = this.$router.resolve({path: '/owner/incident-detail', query: {id: incident.id_incident, openchat: openChatParam}});
                    window.open(routeData.href, '_blank');
                }
            }
            else {
                if (currentIncidentId && currentIncidentId !== incident.id_incident.toString()) {
                    this.$router.push('/owner/incident-detail?id=' + incident.id_incident + '&openchat=' + openChatParam);
                    this.showUnreadChats = false;
                }
                if (!currentIncidentId) {
                    this.$router.push('/owner/incident-detail?id=' + incident.id_incident + '&openchat=' + openChatParam);
                    this.showUnreadChats = false;
                }
            }
        },
    }
}
</script>
