<template>
    <div>
        <div class="main-content" v-if="profile.accountPage">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <a class="back-link" @click="$router.go(-1)"><i class="fas fa-chevron-left"></i>{{profile.nav.back_link}}</a>
                    </div>
                </div>
                <div class="block block--account" style="padding-bottom: 0;">
                    <span class="edit-button edit-button--small" data-testid="accountEditButton" style="top: 20px; right: 20px;" @click="infoEdit = true" v-if="!infoEdit"><i class="fas fa-pen"></i></span>
                    <span class="edit-button edit-button--small" data-testid="accountSaveButton" style="top: 20px; right: 20px;" @click="saveInfo()" v-if="infoEdit"><i class="fas fa-save"></i></span>
                    <div class="row">
                        <div class="col">
                            <div class="profile-card mechanic" style="border-right: 0; margin-bottom: 20px;">
                                <div class="row">
                                    <div class="col">
                                        <h2 data-testid="accountNameText" style="float: none;" v-if="!infoEdit">{{accountInfo.mechanic_name}}</h2>
                                        <br  v-if="!infoEdit" />
                                        <div v-if="!infoEdit">
                                            <label>{{profile.nav.account.account}}</label>
                                            <p>{{accountInfo.shiro_user_username}}</p>
                                            <label>{{profile.signin.username}}</label>
                                            <p data-testid="accountEmailText">{{accountInfo.mechanic_email}}</p>
                                            <label style="margin-top: 5px;">{{profile.accountPage.account.contactmobile}}</label>
                                            <p data-testid="accountMobileText">{{accountInfo.mechanic_mobile}}</p>
                                        </div>
                                        <div v-else>
                                            <label>{{profile.nav.account.account}}</label>
                                            <input type="text" class="form-control" data-testid="accountNameInput" v-model="accountInfo.mechanic_name" style="margin-bottom: 10px;" />
                                            <label>{{profile.signin.username}}</label>
                                            <input type="text" class="form-control" data-testid="accountEmailInput" v-model="accountInfo.mechanic_email"  style="margin-bottom: 10px;" />
                                            <label style="margin-top: 5px;" >{{profile.accountPage.account.contactmobile}}</label>
                                            <VueTelInput type="text" class="form-control" data-testid="accountPhoneInput" v-bind="bindProps" v-model="tempPhone" @input="validatePhone" :class="{valid: validPhone, error: invalidPhone}"></VueTelInput>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <hr style="margin-top: 0;" />
                        </div>
                    </div>
                    <div class="account-info">
                        <h3 style="font-size: 16px;">{{profile.mechanics.whatsapp_settings}}</h3>
                        <div class="switch-wrapper switch-wrapper--full" :class="{disabled: !canEditWA, active: accountInfo.notify_whatsapp_new_incident}">
                            <div class="inner" @click="toggleNotsWA('new')">
                                <i class="fa-brands fa-whatsapp"></i>
                                <p>{{profile.accountPage.account.new_incidents_wa}}</p>
                                <span class="label">{{profile.switches.wa_new}}</span>
                                <label class="switch">
                                    <span class="slider round" data-testid="employeeNotificationSwitch"></span>
                                </label>
                            </div>   
                        </div>
                        <div class="switch-wrapper switch-wrapper--full" :class="{disabled: !canEditWA, active: accountInfo.notify_whatsapp_status}">
                            <div class="inner" @click="toggleNotsWA('status')">
                                <i class="fa-brands fa-whatsapp"></i>
                                <p>{{profile.accountPage.account.status_updates_wa}}</p>
                                <span>{{profile.switches.wa_status}}</span>
                                <label class="switch">
                                    <span class="slider round" data-testid="employeeStatusSwitch"></span>
                                </label>
                            </div>
                        </div>
                        <div class="switch-wrapper switch-wrapper--full" :class="{disabled: !canEditWA, active: accountInfo.notify_whatsapp_chat}">
                           <div class="inner" @click="toggleNotsWA('chat')" >
                                <i class="fa-brands fa-whatsapp"></i>
                                <p>{{profile.accountPage.account.chat_updates_wa}}</p>
                                <span class="label">{{profile.switches.wa_chat}}</span>
                                <label class="switch">
                                    <span class="slider round" data-testid="employeeChatSwitch"></span>
                                </label>
                           </div>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import { VueTelInput } from 'vue-tel-input';
export default {
    mixins: [setLocale],
    components: {
        VueTelInput
    },
    data() {
        return {
            profile: {},
            accountInfo: {},
            tempPhone: '',
            infoEdit: false,
            canEditWA: false,
            validPhone: false,
            invalidPhone: false,
            bindProps: {
                defaultCountry: "NL",
                disabledFetchingCountry: false,
                disabled: false,
                disabledFormatting: false,
                required: false,
                enabledCountryCode: false,
                enabledFlags: true,
                autocomplete: "off",
                name: "telephone",
                maxLen: 25,
                dropdownOptions: {
                    disabledDialCode: false,
                    showFlags: true
                },
                inputOptions: {
                    showDialCode: false,
                    placeholder: '',
                }
            },
        }
    },
    created() {
        document.title = 'S1MONE | Account';
        this.getAccountInfo();
    },
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        getAccountInfo() {
            let self = this;
            fetch('/api/v1/formechanic/info')
            .then(response => { response.json().then(
                function(data) {
                    self.accountInfo = data;
                    if (self.accountInfo.mechanic_mobile) {
                        self.canEditWA = true;
                    }
                    self.tempPhone = self.accountInfo.mechanic_mobile;
                    console.log(self.accountInfo);
                });
            });
        },
        saveInfo() {
            if (!this.invalidPhone) {
                let paramMobile = 'data[' + this.accountInfo.id_mechanic + '][mobile]';
                let paramEmail = 'data[' + this.accountInfo.id_mechanic + '][email]';
                let paramName = 'data[' + this.accountInfo.id_mechanic + '][name]';
                const params = new URLSearchParams();
                params.append('action', 'edit');
                params.append(paramMobile, this.formattedPhone);
                params.append(paramEmail, this.accountInfo.mechanic_email);
                params.append(paramName, this.accountInfo.mechanic_name);
                let self = this;
                fetch('/api/v1/mechanic', {
                    method: 'post',
                    body: params
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.infoEdit = false;
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.getAccountInfo();
                });
            }
        },
        toggleNotsWA(type) {
            if (type === 'new') {
                this.accountInfo.notify_whatsapp_new_incident = !this.accountInfo.notify_whatsapp_new_incident;
            }
            if (type === 'status') {
                this.accountInfo.notify_whatsapp_status = !this.accountInfo.notify_whatsapp_status;
            }
            if (type === 'chat') {
                this.accountInfo.notify_whatsapp_chat = !this.accountInfo.notify_whatsapp_chat;
            }
            const data = new URLSearchParams();
            data.append('notify_whatsapp_chat',  this.accountInfo.notify_whatsapp_chat);
            data.append('notify_whatsapp_new_incident',  this.accountInfo.notify_whatsapp_new_incident);
            data.append('notify_whatsapp_status',  this.accountInfo.notify_whatsapp_status);
            let self = this;
            fetch('/api/v1/shiro_user/updatenotify', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        validatePhone: function (number, object) {
            this.intCode = object.countryCallingCode
            if (object.formatted) {
                if (object.valid) {
                    this.validPhone = true;
                    this.invalidPhone = false;
                    this.formattedPhone = object.number;
                }
                else {
                    this.validPhone = false;
                    this.invalidPhone = true;
                }
            }
            else {
                this.validPhone = false;
                this.invalidPhone = false;
            }
        }
    }
}
</script>
