<template>
    <div class="block block--account block--border" style="margin-bottom: 0;">
        <div class="account-info">
            <h4 style="margin-bottom: 0;">{{profile.accountPage.company.my_info}}
            </h4>
            <div class="account-contents">
                <div class="info-row alt-hover" data-testid="personalNameEditButton" @click="nameEdit = true">
                    <label>{{profile.employees.name}}
                        <span class="edit-button edit-button--small" v-if="!nameEdit"><i class="fas fa-pencil-alt"></i></span>
                        <span class="edit-button edit-button--small" data-testid="personalNameSave" @click="saveName()" v-if="nameEdit"><i class="far fa-save"></i></span>
                    </label>
                    <div v-if="nameEdit">
                        <input type="text" class="form-control" v-model="currentUser.actual_name" data-testid="personalInputName" />
                    </div>
                    <div v-else>
                        <p data-testid="userNameText">{{currentUser.actual_name}} <span class="admin" v-if="admin" data-testid="adminLabel" style="margin-left: 5px;">ADMIN</span></p>
                    </div>
                </div>
                <div class="info-row alt-hover" data-testid="personalPhoneEditButton" @click="editMobile()">
                    <label>{{profile.accountPage.account.contactmobile}}
                        <span class="edit-button edit-button--small" v-if="!mobileEdit"><i class="fas fa-pencil-alt"></i></span>
                        <span class="edit-button edit-button--small" data-testid="personalPhoneSave" @click="saveMobile()" v-if="mobileEdit"><i class="far fa-save"></i></span>
                    </label>
                    <div v-if="mobileEdit">
                        <input type="text" class="form-control" v-model="tempWaPhone" @input="validatePhone()" :class="{error: invalidPhone}" data-testid="personalInputPhone" >
                    </div>
                    <div v-else>
                        <p data-testid="userMobileText" style="margin-bottom: 0;">{{currentUser.mobile}}</p>
                    </div>
                </div>
                <div class="info-row no-hover">
                    <label>Portfolio</label>
                    <div>
                        <span class="portfolio" @click="portFolioModal(currentUser)"><i class="fas fa-briefcase" data-testid="personalPortfolioOpen"></i>Portfolio</span>
                    </div>
                </div>
                <div class="info-row alt-hover" @click="emailEdit = true">
                    <label>{{profile.nav.account.account}}
                        <span class="edit-button edit-button--small" v-if="!emailEdit"><i class="fas fa-pencil-alt"></i></span>
                        <span class="edit-button edit-button--small" @click.stop="saveEmail()" v-if="emailEdit"><i class="far fa-save"></i></span>
                    </label>
                    <div v-if="emailEdit">
                        <input type="text" class="form-control" v-model="tempEmail" @input="validateEmail()" :class="{error: invalidEmail}">
                    </div>
                    <div v-else>
                        <p style="margin-bottom: 0;" data-testid="userEmailText">{{currentUser.username}}</p>
                    </div>
                </div>
                <div class="row" v-if="accountInfo.building_manager_admin">
                    <div class="col">
                        <div class="switch-wrapper switch-wrapper--full alt-hover no-radius" :class="{active: currentUser.mfa_required}">
                            <div class="inner" @click="toggleMfa(currentUser)">
                                <i class="fa-regular fa-key"></i>
                                <p>{{profile.employees.MFA}}</p>
                                <span>{{ profile.switches.mfa_login }}</span>
                                <label class="switch">
                                    <span class="slider round" data-testid="personalMFA"></span>
                                </label>
                            </div>
                        </div>
                    </div>          
                </div>
                <hr style="margin: 0 -20px;" v-if="accountInfo.updates_via_wa_active" />
                <div class="row">
                    <div class="col">
                        <div class="switch-wrapper switch-wrapper--full alt-hover no-radius" :class="{active: currentUser.notify_incident_assigned}">
                            <div class="inner" @click="toggleNotsWA(currentUser, 'email')">
                                <i class="fa-solid fa-at"></i>
                                <p>{{profile.accountPage.account.notifications_assign}}</p>
                                <span class="label">{{profile.switches.send_automatic_mail_me}}</span>
                                <label class="switch">
                                    <span class="slider round" data-testid="personalEmailNotificationSwitch"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <label v-if="accountInfo.updates_via_wa_active">{{profile.accountPage.account.notifications_via_wa}} <div class="premium-label" v-tooltip.right="profile.accountPage.account.payed_functionality_inactive" style="padding: 1px 5px;"><i class="fas fa-euro-sign"></i> Premium</div></label>
                <div class="row" v-if="accountInfo.updates_via_wa_active">
                    <div class="col">
                        <div class="switch-wrapper switch-wrapper--full alt-hover" :class="{disabled: !currentUser.mobile, active: currentUser.notify_whatsapp_new_incident}">
                            <div class="inner" @click="toggleNotsWA(currentUser, 'new')">
                                <i class="fa-brands fa-whatsapp"></i>
                                <p>{{profile.accountPage.account.new_incidents_wa}}</p>
                                <span class="label">{{profile.switches.wa_new}}</span>
                                <label class="switch">
                                    <span class="slider round" data-testid="personalNotificationsSwitch"></span>
                                </label>
                            </div>   
                        </div>
                        <div class="switch-wrapper switch-wrapper--full alt-hover" v-if="accountInfo.updates_via_wa_active" :class="{disabled: !currentUser.mobile, active: currentUser.notify_whatsapp_status}">
                            <div class="inner" @click="toggleNotsWA(currentUser, 'status')">
                                <i class="fa-brands fa-whatsapp"></i>
                                <p>{{profile.accountPage.account.status_updates_wa}}</p>
                                <span>{{profile.switches.wa_status}}</span>
                                <label class="switch">
                                    <span class="slider round" data-testid="personalStatusSwitch"></span>
                                </label>
                            </div>
                        </div>
                        <div class="switch-wrapper switch-wrapper--full alt-hover" v-if="accountInfo.updates_via_wa_active" :class="{disabled: !currentUser.mobile, active: currentUser.notify_whatsapp_chat}">
                           <div class="inner" @click="toggleNotsWA(currentUser, 'chat')" >
                                <i class="fa-brands fa-whatsapp"></i>
                                <p>{{profile.accountPage.account.chat_updates_wa}}</p>
                                <span class="label">{{profile.switches.wa_chat}}</span>
                                <label class="switch">
                                    <span class="slider round" data-testid="personalMessagesSwitch"></span>
                                </label>
                           </div>  
                        </div>
                        <br v-if="!currentUser.mobile" />
                        <span class="notification notification--warning" v-if="!currentUser.mobile">
                            {{profile.employees.wa_not_allowed}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <PortfolioModal v-if="portfolioModalOpen" @close="portfolioModalOpen = false" :selectedUser="selectedUser" />
        <BmEmailChangeModal v-if="bmEmailChangeModalOpen" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import PortfolioModal from "@/components/modals/PortfolioModal.vue";
import BmEmailChangeModal from '../modals/BmEmailChangeModal.vue';
import { mapState } from 'vuex';

export default {
    name: 'CompanyBlock',
    mixins: [setLocale],
    components: {
        PortfolioModal,
        BmEmailChangeModal
    },
    data: function () {
        return {
            nameEdit: false,
            mobileEdit: false,
            emailEdit: false,
            tempWaPhone: '',
            validPhone: false,
            invalidPhone: false,
            tempEmail: '',
            validEmail: false,
            invalidEmail: false,
            selectedUser: {},
            currentUser: {},
            portfolioModalOpen: false,
            bmEmailChangeModalOpen: false
        }
    },
    props: ['accountInfo', 'userName'],
    computed: mapState(['admin']),
    created() {
        this.getEmployees();
    },
    methods: {
        getEmployees() {
            let self = this;
            fetch('/api/v1/buildingmanageremployee')
            .then(response => { response.json().then(
                function(data) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        if (item.username === self.userName) {
                            self.currentUser = item;
                            if (self.accountInfo.updates_via_wa_active) {
                                self.tempWaPhone = self.currentUser.mobile.replace('+31', '0');
                            }
                            self.tempEmail = self.currentUser.username;
                        }
                    }
                });
            });
        },
        saveName() {
            const data = new URLSearchParams();
            let nameParam = 'data[0][actual_name]';
            data.append('action', 'edit');
            data.append(nameParam, this.currentUser.actual_name);
            let self = this;
            fetch('/api/v1/shiro_user', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.nameEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        editMobile() {
            this.tempWaPhone = this.currentUser.mobile;
            this.mobileEdit = true;	
        },
        saveMobile() {
            if (this.accountInfo.updates_via_wa_active && this.invalidPhone) {
                return;
            }
            if ((!this.invalidPhone)) {
                this.currentUser.mobile = this.tempWaPhone; 
                const data = new URLSearchParams();
                let mobileParam = 'data[0][mobile]';
                data.append('action', 'edit');
                data.append(mobileParam, this.tempWaPhone);
                let self = this;
                fetch('/api/v1/shiro_user', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.mobileEdit = false;
                    self.getEmployees();
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                });
            }
        },
        validateEmail() {
            let pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            if (pattern.test(this.tempEmail)) {
                this.validEmail = true;
                this.invalidEmail = false;
            }
            else {
                this.invalidEmail = true;
                this.validEmail = false;
            }
        },
        saveEmail() {
            if (this.invalidEmail) {
                return;
            }
           else {
                if (this.tempEmail !== this.accountInfo.username) {
                    let self = this;
                    const data = new URLSearchParams();
                    data.append('action', 'edit');
                    data.append('data[0][username]', this.tempEmail);
                    fetch('/api/v1/shiro_user', {
                        method: 'post',
                        body: data
                    }).then((resp) => {return resp.json();
                    }).then(function(data) {
                        self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                        self.bmEmailChangeModalOpen = true;
                    });
                }
                else {
                    this.emailEdit = false;
                }
            }
        },
        validatePhone() {
            if (this.tempWaPhone) {
                let pattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
                if (pattern.test(this.tempWaPhone)) {
                    this.validPhone = true;
                    this.invalidPhone = false;
                }
                else {
                    this.invalidPhone = true;
                    this.validPhone = false;
                }
            }
            else {
                this.validPhone = true;
                this.invalidPhone = false;
            }
            if (this.currentUser.notify_whatsapp_chat || this.currentUser.notify_whatsapp_new_incident || this.currentUser.notify_whatsapp_status) {
                let pattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
                if (pattern.test(this.tempWaPhone)) {
                    this.validPhone = true;
                    this.invalidPhone = false;
                }
                else {
                    this.invalidPhone = true;
                    this.validPhone = false;
                }
            }
        },
        toggleNotsWA(item, type) {
            if (type === 'email') {
                item.notify_incident_assigned = !item.notify_incident_assigned;
            }
            if (item.mobile) {
                if (type === 'new') {
                    item.notify_whatsapp_new_incident = !item.notify_whatsapp_new_incident;
                }
                if (type === 'status') {
                    item.notify_whatsapp_status = !item.notify_whatsapp_status;
                }
                if (type === 'chat') {
                    item.notify_whatsapp_chat = !item.notify_whatsapp_chat;
                }
            }
            const data = new URLSearchParams();
            data.append('id_shiro_user', item.id_shiro_user);
            if (item.mobile) {
                data.append('notify_whatsapp_chat', item.notify_whatsapp_chat);
                data.append('notify_whatsapp_new_incident', item.notify_whatsapp_new_incident);
                data.append('notify_whatsapp_status', item.notify_whatsapp_status);
            }
            data.append('notify_incident_assigned', item.notify_incident_assigned);
            let self = this;
            fetch('/api/v1/buildingmanageremployee/notifywhatsapp', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        portFolioModal(item) {
            this.selectedUser = item;
            this.portfolioModalOpen = true;
        },
        toggleMfa(item) {
            item.mfa_required = !item.mfa_required
            const data = new URLSearchParams();
            data.append('id_shiro_user', item.id_shiro_user);
            data.append('mfa_required', item.mfa_required);
            let self = this;
            fetch('/api/v1/buildingmanageremployee/setmfarequired', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        }
    }
}
</script>
