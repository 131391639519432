<template>
    <div>
        <div v-if="!connectExistingSuccess">
            <div class="back-to-scanner" @click="resetAll()"><i class="fa fa-chevron-left"></i> Terug naar scanner</div>
            <div class="row">
                <div class="col-lg-9">
                    <div class="block block--actions" style="border-radius: 20px;">
                        <section class="incident-head">
                            <h1>Gevonden object <span class="id_asset">{{asset.id_asset}}</span></h1>
                            <span class="active-label" style="top: 27px; right: 20px;" v-if="!asset.hidden">{{profile.assets.assettable.active}}</span>
                            <span class="inactive-label" style="top: 27px; right: 20px;" v-if="asset.hidden">{{profile.assets.inactive}}</span>
                            <!-- <span class="edit-button icon" @click="saveAsset('visible')" v-if="asset.hidden" v-tooltip.right="profile.assets.assetmodal.activate_asset" style="float: right; margin-top: 7px;"><i class="fas fa-toggle-on"></i></span>
                            <span class="edit-button icon" @click="saveAsset('hidden')" v-if="!asset.hidden" v-tooltip.right="profile.assets.assettable.deactivateareyousure" style="float: right; margin-top: 7px;"><i class="fas fa-toggle-off"></i></span> -->
                        </section>
                        <h3 style="margin-top: 15px;">{{asset.name}}</h3>
                        <div class="location-contents">
                            <div class="location-info">
                                <div class="row" v-if="asset.key_number || asset.id_external">
                                    <div class="col-sm-6" v-if="asset.key_number">
                                        <label>{{profile.assets.assetmodal.key_number}}</label>
                                        <p>{{asset.key_number}}</p>
                                    </div>
                                    <div class="col-sm-6" v-if="asset.id_external && asset.id_external !== 'null'">
                                        <label>{{profile.assets.assetmodal.external_id}}</label>
                                        <p v-if="asset.id_external !== 'null'">{{asset.id_external}}</p>
                                    </div>
                                </div>
                                <div class="row" v-if="assetType.display_name">
                                    <div class="col-sm-6">
                                        <label>{{profile.assets.assetmodal.assettype}}</label>
                                        <p>{{assetType.display_name}}</p>
                                    </div>
                                </div>
                                <div class="row" v-if="assetType && (asset.extra_description_a || asset.extra_description_b)">
                                    <div class="col-sm-6" v-if="asset.extra_description_a">
                                        <label>{{assetType.extra_description_a}}</label>
                                        <p v-if="asset.extra_description_a">{{asset.extra_description_a}}</p>
                                        <p v-if="!asset.extra_description_a && assetType.extra_description_a_default">{{assetType.extra_description_a_default}}</p>
                                    </div>
                                    <div class="col-sm-6" v-if="asset.extra_description_b">
                                        <label>{{assetType.extra_description_b}}</label>
                                        <p v-if="asset.extra_description_b">{{asset.extra_description_b}}</p>
                                        <p v-if="!asset.extra_description_b && assetType.extra_description_b_default">{{assetType.extra_description_b_default}}</p>
                                    </div>
                                </div>
                                <div class="row" v-if="assetType && (asset.extra_description_c || asset.extra_description_d)">
                                    <div class="col-sm-6" v-if="asset.extra_description_c">
                                        <label>{{assetType.extra_description_c}}</label>
                                        <p v-if="asset.extra_description_c">{{asset.extra_description_c}}</p>
                                        <p v-if="!asset.extra_description_c && assetType.extra_description_c_default">{{assetType.extra_description_c_default}}</p>
                                    </div>
                                    <div class="col-sm-6" v-if="asset.extra_description_d">
                                        <label>{{assetType.extra_description_d}}</label>
                                        <p v-if="asset.extra_description_d">{{asset.extra_description_d}}</p>
                                        <p v-if="!asset.extra_description_d && assetType.extra_description_d_default">{{assetType.extra_description_d_default}}</p>
                                    </div>
                                </div>
                                <div class="row" v-if="assetType && (asset.extra_description_e || asset.extra_description_f)">
                                    <div class="col-sm-6" v-if="asset.extra_description_e">
                                        <label>{{assetType.extra_description_e}}</label>
                                        <p v-if="asset.extra_description_e">{{asset.extra_description_e}}</p>
                                        <p v-if="!asset.extra_description_e && assetType.extra_description_e_default">{{assetType.extra_description_e_default}}</p>
                                    </div>
                                    <div class="col-sm-6" v-if="asset.extra_description_f">
                                        <label>{{assetType.extra_description_f}}</label>
                                        <p v-if="asset.extra_description_f">{{asset.extra_description_f}}</p>
                                        <p v-if="!asset.extra_description_f && assetType.extra_description_f_default">{{assetType.extra_description_f_default}}</p>
                                    </div>
                                </div>
                                <div class="row" v-if="assetType && (asset.extra_description_g || asset.extra_description_h)">
                                    <div class="col-sm-6" v-if="asset.extra_description_g">
                                        <label>{{assetType.extra_description_g}}</label>
                                        <p v-if="asset.extra_description_g">{{asset.extra_description_g}}</p>
                                        <p v-if="!asset.extra_description_g && assetType.extra_description_g_default">{{assetType.extra_description_g_default}}</p>
                                    </div>
                                    <div class="col-sm-6" v-if="asset.extra_description_h">
                                        <label>{{assetType.extra_description_h}}</label>
                                        <p v-if="asset.extra_description_h">{{asset.extra_description_h}}</p>
                                        <p v-if="!asset.extra_description_h && assetType.extra_description_h_default">{{assetType.extra_description_h_default}}</p>
                                    </div>
                                </div>
                                <div class="row" v-if="assetType && (asset.extra_description_i || asset.extra_description_j)">
                                    <div class="col-sm-6" v-if="asset.extra_description_i">
                                        <label>{{assetType.extra_description_i}}</label>
                                        <p v-if="asset.extra_description_i">{{asset.extra_description_i}}</p>
                                        <p v-if="!asset.extra_description_i && assetType.extra_description_i_default">{{assetType.extra_description_i_default}}</p>
                                    </div>
                                    <div class="col-sm-6" v-if="asset.extra_description_j">
                                        <label>{{assetType.extra_description_j}}</label>
                                        <p v-if="asset.extra_description_j">{{asset.extra_description_j}}</p>
                                        <p v-if="!asset.extra_description_j && assetType.extra_description_j_default">{{assetType.extra_description_j_default}}</p>
                                    </div>
                                </div>
                                <!-- <div class="row">
                                    <div class="col">
                                        <span class="button button--right button--icon" style="margin-bottom: 0;" @click="showForm = !showForm" v-if="!showForm" v-scroll-to="'#replaceExistingForm'"><i class="fas fa-cube"></i>Wil je deze QR-code koppelen aan een ander object?</span>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="block block--incident_detail location">
                        <section class="incident-head" style="margin-bottom: 20px;">
                            <h1>{{profile.assets.assetmodal.place}}</h1>
                        </section>
                        <div class="row">
                            <div class="col">
                                <a :href="mapsUrl" target="_blank">
                                    <img :src="mapsApiUrl" id="address-map" />
                                </a>
                            </div>
                        </div>
                        <p style="margin-bottom: 0;">
                            <router-link tag="a" v-bind:to="'/buildingmanager/location-detail?id_place=' + asset.id_place">{{placeInfo.name}}</router-link>
                            {{placeInfo.address}} {{placeInfo.house_number}}<br />
                            {{placeInfo.zipcode}} {{placeInfo.city}}<br />
                            {{profile.incident_detail.floor_name}}: {{placeInfo.floor_name}}<br />
                        </p>
                    </div>
                </div>
            </div>
            <div class="block block--actions" style="overflow: hidden; border-radius: 20px;" v-show="showForm" id="replaceExistingForm">
                <form v-on:submit.prevent="saveReplaceExisting()">
                    <h3>We gaan het object vinden waar je de QR code aan wilt koppelen.</h3>
                    <label v-if="!selectedPlace.id_place">Kies een locatie</label>
                    <label v-if="selectedPlace.id_place">Geselecteerde locatie</label>
                    <div class="form-field clearable" :class="{focus:placeSearchTerm.length}" style="margin-bottom: 10px;">
                        <label v-if="!selectedPlace.id_place">{{profile.incidents.new_incident.loc_placeholder}}</label>
                        <div>
                            <div class="selected-info seperate" v-if="selectedPlace.name">
                                <h4 style="color: #212529; margin-bottom: 4px;">{{selectedPlace.name}}</h4>
                                <p>{{selectedPlace.address}} {{selectedPlace.house_number}}, {{selectedPlace.city}}</p>
                                <span>{{profile.incident_detail.floor_name}} {{selectedPlace.floor_name}}</span>
                                <span v-if="selectedPlace.id_place" class="remove-selection" @click="removePlaceSelection()"><i class="fas fa-times"></i></span>
                            </div>
                            <input type="text" ref="place" class="form-control" @input="e => placeSearchTerm = e.target.value" :value="placeSearchTerm" @keyup="searchPlaces()" v-if="!selectedPlace.name" />
                        </div>
                    </div>
                    <ul v-if="places.length && !selectedPlace.id_place" class="dropdown-list" style="padding: 0; margin-top: -12px;">
                        <li v-for="place in places" :key="place.id_place" class="dropdown-list-item" v-on:click="selectPlace(place)">
                            <h4>{{place.name}}</h4>
                            <p>{{place.address}} {{place.house_number}}, {{place.city}}</p>
                            <span>{{profile.incident_detail.floor_name}} {{place.floor_name}}</span>
                        </li>
                    </ul>
                    <div v-if="selectedPlace.id_place">
                        <label v-if="!selectedAsset.id_asset">Kies een object</label>
                        <label v-if="selectedAsset.id_asset">Geselecteerde object</label>
                        <section class="search search-alt" style="margin-top: 0;" v-if="assetsByPlace.length > 7">
                            <form v-if="!selectedAsset.id_asset">
                                <input type="text" ref="search" v-bind:placeholder="profile.assets.search_asset" @input="e => assetSearchTerm = e.target.value" :value="assetSearchTerm" v-on:keyup="searchAssets($event)" style="border-radius: 5px; margin-bottom: 10px;"/>
                                <span class="close-suggestions" @click="clearAssetSearch()" v-if="assetSearchTerm.length"><i class="fas fa-times"></i></span>
                            </form>
                        </section>
                        <div v-if="!assetSearchActive && !selectedAsset.name">
                            <div class="asset-block fullwidth" style="padding: 0" v-for="asset in assetsByPlace" :key="asset.id">
                                <h4 style="color: #212529; float: none; padding: 10px 0 10px 10px; margin: 0;" @click="selectAsset(asset)">{{asset.name}}</h4>
                                <div class="properties" @click="selectAsset(asset)">
                                    <div class="property inactive" v-if="!asset.qr_code">
                                        <i class="fas fa-qrcode"></i> {{profile.assets.no_sticker}}
                                    </div>
                                    <div class="property" v-if="asset.qr_code">
                                        <i class="fas fa-qrcode"></i> {{profile.assets.sticker}}
                                    </div>
                                </div>
                                <span class="id" style="top: 10px;" v-on:click="selectAsset(asset)">{{asset.id_asset}}</span>
                                <span class="show-more" v-if="asset.key_number || asset.asset_type_name || asset.extra_description_a" @click="asset.showMore = !asset.showMore" :class="{active: asset.showMore}"><span v-if="!asset.showMore">Meer</span><span v-if="asset.showMore">Minder</span> <i class="fas fa-chevron-down chevron"></i></span>
                                <div v-show="asset.showMore" v-on:click="selectAsset(asset)" class="show-more-expanded" style="margin-bottom: -10px;">
                                    <div class="row" v-if="asset.key_number || asset.asset_type_name">
                                        <div class="col-sm-6" v-if="asset.key_number">
                                            <label>{{profile.assets.assetmodal.key_number}}</label>
                                            <p v-if="asset.key_number">{{asset.key_number}}</p>
                                        </div>
                                        <div class="col-sm-6" v-if="asset.asset_type_name">
                                            <label>{{profile.assets.assetmodal.assettype}}</label>
                                            <p v-if="asset.asset_type_name">{{asset.asset_type_name}}</p>
                                        </div>
                                    </div>
                                    <div class="row" v-if="asset.assetType && (asset.extra_description_a || asset.extra_description_b)">
                                        <div class="col-sm-6" v-if="asset.extra_description_a">
                                            <label>{{asset.assetType.extra_description_a}}</label>
                                            <p v-if="asset.extra_description_a">{{asset.extra_description_a}}</p>
                                            <p v-if="!asset.extra_description_a && (asset.assetType && asset.assetType.extra_description_a_default)">{{assetType.extra_description_a_default}}</p>
                                        </div>
                                        <div class="col-sm-6" v-if="asset.extra_description_b">
                                            <label>{{asset.assetType.extra_description_b}}</label>
                                            <p v-if="asset.extra_description_b">{{asset.extra_description_b}}</p>
                                            <p v-if="!asset.extra_description_b && asset.assetType.extra_description_b_default">{{assetType.extra_description_b_default}}</p>
                                        </div>
                                    </div>
                                    <div class="row" v-if="asset.assetType && (asset.extra_description_c || asset.extra_description_d)">
                                        <div class="col-sm-6" v-if="asset.extra_description_c">
                                            <label>{{asset.assetType.extra_description_c}}</label>
                                            <p v-if="asset.extra_description_c">{{asset.extra_description_c}}</p>
                                            <p v-if="!asset.extra_description_c && asset.assetType.extra_description_c_default">{{assetType.extra_description_c_default}}</p>
                                        </div>
                                        <div class="col-sm-6" v-if="asset.extra_description_d">
                                            <label>{{asset.assetType.extra_description_d}}</label>
                                            <p v-if="asset.extra_description_d">{{asset.extra_description_d}}</p>
                                            <p v-if="!asset.extra_description_d && asset.assetType.extra_description_d_default">{{assetType.extra_description_d_default}}</p>
                                        </div>
                                    </div>
                                    <div class="row" v-if="asset.assetType && (asset.extra_description_e || asset.extra_description_f)">
                                        <div class="col-sm-6" v-if="asset.extra_description_e">
                                            <label>{{asset.assetType.extra_description_e}}</label>
                                            <p v-if="asset.extra_description_e">{{asset.extra_description_e}}</p>
                                            <p v-if="!asset.extra_description_e && asset.assetType.extra_description_e_default">{{assetType.extra_description_e_default}}</p>
                                        </div>
                                        <div class="col-sm-6" v-if="asset.extra_description_f">
                                            <label>{{asset.assetType.extra_description_f}}</label>
                                            <p v-if="asset.extra_description_f">{{asset.extra_description_f}}</p>
                                            <p v-if="!asset.extra_description_f && asset.assetType.extra_description_f_default">{{assetType.extra_description_f_default}}</p>
                                        </div>
                                    </div>
                                    <div class="row" v-if="asset.assetType && (asset.extra_description_g || asset.extra_description_h)">
                                        <div class="col-sm-6" v-if="asset.extra_description_g">
                                            <label>{{asset.assetType.extra_description_g}}</label>
                                            <p v-if="asset.extra_description_g">{{asset.extra_description_g}}</p>
                                            <p v-if="!asset.extra_description_g && asset.assetType.extra_description_g_default">{{assetType.extra_description_g_default}}</p>
                                        </div>
                                        <div class="col-sm-6" v-if="asset.extra_description_h">
                                            <label>{{asset.assetType.extra_description_h}}</label>
                                            <p v-if="asset.extra_description_h">{{asset.extra_description_h}}</p>
                                            <p v-if="!asset.extra_description_h && asset.assetType.extra_description_h_default">{{assetType.extra_description_h_default}}</p>
                                        </div>
                                    </div>
                                    <div class="row" v-if="asset.assetType && (asset.extra_description_i || asset.extra_description_j)">
                                        <div class="col-sm-6" v-if="asset.extra_description_i">
                                            <label>{{asset.assetType.extra_description_i}}</label>
                                            <p v-if="asset.extra_description_i">{{asset.extra_description_i}}</p>
                                            <p v-if="!asset.extra_description_i && asset.assetType.extra_description_i_default">{{assetType.extra_description_i_default}}</p>
                                        </div>
                                        <div class="col-sm-6" v-if="asset.extra_description_j">
                                            <label>{{asset.assetType.extra_description_j}}</label>
                                            <p v-if="asset.extra_description_j">{{asset.extra_description_j}}</p>
                                            <p v-if="!asset.extra_description_j && asset.assetType.extra_description_j_default">{{assetType.extra_description_j_default}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="assetSearchActive && !selectedAsset.name">
                            <div class="asset-block fullwidth" style="padding: 0" v-for="asset in tempAssets" :key="asset.id">
                                <h4 style="color: #212529; float: none; padding: 10px 0 10px 10px; margin: 0;" @click="selectAsset(asset)">{{asset.name}}</h4>
                                <div class="properties" @click="selectAsset(asset)">
                                    <div class="property inactive" v-if="!asset.qr_code">
                                        <i class="fas fa-qrcode"></i> {{profile.assets.no_sticker}}
                                    </div>
                                    <div class="property" v-if="asset.qr_code">
                                        <i class="fas fa-qrcode"></i> {{profile.assets.sticker}}
                                    </div>
                                </div>
                                <span class="id" style="top: 10px;" v-on:click="selectAsset(asset)">{{asset.id_asset}}</span>
                                <span class="show-more" v-if="asset.key_number || asset.asset_type_name || asset.extra_description_a" @click="asset.showMore = !asset.showMore" :class="{active: asset.showMore}"><span v-if="!asset.showMore">Meer</span><span v-if="asset.showMore">Minder</span> <i class="fas fa-chevron-down chevron"></i></span>
                                <div v-show="asset.showMore" v-on:click="selectAsset(asset)" class="show-more-expanded" style="margin-bottom: -10px;">
                                    <div class="row" v-if="asset.key_number || asset.asset_type_name">
                                        <div class="col-sm-6" v-if="asset.key_number">
                                            <label>{{profile.assets.assetmodal.key_number}}</label>
                                            <p v-if="asset.key_number">{{asset.key_number}}</p>
                                        </div>
                                        <div class="col-sm-6" v-if="asset.asset_type_name">
                                            <label>{{profile.assets.assetmodal.assettype}}</label>
                                            <p v-if="asset.asset_type_name">{{asset.asset_type_name}}</p>
                                        </div>
                                    </div>
                                    <div class="row" v-if="asset.assetType && (asset.extra_description_a || asset.extra_description_b)">
                                        <div class="col-sm-6" v-if="asset.extra_description_a">
                                            <label>{{asset.assetType.extra_description_a}}</label>
                                            <p v-if="asset.extra_description_a">{{asset.extra_description_a}}</p>
                                            <p v-if="!asset.extra_description_a && (asset.assetType && asset.assetType.extra_description_a_default)">{{assetType.extra_description_a_default}}</p>
                                        </div>
                                        <div class="col-sm-6" v-if="asset.extra_description_b">
                                            <label>{{asset.assetType.extra_description_b}}</label>
                                            <p v-if="asset.extra_description_b">{{asset.extra_description_b}}</p>
                                            <p v-if="!asset.extra_description_b && asset.assetType.extra_description_b_default">{{assetType.extra_description_b_default}}</p>
                                        </div>
                                    </div>
                                    <div class="row" v-if="asset.assetType && (asset.extra_description_c || asset.extra_description_d)">
                                        <div class="col-sm-6" v-if="asset.extra_description_c">
                                            <label>{{asset.assetType.extra_description_c}}</label>
                                            <p v-if="asset.extra_description_c">{{asset.extra_description_c}}</p>
                                            <p v-if="!asset.extra_description_c && asset.assetType.extra_description_c_default">{{assetType.extra_description_c_default}}</p>
                                        </div>
                                        <div class="col-sm-6" v-if="asset.extra_description_d">
                                            <label>{{asset.assetType.extra_description_d}}</label>
                                            <p v-if="asset.extra_description_d">{{asset.extra_description_d}}</p>
                                            <p v-if="!asset.extra_description_d && asset.assetType.extra_description_d_default">{{assetType.extra_description_d_default}}</p>
                                        </div>
                                    </div>
                                    <div class="row" v-if="asset.assetType && (asset.extra_description_e || asset.extra_description_f)">
                                        <div class="col-sm-6" v-if="asset.extra_description_e">
                                            <label>{{asset.assetType.extra_description_e}}</label>
                                            <p v-if="asset.extra_description_e">{{asset.extra_description_e}}</p>
                                            <p v-if="!asset.extra_description_e && asset.assetType.extra_description_e_default">{{assetType.extra_description_e_default}}</p>
                                        </div>
                                        <div class="col-sm-6" v-if="asset.extra_description_f">
                                            <label>{{asset.assetType.extra_description_f}}</label>
                                            <p v-if="asset.extra_description_f">{{asset.extra_description_f}}</p>
                                            <p v-if="!asset.extra_description_f && asset.assetType.extra_description_f_default">{{assetType.extra_description_f_default}}</p>
                                        </div>
                                    </div>
                                    <div class="row" v-if="asset.assetType && (asset.extra_description_g || asset.extra_description_h)">
                                        <div class="col-sm-6" v-if="asset.extra_description_g">
                                            <label>{{asset.assetType.extra_description_g}}</label>
                                            <p v-if="asset.extra_description_g">{{asset.extra_description_g}}</p>
                                            <p v-if="!asset.extra_description_g && asset.assetType.extra_description_g_default">{{assetType.extra_description_g_default}}</p>
                                        </div>
                                        <div class="col-sm-6" v-if="asset.extra_description_h">
                                            <label>{{asset.assetType.extra_description_h}}</label>
                                            <p v-if="asset.extra_description_h">{{asset.extra_description_h}}</p>
                                            <p v-if="!asset.extra_description_h && asset.assetType.extra_description_h_default">{{assetType.extra_description_h_default}}</p>
                                        </div>
                                    </div>
                                    <div class="row" v-if="asset.assetType && (asset.extra_description_i || asset.extra_description_j)">
                                        <div class="col-sm-6" v-if="asset.extra_description_i">
                                            <label>{{asset.assetType.extra_description_i}}</label>
                                            <p v-if="asset.extra_description_i">{{asset.extra_description_i}}</p>
                                            <p v-if="!asset.extra_description_i && asset.assetType.extra_description_i_default">{{assetType.extra_description_i_default}}</p>
                                        </div>
                                        <div class="col-sm-6" v-if="asset.extra_description_j">
                                            <label>{{asset.assetType.extra_description_j}}</label>
                                            <p v-if="asset.extra_description_j">{{asset.extra_description_j}}</p>
                                            <p v-if="!asset.extra_description_j && asset.assetType.extra_description_j_default">{{assetType.extra_description_j_default}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="notification notification--error" v-if="!tempAssets.length && assetSearchActive">Geen objecten gevonden met deze zoekterm</div>
                        </div>
                        <div class="selected-asset" v-if="selectedAsset.name">
                            <span class="remove-selection" @click="removeAssetSelection()"><i class="fas fa-times"></i></span>
                            <h3 style="margin-top: 0;">{{selectedAsset.name}}</h3>
                            <span class="id">{{selectedAsset.id_asset}}</span>
                            <div class="location-contents">
                                <div class="location-info">
                                    <div class="row" v-if="selectedAsset.key_number || (selectedAsset.assetType && selectedAsset.assetType.display_name)">
                                        <div class="col-sm-6" v-if="selectedAsset.key_number">
                                            <label>{{profile.assets.assetmodal.key_number}}</label>
                                            <p v-if="selectedAsset.key_number">{{selectedAsset.key_number}}</p>
                                        </div>
                                        <div class="col-sm-6" v-if="selectedAsset.assetType.display_name">
                                            <label>{{profile.assets.assetmodal.assettype}}</label>
                                            <p v-if="selectedAsset.assetType.display_name">{{selectedAsset.assetType.display_name}}</p>
                                        </div>
                                    </div>
                                    <div v-if="selectedAsset.assetType">
                                        <div class="row" v-if="selectedAsset.assetType && (selectedAsset.assetType.extra_description_a || selectedAsset.assetType.extra_description_a_default)">
                                            <div class="col-sm-6" v-if="selectedAsset.extra_description_a || selectedAsset.assetType.extra_description_a_default">
                                                <label>{{selectedAsset.assetType.extra_description_a}}</label>
                                                <p v-if="selectedAsset.extra_description_a">{{selectedAsset.extra_description_a}}</p>
                                                <p v-if="!selectedAsset.extra_description_a && (selectedAsset.assetType && selectedAsset.assetType.extra_description_a_default)">{{selectedAsset.assetType.extra_description_a_default}}</p>
                                            </div>
                                            <div class="col-sm-6" v-if="selectedAsset.extra_description_b || selectedAsset.assetType.extra_description_b_default">
                                                <label>{{selectedAsset.assetType.extra_description_b}}</label>
                                                <p v-if="selectedAsset.extra_description_b">{{selectedAsset.extra_description_b}}</p>
                                                <p v-if="!selectedAsset.extra_description_b && selectedAsset.assetType.extra_description_b_default">{{selectedAsset.assetType.extra_description_b_default}}</p>
                                            </div>
                                        </div>
                                        <div class="row" v-if="selectedAsset.assetType.extra_description_c || selectedAsset.assetType.extra_description_d_default">
                                            <div class="col-sm-6" v-if="selectedAsset.extra_description_c || selectedAsset.assetType.extra_description_c_default">
                                                <label>{{selectedAsset.assetType.extra_description_c}}</label>
                                                <p v-if="selectedAsset.extra_description_c">{{selectedAsset.extra_description_c}}</p>
                                                <p v-if="!selectedAsset.extra_description_c && selectedAsset.assetType.extra_description_c_default">{{selectedAsset.assetType.extra_description_c_default}}</p>
                                            </div>
                                            <div class="col-sm-6" v-if="selectedAsset.extra_description_d || selectedAsset.assetType.extra_description_d_default">
                                                <label>{{selectedAsset.assetType.extra_description_d}}</label>
                                                <p v-if="selectedAsset.extra_description_d">{{selectedAsset.extra_description_d}}</p>
                                                <p v-if="!selectedAsset.extra_description_d && selectedAsset.assetType.extra_description_d_default">{{selectedAsset.assetType.extra_description_d_default}}</p>
                                            </div>
                                        </div>
                                        <div class="row" v-if="selectedAsset.assetType.extra_description_e || selectedAsset.assetType.extra_description_e_default">
                                            <div class="col-sm-6" v-if="selectedAsset.extra_description_e || selectedAsset.assetType.extra_description_e_default">
                                                <label>{{selectedAsset.assetType.extra_description_e}}</label>
                                                <p v-if="selectedAsset.extra_description_e">{{selectedAsset.extra_description_e}}</p>
                                                <p v-if="!selectedAsset.extra_description_e && selectedAsset.assetType.extra_description_e_default">{{selectedAsset.assetType.extra_description_e_default}}</p>
                                            </div>
                                            <div class="col-sm-6" v-if="selectedAsset.extra_description_f || selectedAsset.assetType.extra_description_f_default">
                                                <label>{{selectedAsset.assetType.extra_description_f}}</label>
                                                <p v-if="selectedAsset.extra_description_f">{{selectedAsset.extra_description_f}}</p>
                                                <p v-if="!selectedAsset.extra_description_f && selectedAsset.assetType.extra_description_f_default">{{selectedAsset.assetType.extra_description_f_default}}</p>
                                            </div>
                                        </div>
                                        <div class="row" v-if="selectedAsset.assetType.extra_description_g || selectedAsset.assetType.extra_description_g_default">
                                            <div class="col-sm-6" v-if="selectedAsset.extra_description_g || selectedAsset.assetType.extra_description_g_default">
                                                <label>{{selectedAsset.assetType.extra_description_g}}</label>
                                                <p v-if="selectedAsset.extra_description_g">{{selectedAsset.extra_description_g}}</p>
                                                <p v-if="!selectedAsset.extra_description_g && selectedAsset.assetType.extra_description_g_default">{{asset.assetType.extra_description_e_default}}</p>
                                            </div>
                                            <div class="col-sm-6" v-if="selectedAsset.extra_description_h || selectedAsset.assetType.extra_description_h_default">
                                                <label>{{selectedAsset.assetType.extra_description_h}}</label>
                                                <p v-if="selectedAsset.extra_description_h">{{selectedAsset.extra_description_h}}</p>
                                                <p v-if="!selectedAsset.extra_description_h && selectedAsset.assetType.extra_description_h_default">{{selectedAsset.assetType.extra_description_f_default}}</p>
                                            </div>
                                        </div>
                                        <div class="row" v-if="selectedAsset.assetType.extra_description_i || selectedAsset.assetType.extra_description_i_default">
                                            <div class="col-sm-6" v-if="selectedAsset.extra_description_i || selectedAsset.assetType.extra_description_i_default">
                                                <label>{{selectedAsset.assetType.extra_description_i}}</label>
                                                <p v-if="selectedAsset.extra_description_i">{{selectedAsset.extra_description_i}}</p>
                                                <p v-if="!selectedAsset.extra_description_i && selectedAsset.assetType.extra_description_i_default">{{selectedAsset.assetType.extra_description_i_default}}</p>
                                            </div>
                                            <div class="col-sm-6" v-if="selectedAsset.extra_description_j || selectedAsset.assetType.extra_description_j_default">
                                                <label>{{selectedAsset.assetType.extra_description_j}}</label>
                                                <p v-if="selectedAsset.extra_description_j">{{selectedAsset.extra_description_j}}</p>
                                                <p v-if="!selectedAsset.extra_description_j && selectedAsset.assetType.extra_description_j_default">{{asset.assetType.extra_description_j_default}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="selectedAsset.qr_code">
                        <div class="col">
                            <div class="notification notification--warn">Let op! Het geselecteerde object is al gekoppeld aan een QR code. Wil je de QR code van het gescande object koppelen aan het geselecteerde object?</div>
                        </div>
                    </div>
                    <div class="row" >
                        <div class="col">
                            <button type="submit" v-if="selectedAsset.qr_code" class="button button--right button--icon" :disabled="!newObjectComplete" :class="{disabled: !newObjectComplete}" style="margin-bottom: 0;"><i class="fas fa-cube"></i>QR code van dit object vervangen</button>
                            <button type="submit" v-else class="button button--right button--icon" :disabled="!newObjectComplete" :class="{disabled: !newObjectComplete}" style="margin-bottom: 0;"><i class="fas fa-cube"></i>QR code aan object koppelen</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import { mapState } from 'vuex';
import debounce from 'lodash/debounce';

export default {
    mixins: [setLocale],
    name: 'ReplaceExistingQr',
    data() {
        return {
            profile: {},
            assetType: {},
            places: [],
            placeInfo: {},
            mapsUrl: '',
            mapsApiUrl: '',
            selectedPlace: {},
            placeSearchTerm: '',
            assetsByPlace: [],
            selectedAsset: {},
            assetSearchActive: false,
            assetSearchTerm: '',
            showForm: false,
            connectExistingSuccess: false
        }
    },
    computed: {
        newObjectComplete() {
            if (this.selectedPlace.name && this.selectedAsset.name) {
                return true
            }
            else {
                return false
            }
        },
        ...mapState(['mapsKey'])
    },
    props: ['qrCode', 'asset', 'flow', 'locationInfo'],
    created() {
        this.getAssetTypes();
        this.getPlace();
        console.log(this.asset);
    },
    methods: {
        resetAll() {
            this.$emit('reset');
        },
        getPlace() {
            let self = this;
            fetch('/api/v1/place/byid?id_place=' + this.asset.id_place)
            .then(response => { response.json().then(
                function(data) {
                    self.placeInfo = data;
                    // Add space in zipcode if it ends in two letters
                    if (/[a-zA-Z]{2}$/.test(self.placeInfo.zipcode)) {
                        self.placeInfo.zipcode = self.placeInfo.zipcode.substring(0, self.placeInfo.zipcode.length - 2) + ' ' + self.placeInfo.zipcode.substring(self.placeInfo.zipcode.length - 2);
                    }
                    let gmapsAddress    = self.placeInfo.address + self.placeInfo.house_number + "+"+self.placeInfo.city.replace(/ /g , "+");
                    self.mapsApiUrl     = 'https://maps.googleapis.com/maps/api/staticmap?markers='+
                                           gmapsAddress+
                                            '&zoom=16&size=150x150&key=' + self.mapsKey;
                    self.mapsUrl        = "https://www.google.com/maps/place/"+gmapsAddress;
                });
            });
        },
        getAssetTypes() {
            let self = this;
            fetch('/api/v1/asset_type')
            .then(response => { response.json().then(
                function(data) {
                    self.assetTypes = data.data;
                    for (var j = 0; j < self.assetTypes.length; j++) {
                        let assetType = self.assetTypes[j];
                        if (self.asset.id_asset_type === assetType.id_asset_type) {
                            self.assetType = assetType;
                        }
                    }
                    console.log(self.assetType);
                });
            });
        },
        searchPlaces: debounce(function (e) {
            if (this.placeSearchTerm.length > 2) {
                let searchParam = '';
                if (this.placeSearchTerm) {
                    searchParam = '?search_name=' + this.placeSearchTerm;
                }
                let self = this;
                fetch('/api/v1/place/bybuildingmanageronlywithassets' + searchParam)
                .then(response => { response.json().then(
                    function(data) {
                        self.places = [];
                        for (var i = 0; i < data.data.length; i++) {
                            let place = data.data[i];
                            if (place.floor_name === '0') {
                                if (self.locale === 'nl') {
                                    place.floor_name = 'BG';
                                }
                                else {
                                    place.floor_name = 'GF';
                                }
                            }
                            self.places.push(place);
                        }
                        if (!self.places) {
                            self.placesNoResults = true;
                        }
                    });
                });
            }
            else {
                this.placesNoResults = false;
                this.places = [];
            }
        }, 500),
        removePlaceSelection() {
            this.selectedPlace = {};
            this.places = [];
            this.selectedAsset = {};
        },
        selectPlace(selectedPlace) {
            this.places = [];
            this.placesDropdownToggled = false;
            this.selectedPlace = selectedPlace;
            this.placeSearchTerm = '';
            this.getAssetsById(selectedPlace.id_place);
        },
        getAssetsById(id_place) {
            this.assetsByPlace = [];
            let self = this;
            fetch('/api/v1/asset/activebyplace?id_place=' + id_place)
            .then(response => { response.json().then(
                function(data) {
                    for (var i = 0; i < data.data.length; i++) {
                        let asset = data.data[i];
                        asset.showMore = false;
                        for (var j = 0; j < self.assetTypes.length; j++) {
                            let assetType = self.assetTypes[j];
                            if (asset.id_asset_type === assetType.id_asset_type) {
                                asset.assetType = assetType;
                            }
                        }
                        if (asset.name) {
                            self.assetsByPlace.push(asset);
                        }
                    }
                });
            });
        },
        searchAssets() {
            this.tempAssets = [];
            if (this.assetSearchTerm.length > 1) {
                this.assetSearchActive = true;
                let searchTerm = this.assetSearchTerm.toLowerCase();
                for (var i = 0; i < this.assetsByPlace.length; i++) {
                    let asset = this.assetsByPlace[i];
                    let assetName = asset.name.toLowerCase();
                    if (assetName.includes(searchTerm)) {
                        this.tempAssets.push(asset);
                    }
                }
            }
            else {
                this.assetSearchActive = false;
            }
        },
        clearAssetSearch() {
            this.assetSearchTerm = '';
            this.tempAssets = [];
            this.assetSearchActive = false;
        },
        selectAsset(asset) {
            this.selectedAsset = asset;
            this.tempAssets = [];
            this.assetSearchTerm = '';
        },
        removeAssetSelection() {
            this.selectedAsset = {};
            this.tempAssets = [];
            this.assetSearchActive = false;
        },
        saveAsset(type) {
            const data = new URLSearchParams();
            data.append('qr_code', this.qrCode);
            data.append('id_place', this.asset.id_place);
            if (type === 'hidden') {
                data.append('hidden', true);
            }
            if (type === 'visible') {
                data.append('hidden', false);
            }
            let self = this;
            fetch('/api/v1/updateasset', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.newAssetId = data.id_asset;
            });
        },
        getAsset() {
            let self = this;
            fetch('/api/v1/asset/byid?id_asset=' + this.asset.id_asset)
            .then(response => { response.json().then(
                function(data) {
                    self.asset.hidden = data.hidden;
                });
            });
        }
    }
}
</script>
