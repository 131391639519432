<template>
    <div class="modal-wrapper">
        <div class="modal">
            <div class="modal-header">
                <h3> Stickers van te voren uitgeven</h3>
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <div class="row">
                    <div class="col-sm-3">
                        <span class="control-label" style="right:10px; position: absolute; margin-top: 14px">Grootte</span>
                    </div>
                    <div class="col-sm-9">
                        <div class="dropdown-section">
                            <span class="dropdown" v-on:click="optionsDropdownToggle = !optionsDropdownToggle" :class="{active: optionsDropdownToggle}">
                                <span v-if="fields.size" class="selected">{{fields.size}}</span>
                                <span v-else class="selected">Maak een keuze</span>
                                <i class="fas fa-chevron-down chevron"></i>
                            </span>
                            <div class="dropdown-list" v-if="optionsDropdownToggle">
                                <ul>
                                    <li v-for="(option, index) in options" v-on:click="selectOption(index)" :key="index">
                                        {{option.label}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-1"></div>
                </div>
                <div class="row">
                    <div class="col-sm-3">
                        <span class="control-label" style="right:10px; position: absolute; margin-top: 14px">Aantal</span>
                    </div>
                    <div class="col-sm-9">
                        <input type="number" min="1" class="form-control" v-model="fields.amount" />
                    </div>
                    <div class="col-sm-1"></div>
                </div>
                <button class="button button--icon button--right" style="margin-top:20px" v-on:click="makeStickers()"><i class="fa-solid fa-qrcode"></i>Stickers uitgeven</button>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import sortBy from 'lodash/sortBy';
export default {
    mixins: [setLocale],
    name: 'NewStickerModal',
    data() {
        return {
            profile: {},
            fields: {
                size: '',
                sizeValue: '',
                amount: '',
            },
            options: [
                { label: 'Small', value: 'S' },
                { label: 'Medium', value: 'M' },
                { label: 'Large', value: 'L' },
            ],
            optionsDropdownToggle: false,
        }
    },
    created() {
        document.querySelector('body').classList.add('modal-open');
        document.onkeydown = (evt) => {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                this.closeModal();
            }
        };
    },
    methods: {
        selectOption(index){
            this.optionsDropdownToggle = false;
            this.fields.size = this.options[index].label;
            this.fields.sizeValue = this.options[index].value;
        },
        makeStickers(){
            var link = document.createElement('a');
            link.href = '/api/v1/stickercreation/precreate?size=' + this.fields.sizeValue + '&amount=' + this.fields.amount;
            link.download = 'qrcodes-nobuildingmanager-' + this.fields.amount + '-' + this.fields.sizeValue + '.pdf';
            document.body.appendChild(link);
            link.dispatchEvent(new MouseEvent('click'));
            this.closeModal();
        },
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        }
    }
}


</script>
