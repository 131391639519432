<template>
    <div class="modal-wrapper" v-if="profile.incidents">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-file-excel"></i>{{profile.dashboard.building_manager.request_stats}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="requestExcel()">
                    <div class="row">
                        <div class="col-sm-1">
                            <label class="modal-label">{{profile.dashboard.building_manager.request_stats_from}}</label>
                        </div>
                        <div class="col-sm-3">
                            <select class="form-select" v-model="fields.from.day" data-testid="reportFromDay" >
                                <option value="1">01</option>
                                <option value="2">02</option>
                                <option value="3">03</option>
                                <option value="4">04</option>
                                <option value="5">05</option>
                                <option value="6">06</option>
                                <option value="7">07</option>
                                <option value="8">08</option>
                                <option value="9">09</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="26">26</option>
                                <option value="27">27</option>
                                <option value="28">28</option>
                                <option value="29">29</option>
                                <option value="30">30</option>
                                <option value="31">31</option>
                            </select>
                        </div>
                        <div class="col-sm-4">
                            <select class="form-select" v-model="fields.from.month" data-testid='reportFromMonth'>
                                <option value="1">{{profile.months_short.jan}}</option>
                                <option value="2">{{profile.months_short.feb}}</option>
                                <option value="3">{{profile.months_short.mar}}</option>
                                <option value="4">{{profile.months_short.apr}}</option>
                                <option value="5">{{profile.months_short.may}}</option>
                                <option value="6">{{profile.months_short.jun}}</option>
                                <option value="7">{{profile.months_short.jul}}</option>
                                <option value="8">{{profile.months_short.aug}}</option>
                                <option value="9">{{profile.months_short.sep}}</option>
                                <option value="10">{{profile.months_short.oct}}</option>
                                <option value="11">{{profile.months_short.nov}}</option>
                                <option value="12">{{profile.months_short.dec}}</option>
                            </select>
                        </div>
                        <div class="col-sm-4">
                            <select class="form-select" v-model="fields.from.year" data-testid="reportFromYear" >
                                <option v-for="(year, index) in years" :key="index" :value="year" :selected="year === 2018">{{year}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-1">
                            <label class="modal-label">{{profile.dashboard.building_manager.request_stats_until}}</label>
                        </div>
                        <div class="col-sm-3">
                            <select class="form-select" v-model="fields.until.day" data-testid="reportUntilDay">
                                <option value="1">01</option>
                                <option value="2">02</option>
                                <option value="3">03</option>
                                <option value="4">04</option>
                                <option value="5">05</option>
                                <option value="6">06</option>
                                <option value="7">07</option>
                                <option value="8">08</option>
                                <option value="9">09</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="26">26</option>
                                <option value="27">27</option>
                                <option value="28">28</option>
                                <option value="29">29</option>
                                <option value="30">30</option>
                                <option value="31">31</option>
                            </select>
                        </div>
                        <div class="col-sm-4">
                            <select class="form-select" v-model="fields.until.month" data-testid="reportUntilMonth">
                                <option value="1">{{profile.months_short.jan}}</option>
                                <option value="2">{{profile.months_short.feb}}</option>
                                <option value="3">{{profile.months_short.mar}}</option>
                                <option value="4">{{profile.months_short.apr}}</option>
                                <option value="5">{{profile.months_short.may}}</option>
                                <option value="6">{{profile.months_short.jun}}</option>
                                <option value="7">{{profile.months_short.jul}}</option>
                                <option value="8">{{profile.months_short.aug}}</option>
                                <option value="9">{{profile.months_short.sep}}</option>
                                <option value="10">{{profile.months_short.oct}}</option>
                                <option value="11">{{profile.months_short.nov}}</option>
                                <option value="12">{{profile.months_short.dec}}</option>
                            </select>
                        </div>
                        <div class="col-sm-4">
                            <select class="form-select" v-model="fields.until.year" data-testid='reportUntilYear'>
                                <option v-for="(year, index) in years" :key="index" :value="year" :selected="year === 2018">{{year}}</option>
                            </select>
                        </div>
                    </div>
                    <button type="submit" class="button button--icon" @click="requestExcel()" data-testid='reportSubmitButton'><i class="fas fa-file-excel"></i>{{profile.dashboard.building_manager.request_stats}}</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';

export default {
    mixins: [setLocale],
    data() {
        return {
            profile: {},
            years: [],
            fields: {
                from: {
                    day: 1,
                    month: 1,
                    year: 2018
                },
                until: {
                    day: 1,
                    month: 1,
                    year: 2021
                }
            }
        }
    },
    created() {
        document.querySelector('body').classList.add('modal-open');
        var currentDate = new Date();
        var currentYear = currentDate.getFullYear();
        var startYear = 2018;
        while (startYear <= currentYear) {
            this.years.push(startYear);
            startYear++
        }
        this.fields.until.day = currentDate.getDate();
        this.fields.until.month = currentDate.getMonth() + 1;
        this.fields.until.year = currentDate.getFullYear();
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        requestExcel() {
            let epochFrom = new Date();
            epochFrom.setDate(this.fields.from.day);
            epochFrom.setMonth(this.fields.from.month - 1);
            epochFrom.setFullYear(this.fields.from.year);
            epochFrom.setHours(0);
            epochFrom.setMinutes(0);
            epochFrom.setSeconds(0);
            let epochUntil = new Date();
            epochUntil.setDate(this.fields.until.day);
            epochUntil.setMonth(this.fields.until.month - 1);
            epochUntil.setFullYear(this.fields.until.year);
            epochUntil.setHours(23);
            epochUntil.setMinutes(59);
            epochUntil.setSeconds(59);
            let locale = localStorage.getItem('S1-locale');
            window.open("/api/v1/incidentcombined/asexcel?from="+epochFrom.getTime()+"&until="+epochUntil.getTime()+"&locale="+locale);
            this.$emit('close');
        }
    }
}
</script>
