<template>
    <div class="main-content" v-if="profile.owners">
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="block block--repaircompanies">
                        <section class="incident-head" style="margin-bottom: 20px; padding-bottom: 15px;">
                            <h1>Statistieken</h1>
                        </section>
                        <Bar :data="chartData" :options="options" v-if="loaded" />
                        <div class="loading" v-if="!loaded"><img src="@/assets/icons/loading.gif" /></div>
                        <br />
                        <br />
                        <Bar :data="yearChartData" :options="yearOptions" v-if="MonthsLoaded && showMonths" />
                        <div class="loading" v-if="!MonthsLoaded && showMonths"><img src="@/assets/icons/loading.gif" /></div>
                        <div v-if="managersLoaded && showManagers">
                            <br />
                            <br />
                            <div class="row">
                                <h3 style="margin-left: 20px;">Assets en Incidenten voor {{ selectedMonth }} {{ selectedYear }}</h3>
                            </div>
                            <div class="table-wrap features">
                                <table class="table features table-striped">
                                    <colgroup>
                                        <col style="width: 20%;" />
                                        <col style="width: 15%;" />
                                        <col style="width: 15%;" />
                                        <col style="width: 20%;" />
                                        <col style="width: 20%;" />
                                        <col style="width: 20%;" />
                                    </colgroup>
                                    <thead class="sticky">
                                        <tr>
                                            <th @click="sortTable('name')" :class="{active: sortType === 'name'}">Naam<i class="fas fa-sort"></i></th>
                                            <th @click="sortTable('assets')" :class="{active: sortType === 'assets'}">Actieve objecten<i class="fas fa-sort"></i></th>
                                            <th @click="sortTable('incidents')" :class="{active: sortType === 'incidents'}">Meldingen<i class="fas fa-sort"></i></th>
                                            <th @click="sortTable('incidents_assets')" :class="{active: sortType === 'incidents_assets'}">Incidenten/Objecten<i class="fas fa-sort"></i></th>
                                            <th @click="sortTable('dashboard_incidents')" :class="{active: sortType === 'dashboard_incidents'}">Dashboard Meldingen<i class="fas fa-sort"></i></th>
                                            <th @click="sortTable('Qr_incidents')" :class="{active: sortType === 'Qr_incidents'}">QR-code Meldingen<i class="fas fa-sort"></i></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(manager, index) in statisticsShown" :key="index">
                                            <td>{{ manager.name }}</td>
                                            <td>{{ manager.assets }}</td>
                                            <td>{{ manager.incidents }}</td>
                                            <td>{{ manager.incidents_assets }}</td>
                                            <td>{{ manager.dashboard_incidents }}</td>
                                            <td>{{ manager.Qr_incidents }}</td>
                                            <!-- <td>{{ statistics.assets[index] }}</td>
                                            <td>{{ statistics.incidents[manager] || 0 }}</td>
                                            <td>{{ statistics.incidents[manager] && statistics.assets[index] ? (statistics.incidents[manager] / statistics.assets[index] * 100).toFixed(2) === "0.00" ? "-" : (statistics.incidents[manager] / statistics.assets[index] * 100).toFixed(2) + "%" : "-" }}</td>
                                            <td>
                                            {{
                                                statistics.incidents[manager]
                                                ? ((((statistics.dashboard_incidents[manager] || 0) / statistics.incidents[manager]) * 100).toFixed(2) === "0.00"
                                                    ? "0%"
                                                    : ((((statistics.dashboard_incidents[manager] || 0) / statistics.incidents[manager]) * 100).toFixed(2) + "%"))
                                                : "-"
                                            }}
                                            </td>
                                            <td>
                                            {{
                                                statistics.incidents[manager]
                                                ? ((((statistics.incidents[manager] || 0) - (statistics.dashboard_incidents[manager] || 0)) / statistics.incidents[manager] * 100).toFixed(2) === "0.00"
                                                    ? "0%"
                                                    : ((((statistics.incidents[manager] || 0) - (statistics.dashboard_incidents[manager] || 0)) / statistics.incidents[manager] * 100).toFixed(2) + "%"))
                                                : "-"
                                            }}
                                            </td> -->
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import moment from 'moment';
import sortBy from 'lodash/sortBy';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'
import { Bar } from 'vue-chartjs'
import { Colors } from 'chart.js';
ChartJS.register(Colors);
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export default {
    mixins: [setLocale],
    components: {
        Bar
    },
    data() {
        return {
            profile: {},
            sortType: '',
            sortOrder: 'asc',
            selectedYear: null,
            selectedMonth: null,
            options: {
                responsive: true,
                events: ['click', 'mousemove'],
                onClick: (event, elements, chart) => {
                 if (elements[0]) {
                    const i = elements[0].index;
                    this.selectedYear = chart.data.labels[i];
                    this.getYearStatistics(this.selectedYear);
                 }
               },
               interaction: {
                    intersect: false,
               }
            },
            yearOptions: {
                responsive: true,
                onClick: (event, elements, chart) => {
                 if (elements[0]) {
                    const i = elements[0].index;
                    this.selectedMonth = chart.data.labels[i];
                    this.getManagerStatistics(this.selectedYear, this.selectedMonth);
                 }
               },
               interaction: {
                    intersect: false,
               }
            },
            loaded: false,
            showMonths: false,
            MonthsLoaded: false,
            showManagers: false,
            managersLoaded: false,
            chartData: {
                labels: [],
                datasets: [
                    {
                        label: '',
                        data: [],
                    },
                    {
                        label: '',
                        data: [],
                    },
                    {
                        label: '',
                        data: [],
                    }
                ]
            },
            yearChartData: {
                labels: [],
                datasets: [
                    {
                        label: '',
                        data: [],
                    },
                    {
                        label: '',
                        data: [],
                    },
                    {
                        label: '',
                        data: [],
                    }
                ]
            },
            statistics: {
                name: {},
                assets: {},
                incidents: {},
                dashboard_incidents: {}
            },
            statisticsShown: [],
        }
    },
    created() {
        this.getStatistics();
    },
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        getStatistics() {
            let self = this;
            fetch('/api/v1/s1monestats/incidentsbyyear')
            .then(response => { response.json().then(
                function(data) {
                   self.chartData.labels = data.incidents.incidents_by_year_keys;
                   self.chartData.datasets[0].label = self.profile.datetime.cancelled_no_description;
                   self.chartData.datasets[0].data = data.cancelled_no_description.incidents_by_year_values
                   self.chartData.datasets[1].label = self.profile.datetime.cancelled_with_description;
                   self.chartData.datasets[1].data = data.cancelled_with_description.incidents_by_year_values
                   self.chartData.datasets[2].label = self.profile.datetime.incidents;
                   self.chartData.datasets[2].data = data.incidents.incidents_by_year_values
                   self.loaded = true;
                });
            });
        },
        getYearStatistics(year) {
            let self = this;
            self.showMonths = true;
            self.MonthsLoaded = false;
            fetch('/api/v1/s1monestats/incidentsbymonth?year=' + year)
            .then(response => { response.json().then(
                function(data) {
                   self.yearChartData.labels = data.incidents.incidents_by_month_keys;
                   self.yearChartData.datasets[0].label = self.profile.datetime.cancelled_no_description;
                   self.yearChartData.datasets[0].data = data.cancelled_no_description.incidents_by_month_values
                   self.yearChartData.datasets[1].label = self.profile.datetime.cancelled_with_description;
                   self.yearChartData.datasets[1].data = data.cancelled_with_description.incidents_by_month_values
                   self.yearChartData.datasets[2].label = self.profile.datetime.incidents;
                   self.yearChartData.datasets[2].data = data.incidents.incidents_by_month_values
                   self.MonthsLoaded = true;
                });
            });
        },
        getManagerStatistics(year, month){
            let self = this;
            let monthNumber = moment().month(month).format('M') - 1;
            self.showManagers = true
            self.managersLoaded = false
            self.statisticsShown = []
            fetch('/api/v1/s1monestats/assetsbybuildingmanager?year=' + year + '&month=' + monthNumber)
            .then(response => { response.json().then(
                function(data) {
                    self.statistics.name = Object.keys(data.assets_by_building_manager)
                    self.statistics.assets = Object.values(data.assets_by_building_manager)
                    self.statistics.incidents = data.incidents_by_building_manager
                    self.statistics.dashboard_incidents = data.incidents_by_dashboard_by_building_manager
                    self.managersLoaded = true
                    self.convertStatistics()
                });
            });
        },
        convertStatistics(){
            for (let i = 0; i < this.statistics.name.length; i++) {
                let entry = {
                    name: this.statistics.name[i],
                    assets: this.statistics.assets[i],
                    incidents: this.statistics.incidents[this.statistics.name[i]] || 0,
                    incidents_assets: this.statistics.incidents[this.statistics.name[i]] && this.statistics.assets[i] ? (this.statistics.incidents[this.statistics.name[i]] / this.statistics.assets[i] * 100).toFixed(2) === "0.00" ? "-" : (this.statistics.incidents[this.statistics.name[i]] / this.statistics.assets[i] * 100).toFixed(2) + "%" : "-",
                    dashboard_incidents: this.statistics.incidents[this.statistics.name[i]] && this.statistics.dashboard_incidents[this.statistics.name[i]] ? ((((this.statistics.dashboard_incidents[this.statistics.name[i]] || 0) / this.statistics.incidents[this.statistics.name[i]]) * 100).toFixed(2) === "0.00" ? "0%" : ((((this.statistics.dashboard_incidents[this.statistics.name[i]] || 0) / this.statistics.incidents[this.statistics.name[i]]) * 100).toFixed(2) + "%")) : "-",
                    Qr_incidents: this.statistics.incidents[this.statistics.name[i]] && this.statistics.dashboard_incidents[this.statistics.name[i]] ? ((((this.statistics.incidents[this.statistics.name[i]] || 0) - (this.statistics.dashboard_incidents[this.statistics.name[i]] || 0)) / this.statistics.incidents[this.statistics.name[i]] * 100).toFixed(2) === "0.00" ? "0%" : ((((this.statistics.incidents[this.statistics.name[i]] || 0) - (this.statistics.dashboard_incidents[this.statistics.name[i]] || 0)) / this.statistics.incidents[this.statistics.name[i]] * 100).toFixed(2) + "%")) : "-"
                }
                this.statisticsShown = [...this.statisticsShown, entry];
            }
        },
        getDateMinusNMonths(n) {
            let today = new Date();
            today.setMonth(today.getMonth() - n);
            let dd = String(today.getDate()).padStart(2, '0');
            let mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
            let yyyy = today.getFullYear();
            return dd + '-' + mm + '-' + yyyy;
        },
        getCurrentDate() {
            let today = new Date();
            let dd = String(today.getDate()).padStart(2, '0');
            let mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
            let yyyy = today.getFullYear();
            return dd + '-' + mm + '-' + yyyy;
        },
        firstOfJanuary() {
            let today = new Date();
            let yyyy = today.getFullYear();
            let januaryFirst = new Date(yyyy, 0, 1); // Month is 0-indexed, so 0 is January
            let dd = String(januaryFirst.getDate()).padStart(2, '0');
            let mm = String(januaryFirst.getMonth() + 1).padStart(2, '0'); // January is 0, so +1 to get 01
            return dd + '-' + mm + '-' + yyyy;
        },
        sortTable(sorter) {
            this.sortType = sorter;
            this.sortOrder = this.sortOrder !== 'asc' ? 'asc' : 'desc';
            this.statisticsShown = sortBy(this.statisticsShown, (item) => {
            if (this.sortType === 'name') {
            return item.name.toLowerCase();
            } else if (this.sortType === 'assets') {
            return item.assets;
            } else if (this.sortType === 'incidents') {
            return item.incidents;
            } else if (this.sortType === 'incidents_assets') {
            return parseFloat(item.incidents_assets.replace('%', ''));
            } else if (this.sortType === 'dashboard_incidents') {
            return parseFloat(item.dashboard_incidents.replace('%', ''));
            } else if (this.sortType === 'Qr_incidents') {
            return parseFloat(item.Qr_incidents.replace('%', ''));
            }
            });
            if (this.sortOrder === 'desc') {
            this.statisticsShown.reverse();
            }
        },
    }
}
</script>
