<template>
    <div class="block block--account">
        <div class="account-info">
            <h4 :class="{active: activeEmployeesToggled}" @click="activeEmployeesToggled = !activeEmployeesToggled" style="margin-bottom: 0; cursor: pointer;">{{profile.employees.employees}} ({{employees.length}})<i class="fas fa-chevron-down" v-if="employees.length" style="margin-left: 10px;"></i></h4>
            <span class="edit-button icon" @click="newEmployeeModalOpen = true">
                <i class="fa-solid fa-plus"></i>
            </span>
            <div class="employees" style="margin-top: 20px;" v-if="employees.length" v-show="activeEmployeesToggled">
                <div class="employee" v-for="(item, index) in employees" :key="index">
                    <span class="edit-button icon" @click="removeEmployeeModal(item)" v-if="!item.edit">
                        <i class="fas fa-trash-alt"></i>
                    </span>
                    <span class="edit-button icon" @click="disableEmployeeModal(item)" v-if="!item.edit && !item.employee_disabled" v-tooltip.left="profile.employees.delete_employee" style="right: 36px;">
                        <i class="fas fa-toggle-off"></i>
                    </span>
                    <span class="edit-button icon" data-testid="employeeEditButton" @click="editEmployee(item)" v-if="!item.edit && !item.employee_disabled" style="right: 65px;">
                        <i class="fas fa-pen"></i>
                    </span>
                    <span class="edit-button icon" @click="checkEmailActive(item)" v-if="item.edit" v-tooltip.right="profile.buttons.save" data-testid="employeeSaveButton">
                        <i class="fas fa-save"></i>
                    </span>
                    <div v-if="!item.edit">
                        <h3 data-testid="employeeName">{{item.actual_name}}</h3>
                        <span class="username"><i class="fas fa-at"></i> {{item.username}}</span> <span v-if="item.mobile" class="seperator">|</span>
                        <span class="phone" v-if="item.mobile" data-testid="employeePhone"><i class="fas fa-phone-alt"></i> {{item.mobile}}</span>
                    </div>
                    <div v-else class="account-contents">
                        <h3>{{profile.employees.edit_employee}}</h3>
                        <div class="row">
                            <div class="col-sm-6">
                                <label>{{profile.employees.name}}</label>
                                <input type="text" class="form-control" v-model="item.actual_name" data-testid="employeeNameEdit"/>
                            </div>
                            <div class="col-sm-6">
                                <label>{{profile.employees.phone}}</label>
                                <input type="text" class="form-control" v-model="item.tempMobile" @input="validateEmployeePhone(item)" :class="{error: item.invalidPhone}" data-testid="employeePhoneEdit"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <label>{{profile.nav.account.account}}</label>
                                <input type="text" class="form-control" v-model="item.username" @input="validateEmail(item)" :class="{error: item.invalidEmail}" data-testid="supplierEmployeeEditEmail" />
                            </div>
                            <div class="col-sm-6" v-if="showEmailErrorActive || showEmailErrorInactive">
                                <div class="notification notification--error">
                                    <p v-if="showEmailErrorActive"><i class="fas fa-exclamation-circle"></i> {{profile.bm_exists}}</p>
                                    <p v-if="showEmailErrorInactive"><i class="fas fa-exclamation-circle"></i> {{profile.employee_exists}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <hr />
                    <h3 :class="{active: inactiveEmployeesToggled}" style="cursor: pointer; margin-bottom: 0;" @click="inactiveEmployeesToggled = !inactiveEmployeesToggled">{{profile.inactive_employees}} ({{inactiveEmployees.length}})<i class="fas fa-chevron-down" v-if="inactiveEmployees.length" style="margin-left: 10px;"></i></h3>
                </div>
            </div>
            <div class="employees" style="margin-top: 20px;" v-if="inactiveEmployees.length" v-show="inactiveEmployeesToggled">
                <div class="employee" v-for="(item, index) in inactiveEmployees" :key="index" :class="{disabled: item.employee_disabled}">
                    <span class="edit-button icon" @click="enableEmployeeModal(item)" v-tooltip.right="profile.enable_employee">
                        <i class="fas fa-toggle-on"></i>
                    </span>
                    <div>
                        <h3>{{item.actual_name}}</h3>
                        <span class="username"><i class="fas fa-at"></i> {{item.username}}</span> <span v-if="item.mobile" class="seperator">|</span>
                        <span class="phone" v-if="item.mobile"><i class="fas fa-phone-alt"></i> {{item.mobile}}</span>
                    </div>
                </div>
            </div>
        </div>
        <NewEmployeeModal v-if="newEmployeeModalOpen" @close="getEmployees()" />
        <DisableEmployeeModal v-if="disableEmployeeModalOpen" @close="getEmployees()" :selectedUser="selectedUser" />
        <EnableEmployeeModal v-if="enableEmployeeModalOpen" @close="getEmployees()" :selectedUser="selectedUser" />
        <RemoveEmployeeModal v-if="removeEmployeeModalOpen" @close="getEmployees()" :selectedUser="selectedUser" :userType="'supplier'"  />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-supplier.js';
import NewEmployeeModal from "@/components/supplier/modals/NewEmployeeModal.vue";
import DisableEmployeeModal from "@/components/supplier/modals/DisableEmployeeModal.vue";
import EnableEmployeeModal from "@/components/supplier/modals/EnableEmployeeModal.vue";
import RemoveEmployeeModal from "@/components/modals/RemoveEmployeeModal.vue";
import sortBy from 'lodash/sortBy';

export default {
    name: 'MainBlock',
    mixins: [setLocale],
    components: {
        NewEmployeeModal,
        DisableEmployeeModal,
        EnableEmployeeModal,
        RemoveEmployeeModal
    },
    data: function () {
        return {
            employees: [],
            inactiveEmployees: [],
            inactiveEmployeesToggled: false,
            activeEmployeesToggled: false,
            newEmployeeModalOpen: false,
            disableEmployeeModalOpen: false,
            enableEmployeeModalOpen: false,
            employeesSortType: '',
            showEmailErrorActive: false,
            showEmailErrorInactive: false,
            originalEmail: '',
            selectedUser: {},
            removeEmployeeModalOpen: false,

        }
    },
    props: ['accountInfo'],
    created() {
        this.getEmployees();
    },
    methods: {
        getEmployees() {
            this.newEmployeeModalOpen = false;
            this.disableEmployeeModalOpen = false;
            this.enableEmployeeModalOpen = false;
            this.removeEmployeeModalOpen = false;
            let self = this;
            fetch('/api/v1/repaircompanyemployee')
            .then(response => { response.json().then(
                function(data) {
                    self.employees = [];
                    self.inactiveEmployees = [];
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        item.validPhone = false;
                        item.invalidPhone = false;        
                        item.tempMobile = item.mobile;
                        item.edit = false;
                        if (item.username.includes('DISABLED') && item.actual_name) {
                            let name = item.username.split(/[, ]+/).pop();
                            item.username = name;
                            self.inactiveEmployees.push(item);
                        }
                        else {
                            if (item.username !== self.userName && item.actual_name) {
                                self.employees.push(item);
                            }
                        }
                    }
                    self.employees = sortBy(self.employees, 'actual_name');
                    self.inactiveEmployees = sortBy(self.inactiveEmployees, 'actual_name');
                });
            });
        },
        sortTable(sorter) {
            this.sortOrder = this.sortOrder !== 'asc' ? 'asc' : 'desc';
            this.actionRequiredItems = sortBy(this.employees, sorter);
            if (this.sortOrder === 'desc') {
                this.employees.reverse();
            }
        },
        editEmployee(item) {
            item.edit = true;
            this.originalEmail = item.username;
        },
        saveEmployee(item) {
            if (!item.invalidPhone) {
                item.edit = false;
                item.mobile = item.tempMobile;
                const data = new URLSearchParams();
                data.append('action', 'edit');
                data.append('data[' + item.id_shiro_user + '][actual_name]', item.actual_name);
                data.append('data[' + item.id_shiro_user + '][mobile]', item.mobile);
                data.append('data[' + item.id_shiro_user + '][username]', item.username);
                let self = this;
                fetch('/api/v1/repaircompanyemployee', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                });
            }
        },
        disableEmployeeModal(item) {
            this.selectedUser = item;
            this.disableEmployeeModalOpen = true;
        },
        enableEmployeeModal(item) {
            this.selectedUser = item;
            this.enableEmployeeModalOpen = true;
        },
        removeEmployeeModal(item) {
            this.selectedUser = item;
            this.removeEmployeeModalOpen = true;
        },
        validateEmployeePhone(item) {
            if (item.tempMobile) {
                let pattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
                if (pattern.test(item.tempMobile)) {
                    item.validPhone = true;
                    item.invalidPhone = false;
                }
                else {
                    item.invalidPhone = true;
                    item.validPhone = false;
                }
            }
            else {
                item.validPhone = true;
                item.invalidPhone = false;
            }
        },
        validateEmail(item) {
            this.emailexists = false;
            let pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            if (pattern.test(item.username)) {
                item.invalidEmail = false;
            }
            else {
                item.invalidEmail = true;
            }
        },
        checkEmailActive(item) {
            let self = this;
            if (item.username === this.originalEmail) {
                self.checkEmailInactive(item);
            } else {
                fetch('/api/v1/emailexists/buildingmanageraccounts?email=' + item.username)
                .then(response => { response.text().then(
                    function(data) {
                        if (data === 'false') {
                            self.showEmailErrorActive = false;
                            self.checkEmailInactive(item);
                        } else if (data === 'true') {
                            self.showEmailErrorActive = true;
                        }
                    });
                });
            }
        },
        checkEmailInactive(item) {
            let self = this;
            if (item.username === this.originalEmail) {
                self.saveEmployee(item);
            } else {
                fetch('/api/v1/emailexists?email=' + item.username)
                .then(response => { response.text().then(
                    function(data) {
                        if (data === 'false') {
                            self.showEmailErrorInactive = false;
                            self.saveEmployee(item);
                        }
                        else {
                            self.showEmailErrorInactive = true;
                        }
                    });
                });
            }
        },
    }
}
</script>
