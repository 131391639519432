<template>
    <div class="modal-wrapper">
        <div class="modal">
            <div class="modal-header">
                <h3>Ongeprinte stickers exporteren </h3>
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <p> Wilt u alle stickers exporteren in een CSV bestand? </p>
                <br>
                <div class="row">
                    <div class="col-sm-3">
                        <span class="control-label" style="right:10px; position: absolute; margin-top: 14px">Vanaf object ID</span>
                    </div>
                    <div class="col-sm-9">
                        <input type="number" min="1" class="form-control" v-model="fields.from_id_asset" />
                    </div>
                    <div class="col-sm-1"></div>
                </div>
                <div class="row" style="margin-top: 20px;">
                    <div class="col-sm-3">
                        <span class="control-label" style="right:10px; position: absolute; margin-top: 14px">Tot en met object ID</span>
                    </div>
                    <div class="col-sm-9">
                        <input type="number" min="1" class="form-control" v-model="fields.to_id_asset" />
                    </div>
                    <div class="col-sm-1"></div>
                </div>
                <div class="row" v-if="assetIdError" style="margin-top: 20px;">
                    <div class="col">
                        <div class="notification notification--error">
                            <p><i class="fas fa-exclamation-circle"></i> Het eerste object ID moet lager zijn dan het tweede object ID</p>
                        </div>
                    </div>
                </div>
                <button class="button button--icon button--right" style="margin-top:20px" v-on:click="exportStickers()"><i class="fa-solid fa-qrcode"></i>Stickers exporteren</button>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import sortBy from 'lodash/sortBy';
export default {
    mixins: [setLocale],
    name: 'ExportStickerCsvModal',
    props: ['managerID'],
    data() {
        return {
            profile: {},
            fields: {
                from_id_asset: '',
                to_id_asset: '',
            },
            assetIdError: false,
        }
    },
    created() {
        document.querySelector('body').classList.add('modal-open');
        document.onkeydown = (evt) => {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                this.closeModal();
            }
        };
    },
    methods: {
        exportStickers(){
            let self = this;
            if (this.fields.from_id_asset === '' || this.fields.to_id_asset === '' || this.fields.from_id_asset > this.fields.to_id_asset) {
                self.assetIdError = true;
                return;
            }
            var url = "/api/v1/stickercreation/generateprintcsv?id_building_manager=" + this.managerID + "&from_id_asset=" + this.fields.from_id_asset + "&to_id_asset=" + this.fields.to_id_asset;
            window.open(url, '_blank');
        },
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        }
    }
}


</script>
