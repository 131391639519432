<template>
    <div v-if="profile.places">
        <div class="main-content">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <a class="back-link" @click="$router.go(-1)"><i class="fas fa-chevron-left"></i>{{profile.nav.back_link}}</a>
                    </div>
                </div>
                <div class="block block--assets">
                    <section class="incident-head">
                        <h1 data-testid="newLocTitle">{{profile.incidents.new_incident.title}}</h1>
                    </section>
                    <form v-on:submit.prevent="createNewIncident()" style="overflow: hidden; margin-top: 15px;" v-if="!showAgreementMessage">
                        <h5 style="font-size: 21px; margin-bottom: 15px;">{{profile.accountPage.account.general}}</h5>
                        <p style="margin-bottom: 10px; font-size: 16px;"><b>{{ profile.incidents.new_incident.search_loc }}</b></p> 
                        <div class="form-field clearable" :class="{focus: placeSearchTerm.length, error: placeError}" style="margin-bottom: 10px;">
                            <label v-if="!selectedPlace.id_place">{{profile.incidents.new_incident.loc_placeholder}} *</label>
                            <div>
                                <div class="selected-info seperate" v-if="selectedPlace.name" style="border: 2px solid #00C900;">
                                    <h4>{{selectedPlace.name}}</h4>
                                    <p>{{selectedPlace.address}} {{selectedPlace.house_number}}, {{selectedPlace.zipcode}} {{selectedPlace.city}}</p>
                                    <span>{{profile.incident_detail.floor_name}} {{selectedPlace.floor_name}}</span>
                                    <span v-if="selectedPlace.id_place" class="remove-selection" @click="removePlaceSelection()"><i class="fas fa-times"></i></span>
                                    <span class="general-label" v-if="selectedPlace.floor_name === 'VVEVEST'">{{profile.incidents.new_incident.general_spaces}}</span>
                                </div>
                                <input type="text" ref="place" class="form-control" data-testid="inputLocation" @input="e => placeSearchTerm = e.target.value" :value="placeSearchTerm" @keyup="searchPlaces()" v-if="!selectedPlace.name" />
                            </div>
                        </div>
                        <div class="row" v-if="!selectedPlace.name">
                            <div class="col-md-6">
                                <div class="form-field" :class="{focus:fields.zipcode.length, error: !validZipCode}">
                                    <label>{{profile.places.placetable.zipcode}} *</label>
                                    <input type="text" class="form-control" v-model="fields.zipcode" @input="zipCodeSearch()">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-field" :class="{focus:fields.house_number.length}">
                                    <label>{{profile.places.placetable.house_number}} *</label>
                                    <input type="text" class="form-control" v-model="fields.house_number" @input="zipCodeSearch()">
                                </div>
                            </div>
                        </div>
                        <div class="notification notification--error" data-testid="noLocationError" style="margin-bottom: 17px;" v-if="placesNoResults"><i class="fas fa-exclamation-triangle"></i> {{profile.incidents.new_incident.place_error}}</div>
                        <ul v-if="places.length || generalPlaces.length" class="dropdown-list" style="padding: 0; margin-top: 0; border-radius: 10px; max-height: 400px; overflow: auto;">
                            <li v-for="place in generalPlaces" :key="place.id_place" class="dropdown-list-item" data-testid="listLocation" v-on:click="selectPlace(place)">
                                <h4>{{place.name}}</h4>
                                <p>{{place.address}} {{place.house_number}}, {{place.zipcode}} {{place.city}}</p>
                                <span>{{profile.incident_detail.floor_name}} {{place.floor_name}}</span>
                                <span class="general-label" v-if="place.floor_name === 'VVEVEST'">{{profile.incidents.new_incident.general_spaces}}</span>
                            </li>
                            <li v-for="place in places" :key="place.id_place" class="dropdown-list-item" data-testid="listLocation" v-on:click="selectPlace(place)">
                                <h4>{{place.name}}</h4>
                                <p>{{place.address}} {{place.house_number}}, {{place.zipcode}} {{place.city}}</p>
                                <span>{{profile.incident_detail.floor_name}} {{place.floor_name}}</span>
                            </li>
                        </ul>
                        <hr style="margin-top: 0;" />
                        <div class="row" v-if="selectedPlace.id_place">
                            <div class="col-sm-6">
                                <div class="dropdown-section">
                                    <span class="dropdown" data-testid="selectObject" v-on:click="assetsDropdownToggled = !assetsDropdownToggled" :class="{active: assetsDropdownToggled, error: assetError, valid: selectedAssetId}">
                                        <span v-if="!selectedAssetId">{{profile.incidents.new_incident.choose_asset}} *</span>
                                        <span v-if="selectedAssetId" class="selected">{{selectedAssetName}}</span>
                                        <i class="fas fa-chevron-down chevron"></i>
                                    </span>
                                    <div class="dropdown-list" data-testid="listObject" v-if="assetsDropdownToggled" :class="{search: assets.length > 9}">
                                        <input type="text" @input="e => assetSearchTerm = e.target.value" :value="assetSearchTerm" placeholder="Zoek een locatienaam..." @keyup="searchAssets()" v-if="assets.length > 9"  />
                                        <ul>
                                            <li v-for="asset in assets" v-on:click="selectAsset(asset)" :key="asset.id_asset" v-show="!tempAssets.length && !assetsNoResults">
                                                {{asset.name}}
                                                <span class="active-label" v-if="!asset.hidden && asset.qr_code">{{profile.assets.assettable.active}}</span>
                                                <span class="inactive-label" v-if="asset.hidden">{{profile.assets.inactive}}</span>
                                            </li>
                                            <li v-for="(asset, index) in tempAssets" v-on:click="selectAsset(asset)" :key="index" v-show="tempAssets.length">{{asset.name}}</li>
                                            <li v-if="assetsNoResults" class="no-results">{{profile.dashboard.building_manager.no_resultss}}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>   
                            <div class="col-sm-6" v-if="!inactiveAsset && selectedAssetId">
                                <div class="dropdown-section">
                                    <span class="dropdown" data-testid="selectCategory" v-on:click="categoryDropdownToggled = !categoryDropdownToggled" :class="{active: categoryDropdownToggled, error: categoryError, valid: selectedCategory.dutch_name}">
                                        <span v-if="!selectedCategory.id_incident_category">{{profile.incident_detail.category}} *</span>
                                        <span v-if="selectedCategory.id_incident_category && locale === 'nl'" class="selected">{{selectedCategory.dutch_name}}</span>
                                        <span v-if="selectedCategory.id_incident_category && locale === 'en'" class="selected">{{selectedCategory.english_name}}</span>
                                        <i class="fas fa-chevron-down chevron"></i></span>
                                    <div class="dropdown-list" v-if="categoryDropdownToggled" :class="{search: categories.length > 9}">
                                        <p style="font-weight: 500; padding: 15px; border-bottom: 1px solid #d9d9d9; margin: 0 -15px;">{{profile.assets.objectcategories}}</p>
                                        <ul>
                                            <li v-for="category in assetCategories" v-on:click="selectCategory(category)" :key="category.id_category">
                                                <span v-if="locale === 'nl'">{{category.dutch_name}}</span>
                                                <span v-if="locale === 'en'">{{category.english_name}}</span>
                                            </li>
                                            <span class="border"></span>
                                        </ul>
                                        <p style="font-weight: 500; padding: 15px; border-bottom: 1px solid #d9d9d9; margin: 0 -15px;">{{profile.assets.other_categories}}</p>
                                        <input type="text" @input="e => categorySearchTerm = e.target.value" :value="categorySearchTerm" :placeholder="profile.incident_detail.search_category" @keyup="searchCategories()" v-if="categories.length > 9"  />
                                        <ul>
                                            <li v-for="category in categories" v-on:click="selectCategory(category)" :key="category.id_category" v-show="!tempCategories.length && !categoriesNoResults">
                                                <span data-testid="selectCategoryResultNl" v-if="locale === 'nl'">{{category.dutch_name}}</span>
                                                <span data-testid="selectCategoryResultEn" v-if="locale === 'en'">{{category.english_name}}</span>
                                            </li>
                                            <li v-for="(category, index) in tempCategories" v-on:click="selectCategory(category)" :key="index" v-show="tempCategories.length">
                                                <span data-testid="selectCategoryResultIndexNl" v-if="locale === 'nl'">{{category.dutch_name}}</span>
                                                <span data-testid="selectCategoryResultIndexEn" v-if="locale === 'en'">{{category.english_name}}</span>
                                            </li>
                                            <li v-if="categoriesNoResults" class="no-results">{{profile.dashboard.building_manager.no_resultss}}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div> 
                        </div>
                        <div class="notification notification--warning" v-if="inactiveAsset" style="padding-right: 20px;">
                            {{ profile.incidents.new_incident.inactive_asset }} 
                            <span class="button" @click="activateAsset()">{{ profile.incidents.new_incident.activate_asset }}</span>
                            <span class="button button--outline" @click="clearAsset()" style="margin-right: 10px;">{{ profile.incidents.new_incident.choose_other_asset }}</span>
                        </div>
                        <div v-if="showAgreementChoice && agreementflow" style="margin: 10px 0 30px 0;">
                            <label style="margin-bottom: 15px;">{{profile.owners.agreementflow.agreement_flow_choice}}</label>
                            <div class="button-group">
                                <span class="switch-button" data-testid="setAgreementTrueButton" :class="{active: agreementFlowChoice}" @click="setAgreementChoice(true)">{{profile.owners.agreementflow.get_agreement}}</span>
                                <span class="switch-button" data-testid="setAgreementFalseButton" style="margin-left: -1px;" :class="{active: agreementFlowChoice == false}" @click="setAgreementChoice(false)">{{profile.owners.agreementflow.dont_get_agreement}}</span>
                            </div>
                        </div>
                        <div v-if="agreementFlowChoice">
                            <h5 style="font-size: 21px; margin-bottom: 10px;">{{profile.incident_detail.imagery}}</h5>
                            <p style="font-weight: 500;">{{profile.accountPage.account.upload_media}}<br /></p>
                            <div class="form-field-upload">
                                <input type="file" @change="fileChange" multiple />
                            </div>
                        </div>
                        <div class="form-field" :class="{focus:fields.descr.length, error: descriptionError, valid: fields.descr.length}" v-if="!inactiveAsset">
                            <label>{{profile.incidents.new_incident.description}} *</label>
                            <textarea class="form-control" v-model="fields.descr" data-testid="inputDescription" @input="checkDescr()" style="height: 140px;"></textarea>
                        </div>
                        <div v-if="mainReady">
                            <hr />
                            <h5 style="font-size: 21px; margin-bottom: 15px;">{{profile.dashboard.reporter_info}}</h5>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-field" :class="{focus:fields.name.length, error: nameError, valid: fields.name.length}">
                                        <label>{{profile.incidents.new_incident.reporter_name}} *</label>
                                        <input type="text" class="form-control" v-model="fields.name" data-testid="inputName" @input="checkName()" ref="name">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <select class="form-select" v-model="fields.reporter_locale" data-testid="newIncidentLanguage" required>
                                    <option disabled>{{profile.incidents.new_incident.reporter_language}} *</option>
                                    <option value="nl-NL">{{profile.incidents.new_incident.NL}}</option>
                                    <option value="fr-FR">{{profile.incidents.new_incident.FR}}</option>
                                    <option value="de-DE">{{profile.incidents.new_incident.DE}}</option>
                                    <option value="en-GB">{{profile.incidents.new_incident.EN}}</option>
                                    <option value="uk-UA">{{profile.incidents.new_incident.UA}}</option>
                                    <option value="ru-RU">{{profile.incidents.new_incident.RU}}</option>
                                    <option value="pl-PL">{{profile.incidents.new_incident.PL}}</option>
                                    <option value="it-IT">{{profile.incidents.new_incident.IT}}</option>
                                </select>
                                </div>
                            </div>
                            <div class="row">                               
                                <div class="col-md-6">
                                    <vue-tel-input v-model="fields.phone" class="form-control" data-testid="inputPhone" v-bind="bindProps" style="padding-left: 0;" :class="{valid: validPhone, error: invalidPhone}" @input="validatePhone"></vue-tel-input>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-field" :class="{focus:fields.email.length, valid: validEmail, error: invalidEmail}">
                                        <label>{{profile.incidents.new_incident.emailaddress}}</label>
                                        <input type="text" class="form-control" data-testid="inputEmail" v-model="fields.email" @input="validateEmail()">
                                    </div>
                                </div>
                            </div>
                            <div class="switch-wrapper" :class="{disabled: invalidPhone || !fields.phone.length, active: fields.whatsapp}" style="margin-bottom: 15px;">
                                <div class="inner" @click="fields.whatsapp = !fields.whatsapp">
                                    <i class="fa-brands fa-whatsapp"></i>
                                    <p>{{profile.incidents.new_incident.add_to_whatsapp}} <i class="fas fa-info-circle" v-tooltip.right="profile.incidents.new_incident.no_mobile"></i></p>
                                    <span class="label">{{ profile.switches.add_to_wa }}</span>
                                    <label class="switch" v-tooltip.right="profile.incidents.new_incident.add_phone">
                                        <span class="slider round" data-testid="switchPhone"></span>
                                    </label>
                                </div>
                            </div>  
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-field" :class="{focus:fields.reporter_zipcode.length, error: !validReporterZipCode}">
                                        <label>{{profile.places.placetable.zipcode}}</label>
                                        <input type="text" class="form-control" v-model="fields.reporter_zipcode" @input="zipCodeValidation()">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-field" :class="{focus:fields.reporter_house_number.length}">
                                        <label>{{profile.places.placetable.house_number}}</label>
                                        <input type="text" class="form-control" v-model="fields.reporter_house_number" @input="zipCodeValidation()">
                                    </div>
                                </div>
                            </div>
                            <div class="notification notification--error" style="margin-bottom: 17px;" v-if="addressError"><i class="fas fa-exclamation-triangle"></i> {{profile.places.placetable.valid_zip}}</div>
                            <div class="row" v-else>
                                <div class="col-md-6" v-if="fields.reporter_address">
                                    <div class="form-field" :class="{focus:fields.reporter_address.length}">
                                        <label>{{profile.places.placetable.address}}</label>
                                        <input type="text" class="form-control" v-model="fields.reporter_address">
                                    </div>
                                </div>
                                <div class="col-md-6" v-if="fields.reporter_city">
                                    <div class="form-field" :class="{focus:fields.reporter_city.length}">
                                        <label>{{profile.places.placetable.city}}</label>
                                        <input type="text" class="form-control" v-model="fields.reporter_city">
                                    </div>
                                </div>
                            </div> 
                            <div style="margin-bottom: 20px;" v-if="industry === 'housing' && vve">
                                <label>{{profile.incident_detail.renterOrOwner}}</label>
                                <div class="button-group" style="margin-bottom: 30px;">
                                    <span class="switch-button" id="owner-button" :class="{active: !vveOwner}" @click="vveOwner = false">{{profile.incident_detail.renter}}</span>
                                    <span class="switch-button" id="resident-button" :class="{active: vveOwner}" @click="vveOwner = true">{{profile.incident_detail.owner}}</span>
                                </div>
                            </div>  
                            <div class="notification notification--error" style="margin-top: 20px;" v-if="channelsError">
                                <p>{{profile.incidents.new_incident.channels_error}}</p>
                            </div>
                        </div>
                        <button type="submit" class="button button--icon" data-testid="buttonSubmit" id="incidentSubmit" v-if="!incidentCreate && mainReady"><i class="fa-solid fa-plus"></i>{{profile.dashboard.building_manager.create_incident}}</button>
                    </form>
                    <div class="notification notification--warning" data-testid="agreementIncidentText" style="margin-bottom: 5px; width: 100%;" v-if="showAgreementMessage">
                        <p style="font-weight: 500;">{{profile.incidents.new_incident.agreement_message}}</p>
                    </div>
                    <span class="notification notification--error" v-if="backendError" style="margin-bottom: 10px;">{{profile.backend_error}}</span>
                    <span class="notification notification--error" v-if="uploadError" style="margin-bottom: 10px;">{{profile.accountPage.account.upload_error_incident}}</span>
                    <span class="button button--icon" v-if="showAgreementMessage" data-testid="agreementIncidentClose" style="margin-top: 15px; float: right;" @click="$router.go(-1)">{{profile.incident_detail.back_to_dashboard}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import { mapState } from 'vuex';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import { VueTelInput } from 'vue-tel-input';
import debounce from 'lodash/debounce';

export default {
    mixins: [setLocale],
    components: {
        VueTelInput
    },
    data() {
        return {
            profile: {},
            places: [],
            generalPlaces: [],
            assets: [],
            categories: [],
            fields: {
                category: null,
                name: '',
                email: '',
                phone: '',
                descr: '',
                whatsapp: false,
                reporter_locale: 'nl-NL',
                zipcode: '',
                house_number: '',
                reporter_address: '',
                reporter_house_number: '',
                reporter_zipcode: '',
                reporter_city: '',
                file: null
            },
            vveOwner: false,
            validPhone: false,
            invalidPhone: true,
            formattedPhone: '',
            validEmail: false,
            invalidEmail: false,
            validZipCode: true,
            validReporterZipCode: true,
            addressError: false,
            assetsDropdownToggled: false,
            selectedPlace: {},
            selectedAssetId: 0,
            selectedAssetName: '',
            selectedCategory: {},
            categoryDropdownToggled: false,
            categorySearchTerm: '',
            tempCategories: [],
            categoriesNoResults: false,
            assetCategories: [],
            assetsNoResults: false,
            placesNoResults: false,
            tempAssets: [],
            placeSearchTerm: '',
            assetSearchTerm: '',
            nameError: false,
            descriptionError: false,
            placeError: false,
            assetError: false,
            categoryError: false,
            channelsError: false,
            bindProps: {
                defaultCountry: "NL",
                disabledFetchingCountry: false,
                disabled: false,
                disabledFormatting: false,
                required: false,
                enabledCountryCode: false,
                enabledFlags: true,
                autocomplete: "off",
                name: "telephone",
                maxLen: 25,
                dropdownOptions: {
                    disabledDialCode: false,
                    showFlags: true
                },
                inputOptions: {
                    showDialCode: false,
                    placeholder: '',
                }
            },
            loading: false,
            uploadError: false,
            videoPresent: false,
            numberOfFiles: 0,
            intCode: '',
            incidentCreate: false,
            showAgreementMessage: false,
            mainReady: false,
            agreementFlowSetting: null,
            agreementFlowCategories: [],
            showAgreementChoice: null,
            agreementFlowChoice: null,
            inactiveAsset: false,
            backendError: false,
        }
    },
    computed: mapState(['bmId', 'admin', 'agreementflow', 'twinq', 'vve']),
    created() {
        document.title = 'S1MONE | ' + this.profile.dashboard.building_manager.create_incident;
        this.bindProps.inputOptions.placeholder = this.profile.accountPage.account.contactmobile;
        setTimeout(function () {
            if (this.vve) {
                this.vveOwner = true;
            }
            else {
                this.vveOwner = false;
            }
            this.getCategories();

        }.bind(this), 500)
    },
    mounted() {
        this.$refs.place.focus();
    },
    methods: {
        getCategories() {
            let self = this;
            fetch('/api/v1/incident_category/bybuildingmanager?id_building_manager=' + this.bmId)
            .then(response => { response.json().then(
                function(data) {
                    self.categories = data.data;
                    self.categories = uniqBy(self.categories, 'id_incident_category');
                    for (var i = 0; i <self.categories.length; i++) {
                        let category = self.categories[i];
                        category.dutch_name = category.dutch_name.split('*')[0];
                        category.english_name = category.english_name.split('*')[0];
                        if (category.dutch_name.includes('-2') || category.dutch_name.includes('-3')|| category.dutch_name.includes('-4')) {
                            self.categories.splice(i, 1);
                        }
                    }
                    if (self.locale === 'nl') {
                        self.categories = sortBy(self.categories, 'dutch_name');
                    }
                    else {
                        self.categories = sortBy(self.categories, 'english_name');
                    }
                    if ((window.location.href.includes('localhost') || window.location.href.includes('waldorf')) && self.bm === 70) {
                        self.preFillAll();
                    }
                });
            });
        },
        getAssets() {
            let self = this;
            fetch('/api/v1/asset/activebyplacewithqr?id_place=' + this.selectedPlace.id_place)
            .then(response => { response.json().then(
                function(data) {
                    if (data.data.length) {
                        self.assets = [];
                        self.fields.asset = '';
                        for (var i = 0; i < data.data.length; i++) {
                            let item = data.data[i];
                            if (item.name) {
                                self.assets.push(item);
                            } 
                        }
                        if (self.assets.length) {
                           self.agreementFlowSetting = self.assets[0].owner_agreement_flow;  
                        }
                        if (self.agreementFlowSetting === 'FLOW_ON_CATEGORY') {
                            self.getAgreementFlow();
                        }
                        if (self.assets.length === 1) {
                            self.selectAsset(self.assets[0]);
                        }
                    }
                });
            });
        },
        getAgreementFlow() {
            let self = this;
            fetch('/api/v1/agreement_flow_person/catsbyidowner?id_owner=' + this.selectedPlace.id_owner)
            .then(response => { response.json().then(
                function(data) {
                    self.agreementFlowCategories = data.data;
                });
            });
        },
        selectPlace(place) {
            this.places = [];
            this.generalPlaces = [];
            this.selectedPlace = place;
            this.placeSearchTerm = '';
            this.fields.zipcode = '';
            this.fields.house_number = '';
            this.getAssets();
            this.selectedAssetId = 0;
            this.assetsDropdownToggled = false;
            this.placeError = false;
            if (place.floor_name === 'VVEVEST') {
                this.fields.reporter_address = place.address;
                this.fields.reporter_house_number = place.house_number;
                this.fields.reporter_zipcode = place.zipcode;
                this.fields.reporter_city = place.city;
            }
        },
        selectAsset(asset) {
            this.assetsDropdownToggled = false;
            this.selectedAssetName = asset.name;
            this.selectedAssetId = asset.id_asset;
            this.tempAssets = [];
            this.assetSearchTerm = '';
            this.assetError = false;
            if (this.fields.descr && this.selectedCategory.id_incident_category && (this.showAgreementChoice && this.agreementFlowChoice !== null || (this.showAgreementChoice === null && !this.agreementFlowChoice))) {
                this.mainReady = true;
            }
            if (asset.hidden) {
                this.inactiveAsset = true;
            }
            else {
                this.inactiveAsset = false;
            }
            this.getAssetCategories(asset); 
        },
        getAssetCategories(asset) {
            let self = this;
            fetch('/open-api/v1/asset/limited/qr_code?qr_code=' + asset.qr_code)
            .then(response => { response.json().then(
                function(data) {
                    self.assetCategories = data.categories;
                    if (self.locale === 'nl') {
                        self.assetCategories = sortBy(self.assetCategories, 'dutch_name');
                    }
                    else {
                        self.assetCategories = sortBy(self.assetCategories, 'english_name');
                    }
                    console.log(self.assetCategories);
                });
            });
        },
        clearAsset() {
            this.assetsDropdownToggled = true;
            this.selectedAssetName = '';
            this.selectedAssetId = 0;
            this.inactiveAsset = false;
        },
        activateAsset() {
            const data = new URLSearchParams();
            data.append('id_asset', this.selectedAssetId);
            data.append('hidden', false);
            let self = this;
            fetch('/api/v1/asset/update', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.inactiveAsset = false;
            });
        },
        searchPlaces: debounce(function (e) {
            if (this.placeSearchTerm.length > 2) {
                this.fields.zipcode = '';
                this.fields.house_number = '';
                let searchParam = '';
                if (this.placeSearchTerm) {
                    searchParam = '?search_name=' + this.placeSearchTerm;
                }
                let self = this;
                fetch('/api/v1/place/bybuildingmanageronlywithassets' + searchParam)
                .then(response => { response.json().then(
                    function(data) {
                        self.places = [];
                        self.generalPlaces = [];
                        for (var i = 0; i < data.data.length; i++) {
                            let place = data.data[i];
                            if (place.floor_name === '0') {
                                if (self.locale === 'nl') {
                                    place.floor_name = 'BG';
                                }
                                else {
                                    place.floor_name = 'GF';
                                }
                            }
                            if (place.floor_name === 'VVEVEST') {
                                self.generalPlaces.push(place);
                            }
                            else {
                                self.places.push(place);
                            }
                        }
                        if (!self.places.length && !self.generalPlaces.length) {
                            self.placesNoResults = true;
                        }
                        else {
                            self.placesNoResults = false;
                        }
                    });
                });
            }
            else {
                this.placesNoResults = false;
                this.places = [];
                this.generalPlaces = [];
            }
        }, 500),
        removePlaceSelection() {
            this.selectedPlace = {};
            this.generalPlaces = [];
            this.places = [];
            this.fields.reporter_address = '';
            this.fields.reporter_house_number = '';
            this.fields.reporter_zipcode = '';
            this.fields.reporter_city = '';
            this.mainReady = false;
        },
        searchAssets() {
            this.tempAssets = [];
            if (this.assetSearchTerm.length > 1) {
                let searchTerm = this.assetSearchTerm.toLowerCase();
                for (var i = 0; i < this.assets.length; i++) {
                    let asset = this.assets[i];
                    let assetName = asset.name.toLowerCase();
                    if (assetName.includes(searchTerm)) {
                        this.tempAssets.push(asset);
                    }
                    if (!this.tempAssets.length) {
                        this.assetsNoResults = true;
                    }
                    else {
                        this.assetsNoResults = false;
                    }
                }
            }
            else {
                this.assetsNoResults = false;
            }
        },
        selectCategory(category) {
            this.showAgreementChoice = null;
            this.categoryDropdownToggled = false;
            this.selectedCategory = category;
            this.tempCategories = [];
            this.categorySearchTerm = '';
            this.categoryError = false;
            for (var i = 0; i < this.agreementFlowCategories.length; i++) {
                let agreementFlowCategory = this.agreementFlowCategories[i];
                if (category.id_incident_category === agreementFlowCategory.id_incident_category) {
                    this.showAgreementChoice = true;
                }
            }
            if (this.selectedAssetId && this.fields.descr && (this.showAgreementChoice && this.agreementFlowChoice !== null || (this.showAgreementChoice === null && !this.agreementFlowChoice))) {
                this.mainReady = true;
            }
            if (this.agreementFlowSetting === 'ALWAYS_FLOW') {
                this.showAgreementChoice = true;
            }
        },
        searchCategories() {
            this.tempCategories = [];
            if (this.categorySearchTerm.length > 1) {
                let searchTerm = this.categorySearchTerm.toLowerCase();
                for (var i = 0; i < this.categories.length; i++) {
                    let category = this.categories[i];
                    let categoryName;
                    if (this.locale === 'en') {
                        categoryName = category.english_name.toLowerCase();
                    }
                    else {
                        categoryName = category.dutch_name.toLowerCase();
                    }
                    if (categoryName.includes(searchTerm)) {
                        this.tempCategories.push(category);
                    }
                    if (!this.tempCategories.length) {
                        this.categoriesNoResults = true;
                    }
                    else {
                        this.categoriesNoResults = false;
                    }
                }
            }
            else {
                this.categoriesNoResults = false;
            }
        },
        createNewIncident() {
            const data = new URLSearchParams();
            data.append('id_asset', this.selectedAssetId);
            data.append('description', this.fields.descr);
            if (this.intCode) {
                data.append('int_code', this.intCode);
            }
            if (!this.fields.phone && !this.fields.whatsapp) {
                this.invalidPhone = false;
            }
            if (this.fields.whatsapp && !this.fields.phone) {
                this.validPhone = false;
            } 
            if (this.showAgreementChoice && this.agreementFlowChoice === false) {
                data.append('ignore_agreement_flow', true);
            }
            else {
                data.append('ignore_agreement_flow', false);
            }
            data.append('id_incident_category', this.selectedCategory.id_incident_category)
            data.append('reporter_name', this.fields.name);
            data.append('emailaddress', this.fields.email);
            data.append('is_whatsapp', this.fields.whatsapp);
            data.append('reporter_locale', this.fields.reporter_locale);
            data.append('phone', this.formattedPhone);
            data.append('reporter_is_owner', this.vveOwner);
            data.append('reporter_address', this.fields.reporter_address);
            data.append('reporter_house_number', this.fields.reporter_house_number);
            data.append('reporter_zipcode', this.fields.reporter_zipcode);
            data.append('reporter_city', this.fields.reporter_city);
            let self = this;
            if (!this.selectedPlace.id_place) {
                this.placeError = true;
            }
            if (!this.fields.name) {
                this.nameError = true;
            }
            if (!this.fields.descr) {
                this.descriptionError = true;
            }
            if (!this.selectedCategory.id_incident_category) {
                this.categoryError = true;
            }
            if (!this.selectedAssetId) {
                this.assetError = true;
            }
            if (this.fields.name && this.fields.descr && !this.invalidEmail & !this.invalidPhone && this.selectedPlace.id_place && this.selectedCategory.id_incident_category && this.selectedAssetId) {
                this.incidentCreate = true;
                fetch('/api/v1/incident/createnewfromdashboard', {
                    method: 'post',
                    body: data
                }).then((resp) => {
                        if (!resp.ok) {
                            self.backendError = true;
                        }
                        return resp.json();
                    }).then(function(data) {
                        self.backendError = false;
                    if (data.wa_number === 'NO_MORE') {
                        self.channelsError = true;
                        self.incidentCreate = false;
                    }
                    else {
                        self.$gtag.event('click', {
                            event_category: 'Button',
                            event_label: 'Handmatig melding maken',
                        });
                        if (self.locale === 'nl') {
                            self.locale = 'nl-NL';
                        }
                        if (self.locale === 'en') {
                            self.locale = 'en-EN';
                        }
                        self.createdId = data.id_incident;
                        self.$vToastify.success(self.profile.toast.changes_saved, self.profile.dashboard.building_manager.message_table.incident_ID + ' ' + self.profile.toast.is_added);
                        if (data.agreement_flow) {
                            self.showAgreementMessage = true;
                            if (self.agreementFlowChoice && self.fields.file.length) {
                                self.uploadMedia();
                            }
                        }                     
                        else {
                            self.$emit('close');
                            if (!self.twinq) {
                                setTimeout(function () {
                                    self.$router.push('/buildingmanager/incident-detail?id=' + self.createdId);
                                }.bind(this), 1000)
                            }
                            else {
                                setTimeout(function () {
                                    self.$router.push('/buildingmanager');
                                }.bind(this), 1000)
                            }
                        }
                    }
                });
            }
        },
        validateEmail() {
            if (this.fields.email) {
                let pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
                if (pattern.test(this.fields.email)) {
                    this.validEmail = true;
                    this.invalidEmail = false;
                }
                else {
                    this.invalidEmail = true;
                    this.validEmail = false;
                }
            }
            else {
                this.invalidEmail = false;
                this.validEmail = false;
            }
        },
        validatePhone: function (number, object) {
            this.intCode = object.countryCallingCode
            if (object.formatted) {
                if (object.valid) {
                    this.fields.whatsapp = true;
                    this.validPhone = true;
                    this.invalidPhone = false;
                    this.formattedPhone = object.number;
                }
                else {
                    this.fields.whatsapp = false;
                    this.validPhone = false;
                    this.invalidPhone = true;
                }
            }
            else {
                this.formattedPhone = '';
                this.fields.whatsapp = false;
                this.validPhone = false;
                this.invalidPhone = false;
            }
        },
        zipCodeValidation: debounce(function (e) {
            let self = this;
            let zipcodeRegex = new RegExp('^[1-9][0-9]{3} ?(?!sa|sd|ss|SA|SD|SS)[A-Za-z]{2}$');
            this.validReporterZipCode = zipcodeRegex.test(this.fields.reporter_zipcode);
            if (this.validReporterZipCode && this.fields.reporter_house_number) {
                setTimeout(function () {
                    fetch('/api/v1/zipcode/getaddress?zipcode=' + this.fields.reporter_zipcode + '&housenumber=' + this.fields.reporter_house_number)
                    .then(response => { response.json().then(
                        function(data) {
                            if (data.message) {
                                self.addressError = true;
                            }
                            else {
                                self.addressError = false;
                                self.fields.reporter_address = data.street;
                                self.fields.reporter_city = data.city;
                                self.zipCodeSearch();
                            }  
                        });
                    });
                }.bind(this), 500);
            }   
        }, 500),
        getIncidents() {
            this.$emit('getIncidents');
        },
        checkName() {
            if (this.fields.name) {
                this.nameError = false;
            }
            else {
                this.nameError = true;
            }
        },
        checkDescr() {
            if (this.fields.descr) {
                this.descriptionError = false;
                if (this.selectedAssetId && this.selectedCategory.id_incident_category && ((this.showAgreementChoice && this.agreementFlowChoice !== null) || (this.showAgreementChoice === null && !this.agreementFlowChoice))) {
                    this.mainReady = true;
                }
            }
            else {
                this.descriptionError = true;
            }
        },
        setAgreementChoice(choice) {
            this.agreementFlowChoice = choice;
            if (this.fields.descr && this.selectedAssetId && this.selectedCategory.id_incident_category && (this.showAgreementChoice && this.agreementFlowChoice !== null || (this.showAgreementChoice === null && !this.agreementFlowChoice))) {
                this.mainReady = true;
            }
        },
        preFillAll() {
            this.fields.name = 'Thomas de Vries';
            this.fields.email = 'thomasdevries87@hotmail.com';
            this.selectedPlace.id_place = 3460;
            this.getAssets();
            this.placeSearchTerm = 'Centraal terrein'
            this.selectedCategory = this.categories[1];
            this.fields.descr = 'Bla bla bla bla bla';
            this.fields.phone = '+31627090259';
            this.validPhone = true;
            this.validEmail = true;
            setTimeout(function () {
                this.selectedAssetId = this.assets[0].id_asset;
                this.selectedAssetName = this.assets[0].name
            }.bind(this), 1000);
        },
        zipCodeSearch() {
            this.placeSearchTerm = '';
            let self = this;
            let zipcodeRegex = new RegExp('^[1-9][0-9]{3} ?(?!sa|sd|ss|SA|SD|SS)[A-Za-z]{2}$');
            this.validZipCode = zipcodeRegex.test(this.fields.zipcode);
            if (this.validZipCode && this.fields.house_number) {
                this.fields.zipcode = this.fields.zipcode.replace(' ', '');
                setTimeout(function () {
                    fetch('/api/v1/place/byzipcodehousenumber?zipcode=' + this.fields.zipcode + '&housenumber=' + this.fields.house_number + '&has_active_assets=true&has_assets_with_qr_code=true')
                    .then(response => { response.json().then(
                        function(data) {
                            self.places = [];
                            self.generalPlaces = [];
                            for (var i = 0; i < data.length; i++) {
                                let place = data[i];
                                if (place.floor_name === '0') {
                                    if (self.locale === 'nl') {
                                        place.floor_name = 'BG';
                                    }
                                    else {
                                        place.floor_name = 'GF';
                                    }
                                }
                                if (place.floor_name === 'VVEVEST') {
                                    self.generalPlaces.push(place);
                                }
                                else {
                                    self.places.push(place);
                                }
                            }
                            if (!self.places.length && !self.generalPlaces.length) {
                                self.placesNoResults = true;
                            }
                            else {
                                self.placesNoResults = false;
                            }
                        });
                    });
                }.bind(this), 500);
            }
            else {
                self.placesNoResults = false;
            }
        },
        fileChange(e) {
            this.fields.file = e.target.files || e.dataTransfer.files;
        },
        uploadMedia() {
            this.loading = true;
            this.uploadError = false;
            this.numberOfFiles = this.fields.file.length;
            let self = this;
            for (var i = 0; i < this.fields.file.length; i++) {
                let file = this.fields.file[i];
                this.numberOfFiles = i;
                if (file.type === 'application/pdf') {
                    const data = new FormData();
                    let filename = file.name.split('.pdf')[0];
                    data.append('file', file);
                    data.append('filename', filename);
                    data.append('id_incident', this.createdId);
                    data.append('open_api', true);
                    fetch('/api/v1/fileupload/uploadincidentpdf', {
                        method: 'post',
                        body: data
                    }).then((resp) => {return resp.json();
                    }).then(function(data) {
                        if (!data.url) {
                            self.uploadError = true;
                        }
                        self.numberOfFiles--
                        if (!self.videoPresent && self.numberOfFiles === -1) {
                            self.loading = false;
                            self.$vToastify.success(self.profile.places.upload_result_successes, self.profile.toast.changes_saved);
                        }
                    });
                }
                if (file.type === 'video/mp4' || file.type === 'video/3gp' || file.type === 'video/ts' || file.type === 'video/mkv' || file.type === 'video/mov' || file.type === 'video/quicktime' || file.type === 'video/avi') {
                    this.videoPresent = true;
                    const data = new FormData();
                    data.append('file', file);
                    data.append('filename', file.name);
                    data.append('id_incident', this.createdId);
                    data.append('open_api', true);
                    fetch('/api/v1/fileupload/uploadincidentvideo', {
                        method: 'post',
                        body: data
                    }).then((resp) => {
                        if (!resp.ok) {
                            self.uploadError = true;
                        }
                        return resp.json();
                    }).then(function(data) {
                        self.loading = false;
                        self.uploadError = false;
                        if (!self.uploadError && !data.NOK) {
                            self.$vToastify.success(self.profile.places.upload_result_successes, self.profile.toast.changes_saved);
                        }
                        if (data.NOK) {
                            self.uploadError = true;
                        }
                    });
                }
                if (file.type === 'image/bmp' ||
                    file.type === 'image/eps' ||
                    file.type === 'image/gif' ||
                    file.type === 'image/ico' ||
                    file.type === 'image/jpeg' ||
                    file.type === 'image/jpg' ||
                    file.type === 'image/pcx' ||
                    file.type === 'image/png' ||
                    file.type === 'image/raf' ||
                    file.type === 'image/tiff' ||
                    file.type === 'image/webp') {
                    const data = new FormData();
                    data.append('file', file);
                    data.append('id_incident', this.createdId);
                    data.append('open_api', true);
                    fetch('/api/v1/fileupload/uploadincidentimage', {
                        method: 'post',
                        body: data
                    }).then((resp) => {return resp.json();
                    }).then(function(data) {
                        if (!data.url) {
                            self.uploadError = true;
                        }
                        self.numberOfFiles--;
                        if (!self.videoPresent && self.numberOfFiles === -1) {
                            self.loading = false;
                            self.$vToastify.success(self.profile.places.upload_result_successes, self.profile.toast.changes_saved);
                        }
                    });
                }
            }
        }
    }
}
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>
