<template>
    <div>
        <div class="main-content" v-if="profile.assets">
            <div class="container">
                <div class="tabs">
                    <div class="tab" data-testid="assetTab"  @click="switchTab('assets')" :class="{active: currentTab === 'assets'}" v-if="!twinq">
                        <h3>{{profile.nav.assets}}</h3>
                    </div>
                    <div class="tab" data-testid="typeTab" @click="switchTab('asset types')" :class="{active: currentTab === 'asset types'}" v-if="twinq || (!twinq && admin)">
                        <h3>{{profile.assets.asset_types}} <span class="counter" v-if="assetsWithoutType" v-tooltip.right="profile.nav.assets_without_type_tooltip"> {{assetsWithoutType}}</span></h3>
                    </div>
                    <div class="tab right" @click="switchTab('categorysets')" :class="{active: currentTab === 'categorysets'}">
                        <h3>{{profile.assets.category_sets}}</h3>
                    </div>
                    <div class="tab right" @click="switchTab('asset types definition')" :class="{active: currentTab === 'asset types definition'}">
                        <h3>{{profile.assets.types_definition}}</h3>
                    </div>
                    <div class="tab right" @click="switchTab('categories')" :class="{active: currentTab === 'categories'}">
                        <h3>{{profile.mechanic_detail.mechanic_incident_categoryTable.name}}</h3>
                    </div>
                    <div class="tab right" v-if="secondaryAssets.length" @click="switchTab('externalAssets')" :class="{active: currentTab === 'externalAssets'}">
                        <h3>{{profile.assets.externalObjects}} <i class="fas fa-info-circle" v-tooltip.right="profile.incident_detail.pass_external_assets" style="cursor: pointer; font-size: 16px;"></i></h3>
                    </div>
                </div>
                <div class="tab-view" :class="{active: currentTab === 'assets'}">
                    <div class="row">
                        <div class="col" style="position: relative;">
                            <h1>{{profile.nav.assets}}</h1>
                            <span class="edit-button icon" @click="$router.push('/buildingmanager/new-object')" v-if="admin && !disableEdit"><i class="fa-solid fa-plus"></i></span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-8">
                            <section class="search search-alt">
                                <form @submit.prevent="search()">
                                    <input type="text" ref="search" v-bind:placeholder="profile.assets.search_asset" @input="e => searchTerm = e.target.value" :value="searchTerm" v-on:keyup="search($event)" />
                                    <span class="close-suggestions" @click="clearSearch()" v-if="searchTerm.length"><i class="fas fa-times"></i></span>
                                </form>
                            </section>
                        </div>
                        <div class="col-sm-4">
                            <div class="pagination pagination--alt" v-if="assetsTotalPages > 1">
                                <span class="prev" v-if="assetsCurrentPage !== 1" @click="prevPage()"><i class="fas fa-chevron-left"></i></span>
                                <span class="page" v-if="assetsCurrentPage !== 1" @click="firstPage()">1</span>
                                <span class="page-indicator">{{assetsCurrentPage}} / {{assetsTotalPages}}</span>
                                <span class="page page--last" v-if="assetsCurrentPage > 2 && assetsCurrentPage !== assetsTotalPages" @click="lastPage('currentincident')">{{assetsTotalPages}}</span>
                                <span class="next" @click="nextPage()" v-if="assetsCurrentPage !== assetsTotalPages"><i class="fas fa-chevron-right"></i></span>
                            </div>
                        </div>
                    </div>
                    <div class="table-wrap">
                        <div v-if="!loading">
                            <h4 class="result-counter" data-testid="assetSearchResult" v-if="!searchActive && totalAssets">{{totalAssets}} {{profile.assets.assets}}</h4>
                            <h4 class="result-counter" data-testid="assetSearchResult" v-if="searchActive && totalAssets === 0">{{profile.dashboard.building_manager.no_results}} '{{searchTerm}}'.</h4>
                            <h4 class="result-counter" data-testid="assetSearchResult" v-if="searchActive && totalAssets >= 1">{{totalAssets}} {{profile.dashboard.building_manager.results}} '{{searchTerm}}'</h4>
                        </div>
                        <table class="table table-striped" v-if="assets.length && !loading">
                            <thead>
                                <tr>
                                    <th scope="col" @click="sortTable('id_asset')" :class="{active: assetsSortType === 'id_asset'}">ID<i class="fas fa-sort"></i></th>
                                    <th scope="col" @click="sortTable('asset_name')" :class="{active: assetsSortType === 'asset_name'}">{{profile.assets.assettable.id_place}}<i class="fas fa-sort"></i></th>
                                    <th scope="col" @click="sortTable('place_name')" :class="{active: assetsSortType === 'place_name'}">{{profile.assets.assettable.location_name}}<i class="fas fa-sort"></i></th>
                                    <th scope="col" @click="sortTable('address')" :class="{active: assetsSortType === 'address'}">{{profile.employees.address}}<i class="fas fa-sort"></i></th>
                                    <th scope="col" @click="sortTable('qr_code')" :class="{active: assetsSortType === 'qr_code'}">{{profile.assets.assettable.hasqr}}<i class="fas fa-sort"></i></th>
                                    <th scope="col" @click="sortTable('hidden')" :class="{active: assetsSortType === 'hidden'}">{{profile.assets.assettable.active}}<i class="fas fa-sort"></i></th>
                                    <th scope="col">{{profile.assets.history}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in assets" :key="item.id_asset">
                                    <td data-testid="tableIdText" @click="selectAssetToEdit(item.id_asset)">{{item.id_asset}}</td>
                                    <td data-testid="tableNameText" @click="selectAssetToEdit(item.id_asset)">{{item.name}}</td>
                                    <td data-testid="tableLocationText" @click="selectAssetToEdit(item.id_asset)">{{item.place_name}}</td>
                                    <td data-testid="tableAddressText" @click="selectAssetToEdit(item.id_asset)">{{item.place_address}} {{item.place_house_number}}, {{item.place_zipcode}} {{item.place_city}}</td>
                                    <td data-testid="tableStickerText" @click="selectAssetToEdit(item.id_asset)"><i class="fas fa-check" v-if="item.qr_code"></i></td>
                                    <td data-testid="tableActiveText" @click="selectAssetToEdit(item.id_asset)"><i class="fas fa-check" v-if="!item.hidden"></i></td>
                                    <td data-testid="tableHistoryText" @click="selectAssetHistory(item.id_asset)"><i class="fas fa-history"></i></td>
                                </tr>
                            </tbody>
                        </table>
                        <img src="@/assets/icons/loading.gif" v-if="loading" class="loader" />
                    </div>
                </div>
                <div class="tab-view" :class="{active: currentTab === 'categories'}">
                    <Categories :admin="admin" />
                </div>
                <div class="tab-view" :class="{active: currentTab === 'asset types definition'}">
                    <AssetTypesDefinition :assetTypes="assetTypes" :admin="admin" @getAssetTypes="getAssetTypes()" />
                </div>
                <div class="tab-view" :class="{active: currentTab === 'categorysets'}">
                    <CategorySets :assetTypes="assetTypes" :admin="admin" />
                </div>
                <div class="tab-view" :class="{active: currentTab === 'asset types'}">
                    <div class="row">
                        <div class="col" style="position: relative;">
                            <h1>{{profile.assets.asset_types}}</h1>
                        </div>
                    </div>
                    <AssignAssetTypes :admin="admin" :assetTypes="assetTypes" :assetsWithoutType="assetsWithoutType" />
                </div>
                <div class="tab-view" :class="{active: currentTab === 'externalAssets'}">
                    <div class="row">
                        <div class="col" style="position: relative;">
                            <h1>{{profile.assets.externalObjects}}</h1>
                        </div>
                    </div>
                    <div class="table-wrap">
                        <div v-if="!loading">
                            <h4 class="result-counter">{{secondaryAssets.length}} {{profile.assets.assets}}</h4>
                        </div>
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col" @click="sortSecondaryTable('id_asset')" :class="{active: secondaryAssetsSortType === 'id_asset'}">ID<i class="fas fa-sort"></i></th>
                                    <th scope="col" @click="sortSecondaryTable('asset_name')" :class="{active: secondaryAssetsSortType === 'asset_name'}">{{profile.assets.assettable.id_place}}<i class="fas fa-sort"></i></th>
                                    <th scope="col" @click="sortSecondaryTable('place_name')" :class="{active: secondaryAssetsSortType === 'place_name'}">{{profile.assets.assettable.location_name}}<i class="fas fa-sort"></i></th>
                                    <th scope="col" @click="sortSecondaryTable('address')" :class="{active: secondaryAssetsSortType === 'address'}">{{profile.employees.address}}<i class="fas fa-sort"></i></th>
                                    <th scope="col" @click="sortSecondaryTable('qr_code')" :class="{active: secondaryAssetsSortType === 'qr_code'}">{{profile.assets.assettable.hasqr}}<i class="fas fa-sort"></i></th>
                                    <th scope="col" @click="sortSecondaryTable('hidden')" :class="{active: secondaryAssetsSortType === 'hidden'}">{{profile.assets.assettable.active}}<i class="fas fa-sort"></i></th>
                                    <th scope="col">{{profile.assets.history}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in secondaryAssets" :key="item.id_asset">
                                    <td data-testid="assetIdText" @click="selectAssetToEdit(item.id_asset)">{{item.id_asset}}</td>
                                    <td data-testid="assetNameText" @click="selectAssetToEdit(item.id_asset)">{{item.name}}</td>
                                    <td data-testid="assetLocationText" @click="selectAssetToEdit(item.id_asset)">{{item.place_name}}</td>
                                    <td data-testid="assetAddressText" @click="selectAssetToEdit(item.id_asset)">{{item.place_address}} {{item.place_house_number}}, {{item.place_zipcode}} {{item.place_city}}</td>
                                    <td data-testid="assetStickerText" @click="selectAssetToEdit(item.id_asset)"><i class="fas fa-check" v-if="item.qr_code"></i></td>
                                    <td data-testid="assetActiveText" @click="selectAssetToEdit(item.id_asset)"><i class="fas fa-check" v-if="!item.hidden"></i></td>
                                    <td data-testid="assetHistoryText" @click="selectAssetHistory(item.id_asset)"><i class="fas fa-history"></i></td>
                                </tr>
                            </tbody>
                        </table>
                        <img src="@/assets/icons/loading.gif" v-if="loading" class="loader" />
                    </div>
                </div>
            </div>
        </div>
        <AssetHistoryModal v-if="assetHistoryModalOpen" @close="assetHistoryModalOpen = false" :assetId="selectedAssetId" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { setLocale } from '@/mixins/setLocale.js';
import sortBy from 'lodash/sortBy';
import AssetHistoryModal from "@/components/modals/AssetHistoryModal.vue";
import AssignAssetTypes from "@/components/asset/AssignAssetTypes.vue";
import AssetTypesDefinition from "@/components/asset/AssetTypesDefinition.vue";
import Categories from "@/components/asset/Categories.vue";
import CategorySets from "@/components/asset/CategorySets.vue";
import debounce from 'lodash/debounce';

export default {
    mixins: [setLocale],
    components: {
        AssetHistoryModal,
        Categories,
        AssignAssetTypes,
        AssetTypesDefinition,
        CategorySets
    },
    data() {
        return {
            profile: {},
            assets: [],
            secondaryAssets: [],
            places: [],
            totalAssets: 0,
            assetTypes: [],
            assetsSortType: '',
            secondaryAssetsSortType: '',
            assetsCurrentPage: 1,
            assetsTotalPages: 1,
            sortOrder: 'asc',
            secondarySortOrder: 'asc',
            searchTerm: '',
            searchActive: false,
            assetHistoryModalOpen: false,
            selectedAssetId: 0,
            currentTab: 'assets',
            admin: false,
            loading: false,
            assetsWithoutType: null,
            disableEdit: false
        }
    },
    computed: mapState(['updateTypes', 'twinq', 'assetsWithoutTypeCount']),
    created() {
        document.querySelector('body').classList.remove('modal-open');
        if (this.profile.nav) {
            document.title = 'S1MONE | ' + this.profile.nav.assets;
        }
        if (this.assetsWithoutTypeCount) {
            this.assetsWithoutType = Number(this.assetsWithoutTypeCount);
        }
        if (localStorage.getItem('S1-assetsPage')) {
            this.assetsCurrentPage = localStorage.getItem('S1-assetsPage');
        }
        if (localStorage.getItem('S1-assetsTab')) {
            this.currentTab = localStorage.getItem('S1-assetsTab');
        }
        if (localStorage.getItem('S1-assetsSortOrder')) {
            this.sortOrder = localStorage.getItem('S1-assetsSortOrder');
        }
        if (localStorage.getItem('S1-assetsSorter')) {
            this.assetsSortType = localStorage.getItem('S1-assetsSorter');
        }
        if (localStorage.getItem('S1-assetsSearchTerm')) {
            this.searchTerm = localStorage.getItem('S1-assetsSearchTerm');
            this.assetsCurrentPage = 1;
            this.sortOrder = 'asc';
            this.assetsSortType = '';
            this.searchActive = true;
        }
        this.getAssets();
        this.getAccountInfo();
        this.getAssetTypes();
    },
    mounted() {
        this.$refs.search.focus();
    },
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        getAssetTypes() {
            let self = this;
            fetch('/api/v1/asset_type')
            .then(response => { response.json().then(
                function(data) {
                    self.assetTypes = data.data;
                    self.assetTypes = sortBy(self.assetTypes, 'display_name');
                });
            });
        },
        getAccountInfo() {
            let self = this;
            fetch('/api/v1/userandbuildingmanager')
            .then(resp => {
                if (!resp.ok) {
                    console.log('Backend error occurred, redirecting to signin page.');
                    window.location = '/signin.html';
                    return; 
                }
                return resp.json();
            })
            .then(data => {
                self.admin = data.building_manager_admin;
                self.disableEdit = data.disable_edit_basedata;
            });
        },
        sortTable(sorter) {
            this.sortOrder = this.sortOrder !== 'asc' ? 'asc' : 'desc';
            localStorage.setItem('S1-assetsSortOrder', this.sortOrder);
            this.assetsSortType = sorter;
            localStorage.setItem('S1-assetsSorter', this.assetsSortType);
            this.getAssets();
        },
        sortSecondaryTable(sorter) {
            this.secondarySortOrder = this.secondarySortOrder !== 'asc' ? 'asc' : 'desc';
            this.secondaryAssetsSortType = sorter;
            this.getSecondaryAssets();
        },
        nextPage() {
            this.assetsCurrentPage++;
            this.getAssets();
            localStorage.setItem('S1-assetsPage', this.assetsCurrentPage);
        },
        prevPage() {
            this.assetsCurrentPage--;
            this.getAssets();
            localStorage.setItem('S1-assetsPage', this.assetsCurrentPage);
        },
        firstPage() {
            this.assetsCurrentPage = 1;
            this.getAssets();
            localStorage.setItem('S1-assetsPage', this.assetsCurrentPage);
        },
        lastPage() {
            this.assetsCurrentPage = this.assetsTotalPages;
            this.getAssets();
            localStorage.setItem('S1-assetsPage', this.assetsCurrentPage);
        },
        getAssets: debounce(function (e) {
            this.loading = true;
            let self = this;
            let from;
            if (this.assetsCurrentPage === 1) {
                from = 0;
            }
            else {
                from = (this.assetsCurrentPage - 1) * 25;
            }
            let fromParam = '&offset=' + from;
            let orderParam = '&sort_dir=' + this.sortOrder;
            let sortParam = '';
            if (this.assetsSortType) {
                sortParam = '&sort_col=' + this.assetsSortType;
            }
            let searchParam = '';
            if (this.searchTerm) {
                searchParam = '&search_name=' + this.searchTerm;
            }
            fetch('/api/v1/asset/filtered?limit=25' + fromParam + orderParam + sortParam + searchParam)
            .then(response => { response.json().then(
                function(data) {
                    self.assets = [];
                    self.totalAssets = data.recordsTotal;
                    self.assetsTotalPages = data.recordsTotal / 25;
                    self.assetsTotalPages = Math.ceil(self.assetsTotalPages);
                    for (var j = 0; j < data.data.length; j++) {
                        let asset = data.data[j];
                        self.assets.push(asset);
                    }
                    self.loading = false;
                    if (data.hasOwnerAssets) {
                        self.getSecondaryAssets();
                    }
                });
            });
        }, 500),
        getSecondaryAssets() {
            let self = this;
            let sortParam = '';
            if (this.secondaryAssetsSortType) {
                sortParam = '&sort_col=' + this.secondaryAssetsSortType;
            }
            let orderParam = '&sort_dir=' + this.secondarySortOrder;
            fetch('/api/v1/asset/filtered?limit=100&owner_as_bm=true' + sortParam + orderParam)
            .then(response => { response.json().then(
                function(data) {
                    self.secondaryAssets = data.data;
                });
            });
        },
        selectAssetToEdit(id_asset) {
            this.selectedAssetId = id_asset;
            this.$router.push('/buildingmanager/asset-detail?asset=' + id_asset);
        },
        selectAssetHistory(id_asset)  {
            this.selectedAssetId = id_asset;
            this.assetHistoryModalOpen = true;
        },
        search($event) {
            this.assetsCurrentPage = 1;
            let searchTerm = this.searchTerm.toLowerCase();
            if (searchTerm.length > 2) {
                this.searchActive = true;
                this.getAssets();
            }
            if (searchTerm.length < 2 && this.searchActive)  {
                this.searchActive = false;
                this.getAssets();
            }
            if($event && $event.keyCode === 27) {
                this.searchActive = false;
                this.searchTerm = '';
                this.getAssets();
            }
            localStorage.setItem('S1-assetsSearchTerm', this.searchTerm);
        },
        clearSearch() {
            this.searchActive = false;
            this.searchTerm = '';
            this.getAssets();
            localStorage.removeItem('S1-assetsSearchTerm');
        },
        reloadAssets() {
            this.getAssets();
        },
        switchTab(type) {
            this.currentTab = type;
            if (type === 'asset types') {
                this.$store.dispatch('updateLoadAssets', true);
            }
            if (type === 'categorysets') {
                this.$store.dispatch('updateResetSets', true);
            }
            localStorage.setItem('S1-assetsTab', type);
        }
    }
}
</script>
