<template>
    <div class="modal-wrapper">
        <div class="modal">
            <div class="modal-header">
                <h3>Download stickers PDF </h3>
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <div class="row">
                    <div class="col-sm-3">
                        <span class="control-label" style="right:10px; position: absolute; margin-top: 14px">Vanaf object ID</span>
                    </div>
                    <div class="col-sm-9">
                        <input type="number" min="1" class="form-control" v-model="fields.from_id_asset" />
                    </div>
                    <div class="col-sm-1"></div>
                </div>
                <div class="row" style="margin-top: 20px;">
                    <div class="col-sm-3">
                        <span class="control-label" style="right:10px; position: absolute; margin-top: 14px">Tot en met object ID</span>
                    </div>
                    <div class="col-sm-9">
                        <input type="number" min="1" class="form-control" v-model="fields.to_id_asset" />
                    </div>
                    <div class="col-sm-1"></div>
                </div>
                <div class="row" style="margin-top: 20px;">
                    <div class="col-sm-3">
                        <span class="control-label" style="right:10px; position: absolute; margin-top: 14px">Grootte</span>
                    </div>
                    <div class="col-sm-9">
                        <div class="dropdown-section">
                            <span class="dropdown" v-on:click="optionsDropdownToggle = !optionsDropdownToggle" :class="{active: optionsDropdownToggle}">
                                <span v-if="fields.size" class="selected">{{fields.size}}</span>
                                <i class="fas fa-chevron-down chevron"></i>
                            </span>
                            <div class="dropdown-list" v-if="optionsDropdownToggle">
                                <ul>
                                    <li v-for="(option, index) in options" v-on:click="selectOption(index)" :key="index">
                                        {{option.label}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-1"></div>
                </div>
                <div class="row" v-if="assetIdError" style="margin-top: 20px;">
                    <div class="col">
                        <div class="notification notification--error">
                            <p><i class="fas fa-exclamation-circle"></i> Het eerste object ID moet lager zijn dan het tweede object ID</p>
                        </div>
                    </div>
                </div>
                <button class="button button--icon button--right" style="margin-top:20px" v-on:click="exportStickers()"><i class="fa-solid fa-qrcode"></i>Stickers downloaden</button>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import sortBy from 'lodash/sortBy';
export default {
    mixins: [setLocale],
    name: 'ExportStickerPdfModal',
    props: ['managerID'],
    data() {
        return {
            profile: {},
            managers: {},
            fields: {
                from_id_asset: '',
                to_id_asset: '',
                size: '',
                sizeValue: '',
            },
            options: [
                { label: 'Small', value: 'S' },
                { label: 'Medium', value: 'M' },
                { label: 'Large', value: 'L' },
                { label: 'S to cut', value: 'S-TO-CUT' },
                { label: 'Simplified whitelabel vertical', value: 'SIMPLIFIED-WHITE-LABEL-V' },
                { label: 'Simplified whitelabel horizontal', value: 'SIMPLIFIED-WHITE-LABEL-H' },
            ],
            optionsDropdownToggle: false,
            assetIdError: false,
        }
    },
    created() {
        document.querySelector('body').classList.add('modal-open');
        this.loadManagers();
        this.selectOption(0);
        document.onkeydown = (evt) => {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                this.closeModal();
            }
        };
    },
    methods: {
        loadManagers(){
            let self = this;
            fetch('/api/v1/buildingmanager')
            .then(response => { response.json().then(
                function(data) {
                    self.managers = data.data;
                });
            });
        },
        getManagerName(id){
            let manager = this.managers.find(manager => manager.id_building_manager === Number(id));
            return manager ? manager.name : '';
        },
        selectOption(index){
            this.optionsDropdownToggle = false;
            this.fields.size = this.options[index].label;
            this.fields.sizeValue = this.options[index].value;
        },
        exportStickers(){
            let self = this;
            if (this.fields.from_id_asset === '' || this.fields.to_id_asset === '' || this.fields.from_id_asset > this.fields.to_id_asset) {
                self.assetIdError = true;
                return;
            } else {
                var link = document.createElement('a');
                link.href = '/api/v1/stickercreation/recreate?id_building_manager=' + this.managerID
                         + '&from_id_asset=' + this.fields.from_id_asset 
                         + '&to_id_asset=' + this.fields.to_id_asset
                         + '&size=' + this.fields.sizeValue;
                let amount = this.fields.to_id_asset - this.fields.from_id_asset + 1;
                link.download = 'qrcodes-' + this.getManagerName(this.managerID) + '-' +  amount + '-' + this.fields.sizeValue + '.pdf';
                document.body.appendChild(link);
                link.dispatchEvent(new MouseEvent('click'));
                this.closeModal();
            }
        },
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        }
    }
}


</script>
