<template>
    <div>
        <div class="main-content" v-if="profile.accountPage">
            <div class="container">
                <div class="block block--account">
                    <div class="row">
                        <div class="col">
                            <div class="row" style="margin-top: 10px;">
                                <div class="col">
                                    <h2 data-testid="accountNameText" style="float: none;">{{accountInfo.name}}</h2>
                                    <h3 data-testid="accountEmailText" style="color: #9d9797; margin-top: 5px;">{{accountInfo.username}}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="block block--account" style="padding-bottom: 0;">
                    <div class="account-info">
                        <h4 style="margin-bottom: 5px;">{{profile.accountPage.account.change_pw}}</h4>
                        <div class="account-contents">
                            <div class="info-row" data-testid="generalPasswordEditButton" @click="passwordEdit = true">
                                <label><span v-if="!passwordEdit">{{profile.accountPage.account.password}}</span>
                                    <span class="edit-button edit-button--small" v-if="!passwordEdit"><i class="fas fa-pencil-alt"></i></span>
                                    <span class="edit-button edit-button--small" data-testid="generalPasswordSave" @click.stop="savePassword()" v-if="passwordEdit"><i class="far fa-save"></i></span>
                                </label>
                                <div v-if="passwordEdit">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <label>{{profile.accountPage.account.new_pw}}</label>
                                            <input data-testid="generalPasswordInput" type="password" class="form-control" v-model="newPassword" @input="passwordCheck()" />
                                            <ul class="password-requirements" v-if="!passwordCorrect">
                                                <li><i class="fas fa-times red" v-if="!passwordRequirements.characters"></i><i class="fas fa-check green" v-if="passwordRequirements.characters"></i> {{profile.accountPage.account.length}}</li>
                                                <li><i class="fas fa-times red" v-if="!passwordRequirements.uppercase"></i><i class="fas fa-check green" v-if="passwordRequirements.uppercase"></i> {{profile.accountPage.account.ucase}}</li>
                                                <li><i class="fas fa-times red" v-if="!passwordRequirements.lowercase"></i><i class="fas fa-check green" v-if="passwordRequirements.lowercase"></i> {{profile.accountPage.account.lcase}}</li>
                                                <li><i class="fas fa-times red" v-if="!passwordRequirements.number"></i><i class="fas fa-check green" v-if="passwordRequirements.number"></i> {{profile.accountPage.account.nr}}</li>
                                            </ul>
                                        </div>
                                        <div class="col-lg-6">
                                            <label>{{profile.accountPage.account.confirm_new_pw}}</label>
                                            <input data-testid="generalPasswordConfirmInput" type="password" class="form-control" v-model="newPasswordConfirm" @input="checkPasswords()" />
                                            <p class="password-requirements" v-if="!passwordsMatch"><b>{{profile.accountPage.account.match}}</b></p>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <p>•••••••••</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-supplier.js';

export default {
    mixins: [setLocale],
    data() {
        return {
            profile: {},
            accountInfo: {},
            passwordEdit: false,
            mobileEdit: false,
            newPassword: '',
            newPasswordConfirm: '',
            passwordRequirements: {
                characters: false,
                uppercase: false,
                lowercase: false,
                number: false
            },
            passwordCorrect: true,
            passwordsMatch: true,
            validMobilePhone: false,
            invalidMobilePhone: false,
        }
    },
    created() {
        document.title = 'S1MONE | Account';
        this.getAccountInfo();
    },
    methods: {
        getAccountInfo() {
            let self = this;
            fetch('/api/v1/getcurrentuser')
            .then(response => { response.json().then(
                function(data) {
                    self.accountInfo = data;
                });
            });
        },
        savePassword() {
            if (this.passwordCorrect && this.passwordsMatch && this.newPassword.length) {
                const data = new URLSearchParams();
                data.append('newpassword', this.newPassword);
                let self = this;
                fetch('/api/v1/changepassword', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.passwordEdit = false;
                });
            }
            if (!this.newPassword.length) {
                this.$vToastify.success(this.profile.toast.edited, this.profile.toast.changes_saved);
                this.passwordEdit = false;
            }
        },
        passwordCheck() {
            const ucase = new RegExp("[A-Z]+");
            const lcase = new RegExp("[a-z]+");
            const num = new RegExp("[0-9]+");
            if (this.newPassword.length > 9) {
                this.passwordRequirements.characters = true;
            }
            else {
                this.passwordRequirements.characters = false;
                this.passwordCorrect = false;
            }
            if (ucase.test(this.newPassword)) {
                this.passwordRequirements.uppercase = true;
            }
            else {
                this.passwordRequirements.uppercase = false;
                this.passwordCorrect = false;
            }
            if (lcase.test(this.newPassword)) {
                this.passwordRequirements.lowercase = true;
            }
            else {
                this.passwordRequirements.lowercase = false;
                this.passwordCorrect = false;
            }
            if (num.test(this.newPassword)) {
                this.passwordRequirements.number = true;
            }
            else {
                this.passwordRequirements.number = false;
                this.passwordCorrect = false;
            }
            if (!this.newPassword.length) {
                this.passwordCorrect = true;
            }
            this.checkValidations();
        },
        checkValidations() {
            if (this.passwordRequirements.characters &&
                this.passwordRequirements.uppercase &&
                this.passwordRequirements.lowercase &&
                this.passwordRequirements.number &&
                this.newPassword === this.newPasswordConfirm) {
                    this.passwordCorrect = true;
            }
            else {
                this.passwordCorrect = false;
            }
            if (this.newPassword === this.newPasswordConfirm) {
                this.passwordsMatch = true;
            }
        },
        checkPasswords() {
            if (this.newPassword !== this.newPasswordConfirm) {
                this.passwordsMatch = false;
                this.passwordCorrect = false;
            }
            else {
                this.passwordsMatch = true;
            }
            this.checkValidations();
        },
        saveMobile() {
            const params = new URLSearchParams();
            params.append('action', 'edit');
            params.append('data[0][mobile]', this.accountInfo.repair_company_mobile);
            let self = this;
            if (!this.invalidMobilePhone) {
                fetch('/api/v1/repaircompany', {
                    method: 'post',
                    body: params
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.mobileEdit = false;
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                });
            }
        },
        validateMobilePhone() {
            if (this.accountInfo.repair_company_mobile) {
                let pattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
                if (pattern.test(this.accountInfo.repair_company_mobile)) {
                    this.validMobilePhone = true;
                    this.invalidMobilePhone = false;
                }
                else {
                    this.invalidMobilePhone = true;
                    this.validMobilePhone = false;
                }
            }
            else {
                this.invalidMobilePhone = false;
                this.validMobilePhone = false;
            }
        },
    }
}
</script>
