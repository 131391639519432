<template>
    <div>
        <div class="main-content" v-if="profile.places">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <a class="back-link" @click="$router.go(-1)"><i class="fas fa-chevron-left"></i>{{profile.nav.back_link}}</a>
                    </div>
                </div>
                <div class="block">
                    <div class="profile-card location">
                        <a :href="mapsUrl" target="blank" class="detail-map">
                            <img :src="mapsApiUrl" id="address-map" />
                        </a>
                        <div class="content">
                            <h4>{{profile.dashboard.building_manager.message_table.place}}</h4>
                            <h2>{{placeInfo.name}}
                                <img src="@/assets/icons/flags/it.svg" v-if="placeInfo.country === 'IT'" style="width: 15px; margin-left: 5px;">
                                <img src="@/assets/icons/flags/de.svg" v-if="placeInfo.country === 'DE'" style="width: 15px; margin-left: 5px;">
                                <img src="@/assets/icons/flags/fr.svg" v-if="placeInfo.country === 'FR'" style="width: 15px; margin-left: 5px;">
                                <img src="@/assets/icons/flags/esp.svg" v-if="placeInfo.country === 'ES'" style="width: 15px; margin-left: 5px;">
                                <img src="@/assets/icons/flags/lu.svg" v-if="placeInfo.country === 'LU'" style="width: 15px; margin-left: 5px;">
                                <img src="@/assets/icons/flags/be.svg" v-if="placeInfo.country === 'BE'" style="width: 15px; margin-left: 5px;">
                            </h2>  
                        </div>              
                    </div>
                    <span class="edit-button icon" @click="removeLocationModalOpen = true" v-if="admin && !disableEdit"><i class="far fa-trash-alt"></i></span>
                </div>
                <div class="row">
                    <div class="col-md-7">
                        <div class="row" v-if="passedPlace">
                            <div class="col">
                                <span class="original_bm" v-tooltip.right="profile.incident_detail.pass_tooltip_location"><i class="fas fa-share"></i> {{placeInfo.building_manager_name}}</span>
                            </div>
                        </div>
                        <div class="block block--location_detail" :class="{tabbed: passedPlace}" style="padding-bottom: 0;">
                            <div v-if="!loading">
                                <section class="incident-head">
                                    <h1 data-testid="locationEditTitle">{{profile.accountPage.account.general}}</h1>
                                </section>
                                <section class="location-contents">
                                    <div class="notification notification--warning" v-if="existsError" style="margin-bottom: 20px;">
                                        {{profile.places.places_known_error}}
                                    </div>       
                                    <div class="location-info">
                                        <div class="info-row" data-testid="locationNameEditButton" @click="nameEdit = true">
                                            <label>{{profile.assets.assettable.location_name}}
                                                <span class="edit-button edit-button--small" v-if="!nameEdit && !twinq"><i class="fas fa-pencil-alt"></i></span>
                                                <span class="edit-button edit-button--small" data-testid="locationNameSave" @click="saveName()" v-if="nameEdit && (!twinq || twinq && placeInfo.floor_name === 'VVEVEST')"><i class="far fa-save"></i></span>
                                            </label>
                                            <div v-if="nameEdit && !twinq">
                                                <input type="text" data-testid="locationEditName" class="form-control" v-model="placeInfo.name" />
                                            </div>
                                            <div v-else>
                                                <p data-testid="locationNameText" style="white-space: normal;">{{placeInfo.name}}</p>
                                            </div>
                                        </div>
                                        <div class="info-row" data-testid="locationStreetEditButton" @click="addressEdit = true">
                                            <label>{{profile.places.placetable.address}}
                                                <span class="edit-button edit-button--small" v-if="!addressEdit && (!twinq || twinq && placeInfo.floor_name === 'VVEVEST')"><i class="fas fa-pencil-alt"></i></span>
                                                <span class="edit-button edit-button--small" data-testid="locationStreetSave" @click="saveAddress()" v-if="addressEdit && (!twinq || twinq && placeInfo.floor_name === 'VVEVEST')"><i class="far fa-save"></i></span>
                                            </label>
                                            <div v-if="addressEdit && (!twinq || twinq && placeInfo.floor_name === 'VVEVEST')">
                                                <input type="text" data-testid="locationEditAddress" class="form-control" v-model="placeInfo.address" />
                                            </div>
                                            <div v-else>
                                                <p data-testid="locationAddressText" style="white-space: normal;">{{placeInfo.address}}</p>
                                            </div>
                                        </div>
                                        <div class="info-row" data-testid="locationNumberEditButton" @click="houseNumberEdit = true">
                                            <label>{{profile.places.placetable.house_number}}
                                                <span class="edit-button edit-button--small" v-if="!houseNumberEdit && (!twinq || twinq && placeInfo.floor_name === 'VVEVEST')"><i class="fas fa-pencil-alt"></i></span>
                                                <span class="edit-button edit-button--small" data-testid="locationNumberSave" @click="saveHouseNumber()" v-if="houseNumberEdit && (!twinq || twinq && placeInfo.floor_name === 'VVEVEST')"><i class="far fa-save"></i></span>
                                            </label>
                                            <div v-if="houseNumberEdit && (!twinq || twinq && placeInfo.floor_name === 'VVEVEST')">
                                                <input type="text" data-testid="locationEditHouseNumber" class="form-control" v-model="placeInfo.house_number" />
                                            </div>
                                            <div v-else>
                                                <p data-testid="locationAddressNumber" style="white-space: normal;">{{placeInfo.house_number}}</p>
                                            </div>
                                        </div>
                                        <div class="info-row" data-testid="locationFloorEditButton" @click="floorEdit = true">
                                            <label>{{profile.incident_detail.floor_name}}
                                                <span class="edit-button edit-button--small" v-if="!floorEdit && !twinq"><i class="fas fa-pencil-alt"></i></span>
                                                <span class="edit-button edit-button--small" data-testid="locationFloorSave" @click="saveFloor()" v-if="floorEdit && !twinq && !twinq"><i class="far fa-save"></i></span>
                                            </label>
                                            <div v-if="floorEdit && !twinq">
                                                <input type="text" data-testid="locationEditFloor" class="form-control" v-model="placeInfo.floor_name" v-if="industry === 'leasing'" />
                                                <select class="form-select" data-testid="locationEditFloor" v-model="placeInfo.floor_name" v-if="industry !== 'leasing' && !twinq" required>
                                                    <option :value="null" disabled>{{profile.places.placetable.floor}} *</option>
                                                    <option v-for="(option, index) in floorOptions" :value="option.value" :disabled="option.disabled" :key="index">{{option.name_nl}}</option>
                                                </select>
                                                <p v-else>{{ placeInfo.floor_name }}</p>
                                            </div>
                                            <div v-else>
                                                <p data-testid="locationFloorText" v-if="placeInfo.floor_name === 'NVT'">{{profile.places.n_a}}</p>
                                                <p data-testid="locationFloorText" v-if="placeInfo.floor_name === '0'">{{profile.places.b_g}}</p>
                                                <p data-testid="locationFloorText" v-if="placeInfo.floor_name !== '0' && placeInfo.floor_name !== 'NVT'">{{placeInfo.floor_name}}</p>
                                            </div>
                                        </div>
                                        <div class="info-row" data-testid="locationZipEditButton" @click="zipcodeEdit = true">
                                            <label>{{profile.places.placetable.zipcode}}
                                                <span class="edit-button edit-button--small" v-if="!zipcodeEdit && (!twinq || twinq && placeInfo.floor_name === 'VVEVEST')"><i class="fas fa-pencil-alt"></i></span>
                                                <span class="edit-button edit-button--small" data-testid="locationZipSave" @click="saveZipcode()" v-if="zipcodeEdit && (!twinq || twinq && placeInfo.floor_name === 'VVEVEST')"><i class="far fa-save"></i></span>
                                            </label>
                                            <div v-if="zipcodeEdit && (!twinq || twinq && placeInfo.floor_name === 'VVEVEST')">
                                                <input type="text" data-testid="locationEditZipcode" class="form-control" v-model="placeInfo.zipcode" />
                                            </div>
                                            <div v-else>
                                                <p data-testid="locationZipText">{{placeInfo.zipcode}}</p>
                                            </div>
                                        </div>
                                        <div class="info-row" data-testid="locationCityEditButton" @click="cityEdit = true">
                                            <label>{{profile.places.placetable.city}}
                                                <span class="edit-button edit-button--small" v-if="!cityEdit && (!twinq || twinq && placeInfo.floor_name === 'VVEVEST')"><i class="fas fa-pencil-alt"></i></span>
                                                <span class="edit-button edit-button--small" data-testid="locationCitySave" @click="saveCity()" v-if="cityEdit && (!twinq || twinq && placeInfo.floor_name === 'VVEVEST')"><i class="far fa-save"></i></span>
                                            </label>
                                            <div v-if="cityEdit && (!twinq || twinq && placeInfo.floor_name === 'VVEVEST')">
                                                <input type="text" data-testid="locationEditCity" class="form-control" v-model="placeInfo.city" />
                                            </div>
                                            <div v-else>
                                                <p data-testid="locationCityText">{{placeInfo.city}}</p>
                                            </div>
                                        </div>
                                        <div class="info-row" data-testid="locationCountryEditButton" @click="countryEdit = true">
                                            <label>{{profile.places.placetable.country}}
                                                <span class="edit-button edit-button--small" v-if="!countryEdit && !twinq"><i class="fas fa-pencil-alt"></i></span>
                                                <span class="edit-button edit-button--small" data-testid="locationCountrySave" @click="saveCountry()" v-if="countryEdit && !twinq"><i class="far fa-save"></i></span>
                                            </label>
                                            <div v-if="countryEdit && !twinq">
                                                <input type="text" data-testid="locationEditCountry" class="form-control" v-model="placeInfo.country" />
                                            </div>
                                            <div v-else>
                                                <p data-testid="locationCountryText">{{placeInfo.country}}</p>
                                            </div>
                                        </div>
                                        <div class="info-row" data-testid="locationOwnerEditButton" @click="ownerEdit = true">
                                            <label v-if="vve">{{profile.assets.vve_short}}
                                                <span class="edit-button edit-button--small" v-if="!ownerEdit && !twinq"><i class="fas fa-pencil-alt"></i></span>
                                                <span class="edit-button edit-button--small" data-testid="locationOwnerSave" @click="saveOwner()" v-if="ownerEdit && !twinq"><i class="far fa-save"></i></span>
                                            </label>
                                            <label v-else>{{profile.toast.owner}}
                                                <span class="edit-button edit-button--small" v-if="!ownerEdit && !twinq"><i class="fas fa-pencil-alt"></i></span>
                                                <span class="edit-button edit-button--small" data-testid="locationOwnerSave" @click="saveOwner()" v-if="ownerEdit"><i class="far fa-save"></i></span>
                                            </label>
                                            <div v-if="ownerEdit && (!twinq || twinq && placeInfo.floor_name === 'VVEVEST')">
                                                <select class="form-select" data-testid="locationEditOwnerSelect" aria-label="Selecteer een eigenaar" v-model="selectedOwnerId">
                                                    <option value="" disabled>Selecteer een eigenaar</option>
                                                    <option v-for="(owner, index) in owners" :value="owner.id_owner" :key="index">{{owner.name}}</option>
                                                </select>                                            
                                            </div>
                                            <div v-else>
                                                <p style="white-space: normal;"><router-link tag="a" data-testid="locationOwnerText" style="color: #0d6efd;" v-bind:to="'/buildingmanager/owner-detail?id=' + placeInfo.id_owner">{{selectedOwnerName}}</router-link></p>
                                            </div>
                                        </div>
                                        <div class="info-row" data-testid="locationSupplierEditButton" @click="repaircompanyEdit = true">
                                            <label>{{profile.accountPage.account.primary_repaircompany}}
                                                <span class="edit-button edit-button--small" v-if="!repaircompanyEdit && !twinq"><i class="fas fa-pencil-alt"></i></span>
                                                <span class="edit-button edit-button--small" data-testid="locationSupplierSave" @click="savePreferredRep()" v-if="repaircompanyEdit && !twinq"><i class="far fa-save"></i></span>
                                            </label>
                                            <div v-if="repaircompanyEdit && !twinq">
                                                <select class="form-select" data-testid="locationEditRepairCompanySelect" aria-label="Selecteer een reparateur" v-model="placeInfo.primary_id_repair_company">
                                                    <option value="">{{profile.incident_detail.select_a_repaircompany}}</option>
                                                    <option v-for="(repaircompany, index) in repaircompanies" :value="repaircompany.id_repair_company" :key="index">{{repaircompany.name}}</option>
                                                </select>                                       
                                            </div>
                                            <div v-else>
                                                <p data-testid="locationSupplierText">{{selectedRepairCompanyName}}</p>
                                            </div>
                                        </div>
                                        <div class="info-row" data-testid="locationTypeEditButton" @click="locationTypeEdit = true">
                                            <label>{{profile.places.placetype}}
                                                <span class="edit-button edit-button--small" v-if="!locationTypeEdit && !twinq"><i class="fas fa-pencil-alt"></i></span>
                                                <span class="edit-button edit-button--small" data-testid="locationTypeSave" @click="saveLocationType()" v-if="locationTypeEdit && !twinq"><i class="far fa-save"></i></span>
                                            </label>
                                            <div v-if="locationTypeEdit && !twinq">
                                                <select class="form-select" data-testid="locationEditTypeSelect" aria-label="Selecteer een locatie type" v-model="selectedPlaceTypeId">
                                                    <option value="">Selecteer een locatie type</option>
                                                    <option v-for="(placeType, index) in placeTypes" :value="placeType.id_place_type" :key="index">{{placeType.name}}</option>
                                                </select>                                     
                                            </div>
                                            <div v-else>
                                                <p data-testid="locationTypeText">{{selectedPlaceTypeName}}</p>
                                            </div>
                                        </div>
                                        <div class="info-row" data-testid="locationExIdEditButton" @click="externalIdEdit = true">
                                            <label>{{profile.assets.assetmodal.external_id}}
                                                <span class="edit-button edit-button--small" v-if="!externalIdEdit"><i class="fas fa-pencil-alt"></i></span>
                                                <span class="edit-button edit-button--small" data-testid="locationExIdSave" @click="saveExternalId()" v-if="externalIdEdit"><i class="far fa-save"></i></span>
                                            </label>
                                            <div v-if="externalIdEdit">
                                                <input type="text" data-testid="locationEditIdExternal" class="form-control" v-model="placeInfo.id_external" />                       
                                            </div>
                                            <div v-else>
                                                <p data-testid="locationExtrenalText">{{placeInfo.id_external}}</p>
                                            </div>
                                        </div>
                                        <div class="info-row last" data-testid="locationDescriptionEditButton" @click="descriptionEdit = true">
                                            <label>{{profile.incidents.new_incident.description}}
                                                <span class="edit-button edit-button--small" v-if="!descriptionEdit"><i class="fas fa-pencil-alt"></i></span>
                                                <span class="edit-button edit-button--small" data-testid="locationDescriptionSave" @click="saveDescription()" v-if="descriptionEdit"><i class="far fa-save"></i></span>
                                            </label>
                                            <div v-if="descriptionEdit">
                                                <textarea class="form-control" data-testid="locationEditDescription" v-model="placeInfo.description" />                                 
                                            </div>
                                            <div v-else>
                                                <p data-testid="locationDescriptionText">{{placeInfo.description}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <img src="@/assets/icons/loading.gif" v-if="loading" class="loader" />
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="block block--assets-list">
                            <div v-if="!loading">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <h3 :class="{'no-margin': !allAssets.length}">{{profile.assets.tablename}}
                                            <div class="edit-button icon" style="top: 0; margin-top: -5px;" @click="newAssetModalOpen = true" v-if="!twinq"><i class="fa-solid fa-plus"></i></div>
                                        </h3>
                                        <section class="actions" style="margin: 40px 0 0 0;" v-if="!allAssets.length">
                                            <span class="button button--small" style="float: right;" @click="newAssetModalOpen = true"><i class="fa-solid fa-plus"></i>{{profile.places.create_first_asset}}</span>
                                        </section>
                                        <section class="actions" style="margin: 30px 0 10px 0;" v-if="allAssets.length">
                                            <div class="button-wrap">
                                                <span class="button button--small" data-testid="deactivateAllAssets" @click="assetsDeactivateConfirmModalOpen = true" v-if="deactivateAllButton && admin && !disableEdit" style="margin-right: 0;"><i class="fas fa-toggle-off"></i>{{profile.assets.deactivate_all}}</span>
                                                <span class="button button--small" data-testid="activateAllAssets" @click="assetsActivateConfirmModalOpen = true" v-if="activateAllButton && admin && !disableEdit" style="margin-right: 10px;"><i class="fas fa-toggle-on"></i>{{profile.assets.activate_all}}</span>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <section class="search search-inpage" v-if="allAssets.length > 5">
                                            <form>
                                                <input type="text" v-bind:placeholder="profile.assets.search_asset" @input="e => searchTerm = e.target.value" :value="searchTerm" v-on:keyup="searchSuggest($event)" />
                                                <span class="close-suggestions" @click="clearSuggestions()" v-if="searchSuggestions.length"><i class="fas fa-times"></i></span>
                                            </form>
                                        </section>
                                    </div>
                                </div>
                                <div v-for="(asset, index) in allAssets" :key="index" @click="selectAssetToEdit(asset.id_asset)" class="asset-collection" v-show="!searchSuggestions.length">
                                    <div class="asset-item" :class="{assettype:asset.asset_type_name}">
                                        <label class="asset-type" v-if="asset.asset_type_name" v-tooltip.right="'Object typenaam'"><i class="fas fa-cube"></i> {{asset.asset_type_name}}</label>
                                        <h4 data-testid="assetName">{{asset.name}}</h4>
                                        <span class="active-label" v-if="!asset.hidden && asset.qr_code">{{profile.assets.assettable.active}}</span>
                                        <span class="inactive-label" v-if="asset.hidden && asset.qr_code">{{profile.assets.inactive}}</span>
                                        <span class="indication-label" v-if="asset.qr_code">{{asset.id_asset}}</span>
                                        <div class="properties">
                                            <span class="property inactive" v-if="!asset.qr_code"><i class="fas fa-qrcode"></i>{{profile.assets.no_sticker}}</span>
                                            <span class="property" v-if="asset.qr_code"><i class="fas fa-qrcode"></i>{{profile.assets.sticker}}</span>
                                            <span class="property" v-if="asset.qr_code"><i class="fas fa-umbrella"></i> {{profile.assets.main_object}}</span>
                                        </div>
                                    </div>
                                    <div v-for="(subasset, index) in asset.subAssets" class="asset-item asset-item--sub" :key="index" @click="selectAssetToEdit(subasset.id_asset)">
                                        <h4>{{subasset.name}}<span v-if="subasset.qr_code"> | <i v-if="subasset.hidden">{{profile.assets.assettable.active}}</i><b v-if="!subasset.active">{{profile.assets.inactive}}</b></span><span class="indication-label" v-if="subasset.qr_code">{{asset.id_asset}}</span></h4>
                                        <div class="properties">
                                            <span class="property" v-if="subasset.qr_code"><i class="fas fa-qrcode"></i>{{profile.assets.sticker}}</span>
                                            <span class="property" v-if="asset.qr_code"><i class="fas fa-umbrella"></i> {{profile.assets.main_object}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div v-for="(asset, index) in searchSuggestions" :key="index" @click="selectAssetToEdit(asset.id_asset)" class="asset-collection" v-show="searchSuggestions.length">
                                    <div class="asset-item" :class="{assettype:asset.asset_type_name}">
                                        <label class="asset-type" v-if="asset.asset_type_name" v-tooltip.right="'Object typenaam'"><i class="fas fa-cube"></i> {{asset.asset_type_name}}</label>
                                        <h4>{{asset.name}}</h4>
                                        <span class="active-label" v-if="!asset.hidden && asset.qr_code">{{profile.assets.assettable.active}}</span>
                                        <span class="inactive-label" v-if="asset.hidden && asset.qr_code">{{profile.assets.inactive}}</span>
                                        <span class="indication-label" v-if="asset.qr_code">{{asset.id_asset}}</span>
                                        <div class="properties">
                                            <span class="property inactive" v-if="!asset.qr_code"><i class="fas fa-qrcode"></i>{{profile.assets.no_sticker}}</span>
                                            <span class="property" v-if="asset.qr_code"><i class="fas fa-qrcode"></i>{{profile.assets.sticker}}</span>
                                            <span class="property" v-if="asset.qr_code"><i class="fas fa-umbrella"></i> {{profile.assets.main_object}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <img src="@/assets/icons/loading.gif" v-if="loading" class="loader" />
                        </div>
                    </div>
                </div>
                <div class="block block--autodispatch" v-if="admin">
                    <h3>{{profile.accountPage.account.autodispatchlabel}}  <i class="fas fa-euro-sign" v-tooltip.right="profile.accountPage.account.autodispatch_feature" style="float: right;"></i></h3>
                    <p v-if="admin && canAutoDispatch">{{profile.accountPage.account.autodispatch_info}}</p>
                    <div class="row" v-if="admin && canAutoDispatch && !disableEdit">
                        <div class="col-sm-6">
                            <select class="form-select" data-testid="autoCategorySelect" v-model="selectedCategoryId">
                                <option data-testid="autoCategoryOption" :value="null">{{profile.accountPage.account.autodispatch_category}}</option>
                                <option data-testid="autoCategoryOption" v-for="(category, index) in categories" :value="category.id_incident_category" :key="index">{{category.dutch_name}}</option>
                            </select>
                        </div>
                        <div class="col-sm-6">
                            <select data-testid="autoSupplierSelect" class="form-select" v-model="selectedRepairCompany">
                                <option data-testid="autoSupplierOption" :value="null">{{profile.accountPage.account.autodispatch_repair_company}}</option>
                                <option data-testid="autoSupplierOption" v-for="(repaircompany, index) in repaircompanies" :value="repaircompany.id_repair_company" :key="index">{{repaircompany.name}}</option>
                            </select>
                        </div>
                    </div>
                    <span class="button button--icon" v-if="admin && canAutoDispatch && !disableEdit" data-testid="autoSubmitButton" @click="selectAutoDispatch()" style="margin: 15px 0 0 0; float: right; width: auto;"><i class="fas fa-folder-plus"></i> {{profile.buttons.add}}</span>
                    <br v-if="admin && canAutoDispatch" />
                    <h4 v-if="selectedAutoDispatchOptions.length">{{profile.accountPage.account.summary}}</h4>
                    <div class="autodispatch-item" v-for="(selectedAutoDispatchOption, index) in selectedAutoDispatchOptions" :key="index">
                        <div class="row" v-if="!selectedAutoDispatchOption.editMode">
                            <div class="col-sm-4">
                                <h3>{{profile.incident_detail.category}}</h3>
                                <p class="capitalize" data-testid="autoCategoryText">{{selectedAutoDispatchOption.category_name}}</p>
                            </div>
                            <div class="col-sm-5">
                                <h3>{{profile.incident_detail.repair_company_comment}}</h3>
                                <p data-testid="autoSupplierText">{{selectedAutoDispatchOption.repair_company_name}}</p>
                            </div>
                            <div class="col-sm-3">
                                <span class="edit-button" data-testid="autoDeleteButton" @click="deleteAutoDispatchOption(selectedAutoDispatchOption.id_place_incident_category_repair_company)" style="margin-right: 30px; border-right: 1px solid #0867b3; padding-right: 15px; margin-top: -5px;" v-if="canAutoDispatch"><i class="fas fa-trash-alt" style="font-size: 16px;"></i></span>
                                <span class="edit-button" data-testid="autoEditButton" @click="selectedAutoDispatchOption.editMode = true" style="margin-top: -5px;" v-if="canAutoDispatch"><i class="fas fa-pencil-alt" style="font-size: 16px;"></i></span>
                            </div>
                        </div>
                        <div class="row" v-if="selectedAutoDispatchOption.editMode">
                            <div class="col-sm-4">
                                <select class="form-select" data-testid="autoEditCategorySelect" v-model="selectedAutoDispatchOption.id_incident_category" @change="selectAutoDispatch()">
                                    <option data-testid="autoEditCategoryOption" value="">{{profile.incident_detail.select_category}}</option>
                                    <option data-testid="autoEditCategoryOption" v-for="(category, index) in categories" :value="category.id_incident_category" :key="index">{{category.dutch_name}}</option>
                                </select>
                            </div>
                            <div class="col-sm-5">
                                <select class="form-select" data-testid="autoEditSupplierSelect" v-model="selectedAutoDispatchOption.id_repair_company" @change="selectAutoDispatch()">
                                    <option data-testid="autoEditSupplierOption" value="">{{profile.incident_detail.select_a_repaircompany}}</option>
                                    <option data-testid="autoEditSupplierOption" v-for="(repaircompany, index) in repaircompanies" :value="repaircompany.id_repair_company" :key="index">{{repaircompany.name}}</option>
                                </select>
                            </div>
                            <div class="col-sm-3">
                                <span class="remove-option" data-testid="autoEditDeleteButton" @click="deleteAutoDispatchOption(selectedAutoDispatchOption.id_place_incident_category_repair_company)" v-if="canAutoDispatch && admin"><i class="fas fa-trash-alt"></i></span>
                                <span class="edit-option" data-testid="autoEditSaveButton" @click="saveAutoDispatchOption(selectedAutoDispatchOption)" v-if="canAutoDispatch"><i class="fas fa-save"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="block block--autodispatch" v-if="!admin && selectedAutoDispatchOptions.length">
                    <h3>{{profile.accountPage.account.autodispatchlabel}}</h3>
                    <br />
                    <h4 v-if="selectedAutoDispatchOptions.length">{{profile.accountPage.account.summary}}</h4>
                    <div class="autodispatch-item" v-for="(selectedAutoDispatchOption, index) in selectedAutoDispatchOptions" :key="index">
                        <div class="row" v-if="!selectedAutoDispatchOption.editMode">
                            <div class="col-sm-4">
                                <h3>{{profile.incident_detail.category}}</h3>
                                <p data-testid="autoCategoryName" class="capitalize">{{selectedAutoDispatchOption.category_name}}</p>
                            </div>
                            <div class="col-sm-5">
                                <h3>{{profile.incident_detail.repair_company_comment}}</h3>
                                <p data-testid="autoSupplierName">{{selectedAutoDispatchOption.repair_company_name}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <RemoveLocationModal v-if="removeLocationModalOpen" @close="removeLocationModalOpen = false" :locationId="placeId" :locationName="placeInfo.name" />
        <NewAssetModal v-if="newAssetModalOpen" @close="newAssetModalOpen = false" :placeInfo="placeInfo" @success="getPlaceInfo()" />
        <AssetsDeactivateConfirmModal v-if="assetsDeactivateConfirmModalOpen" @close="reloadAssets(placeId)" :locationId="placeId" />
        <AssetsActivateConfirmModal v-if="assetsActivateConfirmModalOpen" @close="reloadAssets(placeId)" :locationId="placeId" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import { mapState } from 'vuex';
import sortBy from 'lodash/sortBy';
import RemoveLocationModal from "@/components/modals/RemoveLocationModal.vue";
import AssetsDeactivateConfirmModal from "@/components/modals/AssetsDeactivateConfirmModal.vue";
import AssetsActivateConfirmModal from "@/components/modals/AssetsActivateConfirmModal.vue";
import NewAssetModal from "@/components/modals/NewAssetModal.vue";

export default {
    mixins: [setLocale],
    components: {
        RemoveLocationModal,
        AssetsDeactivateConfirmModal,
        AssetsActivateConfirmModal,
        NewAssetModal
    },
    data() {
        return {
            profile: {},
            placeInfo: [],
            owners: [],
            repaircompanies: [],
            placeTypes: [],
            allAssets: [],
            autoDispatchOptions: [],
            selectedAutoDispatchOptions: [],
            categories: [],
            placeId: '',
            selectedAssetId: 0,
            selectedCategoryId: null,
            selectedRepairCompany: null,
            loading: false,
            removeLocationModalOpen: false,
            assetsDeactivateConfirmModalOpen: false,
            assetsActivateConfirmModalOpen: false,
            nameEdit: false,
            addressEdit: false,
            houseNumberEdit: false,
            floorEdit: false, 
            zipcodeEdit: false,
            cityEdit: false,
            countryEdit: false,
            ownerEdit: false,
            descriptionEdit: false,
            repaircompanyEdit: false,
            locationTypeEdit: false,
            externalIdEdit: false,
            selectedOwnerId: 0,
            selectedOwnerName: 'Niet geselecteerd',
            selectedRepairCompanyName: 'Niet geselecteerd',
            selectedPlaceTypeId: 0,
            selectedPlaceTypeName: 'Niet geselecteerd',
            mapsApiUrl: '',
            mapsUrl: '',
            deactivateAllButton: false,
            activateAllButton: false,
            searchTerm: '',
            searchSuggestions: [],
            canChangeExternalIds: false,
            allAssetsQrCode: true,
            floorOptions: [],
            canAutoDispatch: false,
            admin: false,
            passedPlace: false,
            disableEdit: false,
            newAssetModalOpen: false,
            existsError: false
        }
    },
    computed: mapState(['vve', 'mapsKey', 'twinq']),
    created() {
        let self = this;
        fetch('/floors.json')
        .then(response => { response.json().then(
            function(data) {
                self.floorOptions = data.floors;
            });
        });
        this.getPlaceInfo();
    },
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        getPlaceInfo() {
            this.newAssetModalOpen = false;
            this.allAssets = [];
            this.loading = true;
            let queryString = window.location.search;
            this.placeId = queryString.split('=')[1];
            let self = this;
            fetch('/api/v1/place/byid?id_place=' + this.placeId)
            .then(response => { response.json().then(
                function(data) {
                    self.placeInfo = data;
                    // Add space in zipcode if it ends in two letters
                    if (/[a-zA-Z]{2}$/.test(self.placeInfo.zipcode)) {
                        self.placeInfo.zipcode = self.placeInfo.zipcode.substring(0, self.placeInfo.zipcode.length - 2) + ' ' + self.placeInfo.zipcode.substring(self.placeInfo.zipcode.length - 2);
                    }                    
                    self.deactivateAllButton = false;
                    self.activateAllButton = false;
                    if (self.placeInfo.visible > 0) {
                        self.deactivateAllButton = true;
                    }
                    if (self.placeInfo.hidden > 0) {
                        self.activateAllButton = true;
                    }
                    if (self.placeInfo.description === "null") {
                        self.placeInfo.description = '';
                    }
                    self.getOwners(data.id_owner);
                    self.getPlaces(data.id_place_type);
                    self.getAssets(data.id_place);
                    self.getCategories();
                    self.getUserRights();
                    let gmapsAddress    = self.placeInfo.address + "+" + self.placeInfo.house_number + "+" + self.placeInfo.city.replace(/ /g , "+");
                    self.mapsApiUrl     = 'https://maps.googleapis.com/maps/api/staticmap?markers='+
                                           gmapsAddress+
                                            '&zoom=16&size=150x150&key=' + self.mapsKey;
                    self.mapsUrl        = "https://www.google.com/maps/place/"+gmapsAddress;
                    self.loading = false;
                    document.title = 'S1MONE | ' + self.placeInfo.name;
                });
            });
        },
        getUserRights() {
            let self = this;
            fetch('/api/v1/userandbuildingmanager')
            .then(response => { response.json().then(
                function(data) {
                    self.admin = data.building_manager_admin;
                    self.canAutoDispatch = data.can_autodispatch;
                    self.disableEdit = data.disable_edit_basedata;
                    if (data.can_change_external_ids) {
                        self.canChangeExternalIds = true;
                    }
                    if (data.id_building_manager !== self.placeInfo.id_building_manager) {
                        self.admin = false;
                        self.passedPlace = true;
                    }
                });
            });
        },
        saveName() {
            const data = new URLSearchParams();
            let paramName = 'data[' + this.placeId + '][name]';
            let paramZipcode = 'data[' + this.placeId + '][zipcode]';
            let paramFloorName = 'data[' + this.placeId + '][floor_name]';
            let paramCountry = 'data[' + this.placeId + '][country]';
            let paramHouseNumber = 'data[' + this.placeId + '][house_number]';
            data.append('action', 'edit');
            data.append(paramName, this.placeInfo.name);
            data.append(paramZipcode, this.placeInfo.zipcode);
            data.append(paramFloorName, this.placeInfo.floor_name);
            data.append(paramCountry, this.placeInfo.country);
            data.append(paramHouseNumber, this.placeInfo.house_number);
            let self = this;
            fetch('/api/v1/place', {
                method: 'post',
                body: data
            }).then((resp) => {
                return resp.json();
            }).then(function(data) {
                self.nameEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        saveAddress() {
            const data = new URLSearchParams();
            let paramAddress = 'data[' + this.placeId + '][address]';
            let paramZipcode = 'data[' + this.placeId + '][zipcode]';
            let paramFloorName = 'data[' + this.placeId + '][floor_name]';
            let paramCountry = 'data[' + this.placeId + '][country]';
            let paramHouseNumber = 'data[' + this.placeId + '][house_number]';
            data.append('action', 'edit');
            data.append(paramAddress, this.placeInfo.address);
            data.append(paramZipcode, this.placeInfo.zipcode);
            data.append(paramFloorName, this.placeInfo.floor_name);
            data.append(paramCountry, this.placeInfo.country);
            data.append(paramHouseNumber, this.placeInfo.house_number);
            let self = this;
            fetch('/api/v1/place', {
                method: 'post',
                body: data
            }).then((resp) => {
                return resp.json();
            }).then(function(data) {
                self.addressEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                let gmapsAddress    = self.placeInfo.address + self.placeInfo.house_number + "+"+self.placeInfo.city.replace(/ /g , "+");
                self.mapsApiUrl     = 'https://maps.googleapis.com/maps/api/staticmap?markers='+
                                       gmapsAddress+
                                        '&zoom=16&size=150x100&key=' + self.mapsKey;
                self.mapsUrl        = "https://www.google.com/maps/place/"+gmapsAddress;
            });
        },
        saveHouseNumber() {
            const data = new URLSearchParams();
            let paramHouseNumber = 'data[' + this.placeId + '][house_number]';
            let paramZipcode = 'data[' + this.placeId + '][zipcode]';
            let paramFloorName = 'data[' + this.placeId + '][floor_name]';
            let paramCountry = 'data[' + this.placeId + '][country]';
            data.append('action', 'edit');
            data.append(paramHouseNumber, this.placeInfo.house_number);
            data.append(paramZipcode, this.placeInfo.zipcode);
            data.append(paramFloorName, this.placeInfo.floor_name);
            data.append(paramCountry, this.placeInfo.country);
            let self = this;
            fetch('/api/v1/place', {
                method: 'post',
                body: data
            }).then((resp) => {
                return resp.json();
            }).then(function(data) {
                self.houseNumberEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                let gmapsAddress    = self.placeInfo.address + self.placeInfo.house_number + "+"+self.placeInfo.city.replace(/ /g , "+");
                self.mapsApiUrl     = 'https://maps.googleapis.com/maps/api/staticmap?markers='+
                                       gmapsAddress+
                                        '&zoom=16&size=150x100&key=' + self.mapsKey;
                self.mapsUrl        = "https://www.google.com/maps/place/"+gmapsAddress;
            });
        },
        saveFloor() {
            const data = new URLSearchParams();
            let paramZipcode = 'data[' + this.placeId + '][zipcode]';
            let paramFloorName = 'data[' + this.placeId + '][floor_name]';
            let paramCountry = 'data[' + this.placeId + '][country]';
            let paramHouseNumber = 'data[' + this.placeId + '][house_number]';
            data.append('action', 'edit');
            data.append(paramZipcode, this.placeInfo.zipcode);
            data.append(paramFloorName, this.placeInfo.floor_name);
            data.append(paramCountry, this.placeInfo.country);
            data.append(paramHouseNumber, this.placeInfo.house_number);
            let self = this;
            fetch('/api/v1/place', {
                method: 'post',
                body: data
            }).then((resp) => {
                return resp.json();
            }).then(function(data) {
                self.floorEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        saveZipcode() {
            const data = new URLSearchParams();
            let paramZipcode = 'data[' + this.placeId + '][zipcode]';
            let paramFloorName = 'data[' + this.placeId + '][floor_name]';
            let paramCountry = 'data[' + this.placeId + '][country]';
            let paramHouseNumber = 'data[' + this.placeId + '][house_number]';
            data.append('action', 'edit');
            data.append(paramZipcode, this.placeInfo.zipcode);
            data.append(paramFloorName, this.placeInfo.floor_name);
            data.append(paramCountry, this.placeInfo.country);
            data.append(paramHouseNumber, this.placeInfo.house_number);
            let self = this;
            fetch('/api/v1/place', {
                method: 'post',
                body: data
            }).then((resp) => {
                return resp.json();
            }).then(function(data) {
                self.zipcodeEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        saveCity() {
            const data = new URLSearchParams();
            let paramZipcode = 'data[' + this.placeId + '][zipcode]';
            let paramFloorName = 'data[' + this.placeId + '][floor_name]';
            let paramCountry = 'data[' + this.placeId + '][country]';
            let paramCity = 'data[' + this.placeId + '][city]';
            let paramHouseNumber = 'data[' + this.placeId + '][house_number]';
            data.append('action', 'edit');
            data.append(paramZipcode, this.placeInfo.zipcode);
            data.append(paramFloorName, this.placeInfo.floor_name);
            data.append(paramCountry, this.placeInfo.country);
            data.append(paramCity, this.placeInfo.city);
            data.append(paramHouseNumber, this.placeInfo.house_number);
            let self = this;
            fetch('/api/v1/place', {
                method: 'post',
                body: data
            }).then((resp) => {
                return resp.json();
            }).then(function(data) {
                self.cityEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                let gmapsAddress    = self.placeInfo.address + self.placeInfo.house_number + "+"+self.placeInfo.city.replace(/ /g , "+");
                self.mapsApiUrl     = 'https://maps.googleapis.com/maps/api/staticmap?markers='+
                                       gmapsAddress+
                                        '&zoom=16&size=150x100&key=' + self.mapsKey;
                self.mapsUrl        = "https://www.google.com/maps/place/"+gmapsAddress;
            });
        },
        saveCountry() {
            const data = new URLSearchParams();
            let paramZipcode = 'data[' + this.placeId + '][zipcode]';
            let paramFloorName = 'data[' + this.placeId + '][floor_name]';
            let paramCountry = 'data[' + this.placeId + '][country]';
            let paramHouseNumber = 'data[' + this.placeId + '][house_number]';
            data.append('action', 'edit');
            data.append(paramZipcode, this.placeInfo.zipcode);
            data.append(paramFloorName, this.placeInfo.floor_name);
            data.append(paramCountry, this.placeInfo.country);
            data.append(paramHouseNumber, this.placeInfo.house_number);
            let self = this;
            fetch('/api/v1/place', {
                method: 'post',
                body: data
            }).then((resp) => {
                return resp.json();
            }).then(function(data) {
                self.countryEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                let gmapsAddress    = self.placeInfo.address + self.placeInfo.house_number + "+"+self.placeInfo.city.replace(/ /g , "+");
                self.mapsApiUrl     = 'https://maps.googleapis.com/maps/api/staticmap?markers='+
                                       gmapsAddress+
                                        '&zoom=16&size=150x100&key=' + self.mapsKey;
                self.mapsUrl        = "https://www.google.com/maps/place/"+gmapsAddress;
            });
        },
        saveOwner() {
            const data = new URLSearchParams();
            let paramZipcode = 'data[' + this.placeId + '][zipcode]';
            let paramFloorName = 'data[' + this.placeId + '][floor_name]';
            let paramCountry = 'data[' + this.placeId + '][country]';
            let paramOwner = 'data[' + this.placeId + '][id_owner]';
            let paramHouseNumber = 'data[' + this.placeId + '][house_number]';
            data.append('action', 'edit');
            data.append(paramZipcode, this.placeInfo.zipcode);
            data.append(paramFloorName, this.placeInfo.floor_name);
            data.append(paramCountry, this.placeInfo.country);
            data.append(paramOwner, this.selectedOwnerId);
            data.append(paramHouseNumber, this.placeInfo.house_number);
            for (var i = 0; i < this.owners.length; i++) {
                let owner = this.owners[i];
                if (owner.id_owner === this.selectedOwnerId) {
                    this.selectedOwnerName = owner.name;
                }
            }
            let self = this;
            fetch('/api/v1/place', {
                method: 'post',
                body: data
            }).then((resp) => {
                return resp.json();
            }).then(function(data) {
                self.ownerEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        savePreferredRep() {
            const data = new URLSearchParams();
            let paramZipcode = 'data[' + this.placeId + '][zipcode]';
            let paramFloorName = 'data[' + this.placeId + '][floor_name]';
            let paramCountry = 'data[' + this.placeId + '][country]';
            let paramRepairCompany = 'data[' + this.placeId + '][primary_id_repair_company]';
            let paramHouseNumber = 'data[' + this.placeId + '][house_number]';
            data.append('action', 'edit');
            data.append(paramZipcode, this.placeInfo.zipcode);
            data.append(paramFloorName, this.placeInfo.floor_name);
            data.append(paramCountry, this.placeInfo.country);
            data.append(paramHouseNumber, this.placeInfo.house_number);
            if (this.placeInfo.primary_id_repair_company) {
                data.append(paramRepairCompany, this.placeInfo.primary_id_repair_company);
            }
            else {
                data.append(paramRepairCompany, 0);
            }
            for (var j = 0; j < this.repaircompanies.length; j++) {
                let repairCompany = this.repaircompanies[j];
                if (repairCompany.id_repair_company === this.placeInfo.primary_id_repair_company) {
                    this.selectedRepairCompanyName = repairCompany.name;
                }
            }
            let self = this;
            fetch('/api/v1/place', {
                method: 'post',
                body: data
            }).then((resp) => {
                return resp.json();
            }).then(function(data) {
                self.repaircompanyEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        saveLocationType() {
            const data = new URLSearchParams();
            let paramPlaceType = 'data[' + this.placeId + '][id_place_type]';
            let paramZipcode = 'data[' + this.placeId + '][zipcode]';
            let paramFloorName = 'data[' + this.placeId + '][floor_name]';
            let paramCountry = 'data[' + this.placeId + '][country]';
            let paramHouseNumber = 'data[' + this.placeId + '][house_number]';
            data.append('action', 'edit');
            data.append(paramZipcode, this.placeInfo.zipcode);
            data.append(paramFloorName, this.placeInfo.floor_name);
            data.append(paramCountry, this.placeInfo.country);
            data.append(paramHouseNumber, this.placeInfo.house_number);
            if (this.selectedPlaceTypeId) {
                data.append(paramPlaceType, this.selectedPlaceTypeId);
                for (var k = 0; k < this.placeTypes.length; k++) {
                    let placeType = this.placeTypes[k];
                    if (placeType.id_place_type === this.selectedPlaceTypeId) {
                        this.selectedPlaceTypeName = placeType.name;
                    }
                }
            }
            else {
                data.append(paramPlaceType, 0);
            }
            let self = this;
            fetch('/api/v1/place', {
                method: 'post',
                body: data
            }).then((resp) => {
                return resp.json();
            }).then(function(data) {
                self.locationTypeEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        saveExternalId() {
            const data = new URLSearchParams();
            let paramIdExternal = 'data[' + this.placeId + '][id_external]';
            let paramZipcode = 'data[' + this.placeId + '][zipcode]';
            let paramFloorName = 'data[' + this.placeId + '][floor_name]';
            let paramCountry = 'data[' + this.placeId + '][country]';
            let paramHouseNumber = 'data[' + this.placeId + '][house_number]';
            data.append('action', 'edit');
            data.append(paramZipcode, this.placeInfo.zipcode);
            data.append(paramFloorName, this.placeInfo.floor_name);
            data.append(paramCountry, this.placeInfo.country);
            data.append(paramIdExternal, this.placeInfo.id_external);
            data.append(paramHouseNumber, this.placeInfo.house_number);
            let self = this;
            fetch('/api/v1/place', {
                method: 'post',
                body: data
            }).then((resp) => {
                return resp.json();
            }).then(function(data) {
                self.externalIdEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        saveDescription() {
            const data = new URLSearchParams();
            let paramZipcode = 'data[' + this.placeId + '][zipcode]';
            let paramFloorName = 'data[' + this.placeId + '][floor_name]';
            let paramCountry = 'data[' + this.placeId + '][country]';
            let paramDescription = 'data[' + this.placeId + '][description]';
            let paramHouseNumber = 'data[' + this.placeId + '][house_number]';
            data.append('action', 'edit');
            data.append(paramZipcode, this.placeInfo.zipcode);
            data.append(paramFloorName, this.placeInfo.floor_name);
            data.append(paramCountry, this.placeInfo.country);
            data.append(paramDescription, this.placeInfo.description);
            data.append(paramHouseNumber, this.placeInfo.house_number);
            let self = this;
            fetch('/api/v1/place', {
                method: 'post',
                body: data
            }).then((resp) => {
                return resp.json();
            }).then(function(data) {
                self.descriptionEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        zipCodeSearch() {
            let self = this;
            fetch('/api/v1/place/byzipcodehousenumber?zipcode=' + this.placeInfo.zipcode + '&housenumber=' + this.placeInfo.house_number)
            .then(response => { response.json().then(
                function(data) {
                    let placeZip = self.placeInfo.zipcode.replace(' ', '').toLowerCase();
                    if (data[0].house_number === self.placeInfo.house_number.trim() && data[0].zipcode.toLowerCase() === placeZip) {
                        self.existsError = true;
                    }
                });
            });
        },
        getOwners(id_owner) {
            this.selectedOwnerId = id_owner;
            let self = this;
            fetch('/api/v1/owner')
            .then(response => { response.json().then(
                function(data) {
                    self.owners = data.data;
                    for (var i = 0; i < self.owners.length; i++) {
                        let owner = self.owners[i];
                        if (owner.id_owner === self.selectedOwnerId) {
                            self.selectedOwnerName = owner.name;
                        }
                    }
                    self.owners = sortBy(self.owners, 'name');
                });
            });
        },
        getRepairCompanies() {
            let self = this;
            fetch('/api/v1/repaircompany/bybuildingmanager')
            .then(response => { response.json().then(
                function(data) {
                    self.repaircompanies = data.data;
                    for (var i = 0; i < self.repaircompanies.length; i++) {
                        let repairCompany = self.repaircompanies[i];
                        repairCompany.lowerName = repairCompany.name.toLowerCase();
                        if (repairCompany.id_repair_company === self.placeInfo.primary_id_repair_company) {
                            self.selectedRepairCompanyName = repairCompany.name;
                        }
                        if (self.placeInfo.primary_id_repair_company === repairCompany.id_repair_company) {
                            self.selectedRepairCompanyName = repairCompany.name;
                        }
                    }
                    self.repaircompanies = sortBy(self.repaircompanies, 'lowerName');
                    self.getAutoDispatchOptions(self.placeId)
                });
            });
        },
        getPlaces(id_place) {
            this.selectedPlaceTypeId = id_place;
            let self = this;
            fetch('/api/v1/placetype')
            .then(response => { response.json().then(
                function(data) {
                    self.placeTypes = data.data;
                    for (var i = 0; i < self.placeTypes.length; i++) {
                        let placeType = self.placeTypes[i];
                        if (placeType.id_place_type === self.selectedPlaceTypeId) {
                            self.selectedPlaceTypeName = placeType.name;
                        }
                    }
                    self.placeTypes = sortBy(self.placeTypes, 'name');
                });
            });
        },
        getAssets(id_place) {
            let self = this;
            fetch('/api/v1/asset/filtered?id_place=' + id_place)
            .then(response => { response.json().then(
                function(data) {
                    let assets = data.data;
                    for (var i = 0; i < assets.length; i++) {
                        let asset = assets[i];
                        if (!asset.partof_id_asset) {
                            self.allAssets.push(asset);
                        }
                        if (asset.holder) {
                            asset.subAssets = [];
                        }
                    }
                    // Subassets arent currently used
                    for (var j = 0; j < self.allAssets.length; j++) {
                        let mainAsset = self.allAssets[j];
                        for (var k = 0; k < assets.length; k++) {
                            let asset = assets[k];
                            asset.lowerCaseName = asset.name.toLowerCase();
                            if (asset.partof_id_asset) {
                                if (asset.partof_id_asset === mainAsset.id_asset) {
                                    mainAsset.subAssets.push(asset);
                                }
                            }
                            if (!asset.qr_code) {
                                self.allAssetsQrCode = false;
                            }
                        }
                    }
                    self.allAssets = sortBy(self.allAssets, 'lowerCaseName');
                });
            });
        },
        getCategories() {
            let self = this;
            fetch('/api/v1/incident_category/bybuildingmanager')
            .then(response => { response.json().then(
                function(data) {
                    self.categories = data.data;
                    if (self.locale === 'nl') {
                        self.categories = sortBy(self.categories, 'dutch_name');
                    }
                    if (self.locale === 'en') {
                        self.categories = sortBy(self.categories, 'english_name');
                    }
                    self.getRepairCompanies();
                });
            });
        },
        selectAssetToEdit(id_asset) {
            this.selectedAssetId = id_asset;
            if (!this.twinq) {
                this.$router.push('/buildingmanager/asset-detail?asset=' + id_asset);
            }
        },
        reloadAssets(id_place) {
            this.allAssets = [];
            this.assetsDeactivateConfirmModalOpen = false;
            this.assetsActivateConfirmModalOpen = false;
            this.getPlaceInfo();
        },
        selectAutoDispatch() {
            if (this.selectedCategoryId && this.selectedRepairCompany) {
                const data = new URLSearchParams();
                data.append('id_incident_category', this.selectedCategoryId);
                data.append('id_repair_company', this.selectedRepairCompany);
                data.append('id_place', this.placeId);
                let self = this;
                fetch('/api/v1/placeincidentcategoryrepaircompany/create', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.$gtag.event('click', {
                        event_category: 'Button',
                        event_label: 'Aanmaken autodispatch regel',
                    });
                    self.getAutoDispatchOptions(self.placeId);
                    self.selectedCategoryId = null;
                    self.selectedRepairCompany = null;
                    let successText = self.profile.dashboard.building_manager.message_table.place + ' <b>' + self.placeInfo.name + '</b> ' + self.profile.toast.edited_successfully;
                    self.$vToastify.success(successText, self.profile.toast.location_edited);
                });
            }
        },
        getAutoDispatchOptions(id_place) {
            let self = this;
            fetch('/api/v1/placeincidentcategoryrepaircompany/byidplace?id_place=' + id_place)
            .then(response => { response.json().then(
                function(data) {
                    for (var l = 0; l < data.length; l++) {
                        let option = data[l];
                        option.editMode = false;
                    }
                    self.selectedAutoDispatchOptions = data;
                    for (var j = 0; j < self.selectedAutoDispatchOptions.length; j++) {
                        let autodispatchOption = self.selectedAutoDispatchOptions[j];
                        for (var i = 0; i < self.repaircompanies.length; i++) {
                            let repaircompany = self.repaircompanies[i];
                            if (repaircompany.id_repair_company === autodispatchOption.id_repair_company) {
                                autodispatchOption.repair_company_name = repaircompany.name;
                            }
                        }
                        for (var k = 0; k < self.categories.length; k++) {
                            let category = self.categories[k];
                            if (category.id_incident_category === autodispatchOption.id_incident_category) {
                                autodispatchOption.category_name = category.dutch_name.toLowerCase();
                            }
                        }
                    }
                });
            });
        },
        deleteAutoDispatchOption(id) {
            const data = new URLSearchParams();
            data.append('id_place_incident_category_repair_company', id);
            let self = this;
            fetch('/api/v1/placeincidentcategoryrepaircompany/delete', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.getAutoDispatchOptions(self.placeId);
            });
        },
        saveAutoDispatchOption(selectedAutoDispatchOption) {
            const data = new URLSearchParams();
            data.append('id_place_incident_category_repair_company', selectedAutoDispatchOption.id_place_incident_category_repair_company);
            data.append('id_incident_category', selectedAutoDispatchOption.id_incident_category);
            let self = this;
            fetch('/api/v1/placeincidentcategoryrepaircompany/updateincidentcategory', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
            });
            const object = new URLSearchParams();
            object.append('id_place_incident_category_repair_company', selectedAutoDispatchOption.id_place_incident_category_repair_company);
            object.append('id_repair_company', selectedAutoDispatchOption.id_repair_company);
            fetch('/api/v1/placeincidentcategoryrepaircompany/updaterepaircompany', {
                method: 'post',
                body: object
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.getAutoDispatchOptions(self.placeId);
                selectedAutoDispatchOption.editMode = false;
            });
        },
        searchSuggest($event) {
            this.searchSuggestions = [];
            let searchTerm = this.searchTerm.toLowerCase();
            if (searchTerm.length > 2) {
                for (var i = 0; i < this.allAssets.length; i++) {
                    let asset = this.allAssets[i];
                    let assetName = asset.name.toLowerCase();
                    let assetId = asset.id_asset.toString();
                    if (assetName.includes(searchTerm) || assetId.includes(searchTerm)) {
                        this.searchSuggestions.push(asset);
                    }
                }
            }
        },
        clearSuggestions() {
            this.searchSuggestions = [];
            this.searchTerm = '';
        }
    }
}
</script>
