<template>
    <div>
        <div class="main-content">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="block block--owners" >
                            <section class="incident-head">
                                <h1>Leveranciers</h1>
                            </section>
                            <section class="search search-alt" v-if="suppliers.length">
                                <form @submit.prevent="searchSuggest()">
                                    <input type="text" ref="search" v-bind:placeholder="'Zoek een leverancier op naam'" @input="e => searchTerm = e.target.value" :value="searchTerm" v-on:keyup="searchSuggest($event)" class="no-margin" />
                                    <span class="close-suggestions" @click="clearSuggestions()" v-if="searchActive"><i class="fas fa-times"></i></span>
                                </form>
                            </section>
                            <div v-if="searchActive">
                                <h4 class="result-counter" v-if="searchSuggestions.length === 1">{{searchSuggestions.length}} {{'resultaat gevonden met de term'}} '{{searchTerm}}'</h4>
                                <h4 class="result-counter" v-if="!searchSuggestions.length">{{"Geen resultaten gevonden met de term"}} '{{searchTerm}}'.</h4>
                                <h4 class="result-counter" v-if="searchSuggestions.length > 1">{{searchSuggestions.length}} {{'resultaten gevonden met de term'}} '{{searchTerm}}'</h4>
                                <div v-for="supplier in searchSuggestions" :key="supplier.id_repair_company" class="list-item partner-list-item" @click="selectsupplier(supplier.id_repair_company)">
                                    <b style="float: left; margin-left: 20px;">{{ supplier.name}}</b>
                                    <span class="active-label" v-if="supplier.active" style="top: 0; right: 0; font-size: 13px; float: right; position: relative;">Actief</span>
                                </div>
                            </div>
                            <div v-else style="height: 600px; overflow: auto; padding-right: 10px; margin-top: 10px;">
                                <h4 class="result-counter">{{suppliersShown.length}} {{'Leveranciers'}}</h4>
                                <div class="list-item partner-list-item" v-for="(supplier, index) in suppliersShown" :key="index" style="border: 1px solid #dbdbdb; margin-bottom: 10px;" @click="selectsupplier(supplier.id_repair_company)"> 
                                    <b style="float: left; margin-left: 20px;">{{ supplier.name }} </b>
                                    <span class="active-label" v-if="supplier.active" style="top: 0; right: 0; font-size: 13px; float: right; position: relative;">Actief</span>
                                </div>
                            </div>                          
                        </div>
                    </div>
                </div>
            </div>    
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';

export default {
    mixins: [setLocale],
    data() {
        return {
            searchTerm: '',
            searchSuggestions: [],
            suppliers: [],
            suppliersShown: [],
            searchActive: false,
        }
    },
    created() {
        document.title = 'S1MONE | Suppliers';
        this.loadSuppliers();
    },
    methods: {
        loadSuppliers() {
            this.newPartnerModalOpen = false;
            let self = this;
            fetch('/api/v1/repaircompany')
            .then(response => { response.json().then(
                function(data) {
                    self.suppliers = data.data;
                    self.suppliersShown = data.data;
                    self.suppliersShown.sort((a, b) => {
                        return a.name.localeCompare(b.name);
                    });
                });
            });
        },
        searchSuggest($event) {
            this.searchSuggestions = [];
            let searchTerm = this.searchTerm.toLowerCase();
            if (searchTerm.length > 2) {
                this.searchActive = true;
                for (var i = 0; i < this.suppliersShown.length; i++) {
                    let supplier = this.suppliersShown[i];
                    let supplierName = supplier.name.toLowerCase();
                    if (supplierName.includes(searchTerm)) {
                        this.searchSuggestions.push(supplier);
                    }
                }
            }
            if (searchTerm.length === 0) {
                this.searchActive = false;
            }
            if($event && $event.keyCode === 27) {
                this.searchActive = false;
                this.searchTerm = '';
                this.searchSuggestions = [];
            }
        },
        clearSuggestions() {
            this.searchSuggestions = [];
            this.searchTerm = '';
            this.searchActive = false;
        },
        selectsupplier(id) {
            this.$router.push('/s1mone/supplier-detail?id=' + id);
        }
    }
}
</script>
