<template>
    <div>
        <div v-if="!selectedAction.id_planned_action">
            <div class="row">
                <div class="col">
                    <div class="link-switch" style="margin-bottom: 10px;">
                        <span :class="{active: switchType === 'new'}" @click="switchTab('new')">{{profile.actionable.completed_recordings}}</span>
                        <span :class="{active: switchType === 'archived'}" @click="switchTab('archived')">{{profile.actionable.archived_recordings}}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <section class="search search-inpage" :class="{active: searchActive}">
                        <form @submit.prevent="search()">
                            <input type="text" data-testid="searchRecordingLocation" ref="search" v-bind:placeholder="profile.actionable.search_street" @input="e => searchTerm = e.target.value" :value="searchTerm" v-on:keyup="search($event)" @click="toggleDropdown('search')" style="margin-bottom: 0;" />
                            <span class="close-suggestions" data-testid="clearLocationSearch" @click="clearSearch()" v-if="searchActive"><i class="fas fa-times"></i></span>
                        </form>
                    </section>
                </div>
            </div> 
            <div class="row">
                <div class="col-sm-3">
                    <div class="dropdown-section" style="margin-top: 10px;" :class="{disabled: selectedState, normalcursor: selectedState}">
                        <span v-if="selectedState" data-testid="clearStateSearch" class="remove-selection" style="right: 15px; top: 18px; cursor: pointer; z-index: 2;" @click="removeSelectedState()"><i class="fas fa-times"></i></span>
                        <span class="dropdown" data-testid="stateDropdown" :class="{active: stateDropdownToggled}" @click="toggleDropdown('state')" style="border-radius: 5px; height: 54px;">
                            <span v-if="!selectedState" class="placeholder">{{ profile.actionable.allover_state }}</span>
                            <span v-if="selectedState" class="selected" style="line-height: 54px;">{{selectedState}}</span>
                            <i class="fas fa-chevron-down chevron" v-if="!selectedState"></i>
                        </span>
                        <div class="dropdown-list" v-if="stateDropdownToggled" style="position: absolute;  width: 100%;">
                            <ul>
                                <li v-on:click="selectState('Slecht')">{{profile.actionable.bad}}</li>
                                <li v-on:click="selectState('Matig')">{{profile.actionable.moderate}}</li>
                                <li v-on:click="selectState('Goed')">{{profile.actionable.good}}</li>
                                <li v-on:click="selectState('Uitstekend')">{{profile.actionable.excellent}}</li>
                                <li v-on:click="selectState('Geen staat')">{{profile.actionable.none}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="dropdown-section" style="margin-top: 10px;">
                        <span v-if="selectedAddress" data-testid="clearStreetSearch" class="remove-selection" style="right: 15px; top: 18px; cursor: pointer; z-index: 2;" @click="removeSelectedAddress()"><i class="fas fa-times"></i></span>
                        <span class="dropdown" data-testid="streetNameDropdown" :class="{active: addressDropdownToggled}" @click="toggleDropdown('address')" style="border-radius: 5px; height: 54px;">
                            <span v-if="!selectedAddress" class="placeholder">{{profile.places.placetable.address}}</span>
                            <span v-if="selectedAddress" class="selected" style="line-height: 54px;">{{selectedAddress}}</span>
                            <i class="fas fa-chevron-down chevron" v-if="!selectedAddress"></i>
                        </span>
                        <div class="dropdown-list" v-if="addressDropdownToggled" style="position: absolute;  width: 100%;">
                            <ul>
                                <li v-for="(address, index) in addressList" v-on:click="selectAddress(address)" :key="index">{{address}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-sm-3" v-if="housenumberList.length > 1">
                    <div class="dropdown-section" style="margin-top: 10px;">
                        <span v-if="selectedHouseNumber" class="remove-selection" style="right: 15px; top: 18px; cursor: pointer; z-index: 2;" @click="removeSelectedHousenumber()"><i class="fas fa-times"></i></span>
                        <span class="dropdown" :class="{active: housenumberDropdownToggled}" @click="toggleDropdown('housenumber')" style="border-radius: 5px; height: 54px;">
                            <span v-if="!selectedHouseNumber" class="placeholder">{{profile.places.placetable.house_number}}</span>
                            <span v-if="selectedHouseNumber" class="selected" style="line-height: 54px;">{{selectedHouseNumber}}</span>
                            <i class="fas fa-chevron-down chevron" v-if="!selectedHouseNumber"></i>
                        </span>
                        <div class="dropdown-list" v-if="housenumberDropdownToggled" style="position: absolute; width: 100%;">
                            <ul>
                                <li v-for="(housenumber, index) in housenumberList" v-on:click="selectHousenumber(housenumber)" :key="index">{{housenumber}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-sm-3">
                    <span v-if="dateFilter" data-testid="clearDateSearch" class="remove-selection" style="right: 28px; top: 26px; cursor: pointer; z-index: 2;" @click="removeSelectedDate()"><i class="fas fa-times"></i></span>
                    <flat-pickr v-model="dateFilter" class="form-control filter" :config="flatPickrDateConfig" :placeholder="profile.incident_detail.pick_date" @on-change="selectDate()" @on-open="toggleDropdown('date')"></flat-pickr>
                </div>
            </div>
            <div class="row" v-if="searchActive" style="margin-top: 10px;">
                <div class="col">
                    <h4 class="result-counter" data-testid="noResultsFound" v-if="!tempAssetsList.length">{{profile.dashboard.building_manager.no_results}} '{{searchTerm}}'.</h4>
                    <h4 class="result-counter" data-testid="oneResultsFound" v-if="tempAssetsList.length === 1">{{tempAssetsList.length}} {{profile.dashboard.building_manager.result}} '{{searchTerm}}'</h4>
                    <h4 class="result-counter" data-testid="multipleResultsFound" v-if="tempAssetsList.length > 1">{{tempAssetsList.length}} {{profile.dashboard.building_manager.results}} '{{searchTerm}}'</h4>
                </div>
            </div>
            <div class="row" v-if="selectedState || selectedAddress || dateFilter" style="margin-top: 10px;">
                <div class="col">
                    <h4 class="result-counter" data-testid="noResultsFound" v-if="!assetsList.length">{{assetsList.length}} {{profile.assets.objects_found}}</h4>
                    <h4 class="result-counter" data-testid="oneResultsFound" v-if="assetsList.length === 1">{{assetsList.length}} {{profile.assets.object_found}}</h4>
                    <h4 class="result-counter" data-testid="multipleResultsFound" v-if="assetsList.length > 1">{{assetsList.length}} {{profile.assets.objects_found}}</h4>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="asset-blocks" v-if="!searchActive" style="padding-right: 0; max-height: 80vh;">
                        <div class="asset-block recording" :id="'asset-' + asset.id_asset" v-for="(asset, index) in assetsList" :key="index" style="width: 100%; background: whitesmoke;" @click.stop="getRecordings(asset)" :class="{active: asset.active}" v-show="asset.planned_action_lists.length">
                            <h4 style="margin-bottom: 0;" data-testid="completedAssetName"><router-link tag="a" style="display: inline;" v-bind:to="'/buildingmanager/asset-detail?asset=' + asset.id_asset">{{ asset.asset_name }}</router-link></h4>
                            <span class="placename" style="margin-top: 0; height: auto;">{{ asset.place_name }}</span>
                            <p class="location" style="margin-bottom: 0;">{{ asset.address }} {{ asset.house_number }}, {{ profile.incident_detail.floor_name }} {{ asset.floor_name }}, {{ asset.zipcode }} {{ asset.city }}</p>
                            <div class="toggler" data-testid="toggleAssetDropdown" @click.stop="getRecordings(asset)" :class="{active: asset.active}">
                                <i class="fas fa-chevron-down chevron"></i>
                            </div>
                            <div class="general-states" v-if="asset.generalStateBad.length || asset.generalStateModerate.length || asset.generalStateGood.length || asset.generalStateExcellent.length || asset.generalStateNone.length">
                                <h3>{{ profile.actionable.allover_state }}:</h3>
                                <span v-if="asset.generalStateBad.length" class="red">{{profile.actionable.bad}} ({{ asset.generalStateBad.length }})</span>
                                <span v-if="asset.generalStateModerate.length" class="orange">{{profile.actionable.moderate}} ({{ asset.generalStateModerate.length }})</span>
                                <span v-if="asset.generalStateGood.length" class="green">{{profile.actionable.good}} ({{ asset.generalStateGood.length }})</span>
                                <span v-if="asset.generalStateExcellent.length" class="blue">{{profile.actionable.excellent}} ({{ asset.generalStateExcellent.length }})</span>
                                <span v-if="asset.generalStateNone.length" class="gray">{{profile.actionable.none}} ({{ asset.generalStateNone.length }})</span>
                            </div>
                            <div class="recording-blocks" style="margin-top: 10px;" v-if="asset.active">
                                <div class="recording-block" v-for="(recording, index) in asset.recordings" :key="index" @click.stop="showActionables(asset, recording)" :class="{active: recording.active}" v-show="recording.planned_actions.length">
                                    <span style="color: #686868; font-size: 14px; margin-bottom: 5px;">{{ profile.actionable.recording }}</span>
                                    <h5 style="margin: 0;" data-testid="completedListName">{{ recording.planned_action_list_name }}
                                        <div class="active-label" data-testid="completedLabel" v-if="recording.status === 'completed' && switchType === 'new'">{{profile.actionable.completed}}</div>
                                        <div class="active-label gray" v-if="recording.status === 'archived'">{{profile.actionable.fully_archived}}</div>
                                        <div class="active-label orange" v-if="(recording.status === 'started' || recording.status === 'planned') && switchType === 'new'">{{profile.actionable.partially_completed}}</div>
                                    </h5>
                                    <div class="row" style="margin-top: 0;">
                                        <div class="col-sm-6" v-if="recording.completedDate">
                                            <label style="margin-bottom: 0; font-size: 14px;">{{profile.actionable.recording_date}} & {{profile.actionable.recording_time}}</label>
                                            <p style="margin-bottom: 0; font-size: 14px;">{{recording.completedDate}} {{ recording.completedTime }}</p>
                                        </div>
                                        <div class="col-sm-6">
                                            <label style="margin-bottom: 0; font-size: 14px;">{{profile.actionable.latest_recording_date}}</label>
                                            <p style="margin-bottom: 0; font-size: 14px;" data-testid="completedLatestDate">{{recording.latestDate}}</p>
                                        </div>                                    
                                    </div>
                                    <div class="general-states" v-if="recording.generalStateBad.length || recording.generalStateModerate.length || recording.generalStateGood.length || recording.generalStateExcellent.length">
                                        <h3>{{ profile.actionable.allover_state }}:</h3>
                                        <span v-if="recording.generalStateBad.length" class="red">{{profile.actionable.bad}} ({{ recording.generalStateBad.length }})</span>
                                        <span v-if="recording.generalStateModerate.length" class="orange">{{profile.actionable.moderate}} ({{ recording.generalStateModerate.length }})</span>
                                        <span v-if="recording.generalStateGood.length" class="green">{{profile.actionable.good}} ({{ recording.generalStateGood.length }})</span>
                                        <span v-if="recording.generalStateExcellent.length" class="blue">{{profile.actionable.excellent}} ({{ recording.generalStateExcellent.length }})</span>
                                        <span v-if="recording.generalStateNone.length" class="gray">{{profile.actionable.none}} ({{ recording.generalStateNone.length }})</span>
                                    </div>
                                    <div class="toggler" data-testid="toggleRecordingDropdown" :class="{active: recording.active}" v-if="recording.planned_actions.length" >
                                        <i class="fas fa-chevron-down chevron"></i>
                                    </div>
                                    <div class="actionables" v-if="recording.active && recording.planned_actions.length" style="padding: 10px 0 0 0;">
                                        <span style="color: #686868; font-size: 14px; margin-bottom: 5px;">{{ profile.actionable.actions }}</span>
                                        <div class="actionable" v-for="(actionable, index) in recording.planned_actions" :key="index" style="position: relative;" @click.stop="selectAction(recording, actionable)" :class="{planned: actionable.status === 'planned'}">
                                            <h3 style="margin-bottom: 5px; font-size: 16px;" data-testid="completedActionName">{{ actionable.name }}
                                                <div class="active-label gray" v-if="actionable.status === 'planned'">{{profile.incident_detail.planned}}</div>
                                            </h3>  
                                            <div class="row" v-if="actionable.completedDate">
                                                <div class="col-sm-6">
                                                    <div class="general-states" v-if="actionable.status === 'completed' || actionable.status === 'archived'">
                                                        <h3 style="font-size: 14px;">{{ profile.actionable.allover_state }}:</h3>
                                                        <span v-if="actionable.fields.state === 'Slecht'" data-testid="completedActionState" class="red" style="font-size: 14px; font-weight: 100;">{{actionable.fields.state}}</span>
                                                        <span v-if="actionable.fields.state === 'Matig'" data-testid="completedActionState" class="orange" style="font-size: 14px; font-weight: 100;">{{actionable.fields.state}}</span>
                                                        <span v-if="actionable.fields.state === 'Goed'" data-testid="completedActionState" class="green" style="font-size: 14px; font-weight: 100;">{{actionable.fields.state}}</span>
                                                        <span v-if="actionable.fields.state === 'Uitstekend'" data-testid="completedActionState" class="blue" style="font-size: 14px; font-weight: 100;">{{actionable.fields.state}}</span>
                                                        <span v-if="!actionable.fields.state" data-testid="completedActionState" style="font-size: 14px; font-weight: 100;">{{profile.actionable.none}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6" v-if="actionable.completedDate">
                                                    <label style="float: left; font-size: 14px;  margin-bottom: 0;">{{profile.actionable.recording_date}} & {{ profile.actionable.time }}: </label>
                                                    <p style="float: left; font-size: 14px;  margin: 0 0 0 5px;">{{ actionable.completedDate }} {{ actionable.completedTime }}</p>        
                                                </div>
                                            </div>      
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="asset-blocks" v-else style="max-height: 80vh;">
                        <div class="asset-block recording" :id="'asset-' + asset.id_asset" v-for="(asset, index) in tempAssetsList" :key="index" style="width: 100%; background: whitesmoke;" @click.stop="getRecordings(asset)" :class="{active: asset.active}" v-show="asset.planned_action_lists.length">
                            <h4 style="margin-bottom: 0;"><router-link tag="a" style="display: inline;" v-bind:to="'/buildingmanager/asset-detail?asset=' + asset.id_asset">{{ asset.asset_name }}</router-link></h4>
                            <span class="placename" style="margin-top: 0; height: auto;">{{ asset.place_name }}</span>
                            <p class="location" style="margin-bottom: 0;">{{ asset.address }} {{ asset.house_number }}, {{ profile.incident_detail.floor_name }} {{ asset.floor_name }}, {{ asset.zipcode }} {{ asset.city }}</p>
                            <div class="toggler" @click.stop="getRecordings(asset)" :class="{active: asset.active}">
                                <i class="fas fa-chevron-down chevron"></i>
                            </div>
                            <div class="general-states" v-if="asset.generalStateBad.length || asset.generalStateModerate.length || asset.generalStateGood.length || asset.generalStateExcellent.length || asset.generalStateNone.length">
                                <h3>{{ profile.actionable.allover_state }}:</h3>
                                <span v-if="asset.generalStateBad.length" class="red">{{profile.actionable.bad}} ({{ asset.generalStateBad.length }})</span>
                                <span v-if="asset.generalStateModerate.length" class="orange">{{profile.actionable.moderate}} ({{ asset.generalStateModerate.length }})</span>
                                <span v-if="asset.generalStateGood.length" class="green">{{profile.actionable.good}} ({{ asset.generalStateGood.length }})</span>
                                <span v-if="asset.generalStateExcellent.length" class="blue">{{profile.actionable.excellent}} ({{ asset.generalStateExcellent.length }})</span>
                                <span v-if="asset.generalStateNone.length" class="gray">{{profile.actionable.none}} ({{ asset.generalStateNone.length }})</span>
                            </div>
                            <div class="recording-blocks" style="margin-top: 10px;" v-if="asset.active">
                                <div class="recording-block" v-for="(recording, index) in asset.recordings" :key="index" @click.stop="showActionables(asset, recording)" :class="{active: recording.active}" v-show="recording.planned_actions.length">
                                    <span style="color: #686868; font-size: 14px; margin-bottom: 5px;">{{ profile.actionable.recording }}</span>
                                    <h5 style="margin: 0;">{{ recording.planned_action_list_name }}
                                        <div class="active-label" v-if="recording.status === 'completed' && switchType === 'new'">{{profile.actionable.completed}}</div>
                                        <div class="active-label gray" v-if="recording.status === 'archived'">{{profile.actionable.fully_archived}}</div>
                                        <div class="active-label orange" v-if="(recording.status === 'started' || recording.status === 'planned') && switchType === 'new'">{{profile.actionable.partially_completed}}</div>
                                    </h5>
                                    <div class="row" style="margin-top: 0;">                     
                                        <div class="col-sm-6" v-if="recording.completedDate">
                                            <label style="margin-bottom: 0; font-size: 14px;">{{profile.actionable.recording_date}} & {{profile.actionable.recording_time}}</label>
                                            <p style="margin-bottom: 0; font-size: 14px;">{{recording.completedDate}} {{ recording.completedTime }}</p>
                                        </div>
                                        <div class="col-sm-6">
                                            <label style="margin-bottom: 0; font-size: 14px;">{{profile.actionable.latest_recording_date}}</label>
                                            <p style="margin-bottom: 0; font-size: 14px;">{{recording.latestDate}}</p>
                                        </div>
                                    </div>
                                    <div class="general-states" v-if="recording.generalStateBad.length || recording.generalStateModerate.length || recording.generalStateGood.length || recording.generalStateExcellent.length">
                                        <h3>{{ profile.actionable.allover_state }}:</h3>
                                        <span v-if="recording.generalStateBad.length" class="red">{{profile.actionable.bad}} ({{ recording.generalStateBad.length }})</span>
                                        <span v-if="recording.generalStateModerate.length" class="orange">{{profile.actionable.moderate}} ({{ recording.generalStateModerate.length }})</span>
                                        <span v-if="recording.generalStateGood.length" class="green">{{profile.actionable.good}} ({{ recording.generalStateGood.length }})</span>
                                        <span v-if="recording.generalStateExcellent.length" class="blue">{{profile.actionable.excellent}} ({{ recording.generalStateExcellent.length }})</span>
                                        <span v-if="recording.generalStateNone.length" class="blue">{{profile.actionable.none}} ({{ recording.generalStateNone.length }})</span>
                                    </div>
                                    <div class="toggler" :class="{active: recording.active}" v-if="recording.planned_actions.length" >
                                        <i class="fas fa-chevron-down chevron"></i>
                                    </div>
                                    <div class="actionables" v-if="recording.active && recording.planned_actions.length" style="padding: 10px 0 0 0;">
                                        <span style="color: #686868; font-size: 14px; margin-bottom: 5px;">{{ profile.actionable.actions }}</span>
                                        <div class="actionable" v-for="(actionable, index) in recording.planned_actions" :key="index" style="position: relative;" @click.stop="selectAction(recording, actionable)">
                                            <h3 style="margin-bottom: 5px; font-size: 16px;">{{ actionable.name }}
                                                <div class="active-label gray" v-if="actionable.status === 'planned'">{{profile.incident_detail.planned}}</div>
                                            </h3>   
                                            <div class="row" v-if="actionable.completedDate">
                                                <div class="col-sm-6">
                                                    <div class="general-states" v-if="actionable.status === 'completed' || actionable.status === 'archived'">
                                                        <h3 style="font-size: 14px;">{{ profile.actionable.allover_state }}:</h3>
                                                        <span v-if="actionable.fields.state === 'Slecht'" class="red" style="font-size: 14px; font-weight: 100;">{{actionable.fields.state}}</span>
                                                        <span v-if="actionable.fields.state === 'Matig'" class="orange" style="font-size: 14px; font-weight: 100;">{{actionable.fields.state}}</span>
                                                        <span v-if="actionable.fields.state === 'Goed'" class="green" style="font-size: 14px; font-weight: 100;">{{actionable.fields.state}}</span>
                                                        <span v-if="actionable.fields.state === 'Uitstekend'" class="blue" style="font-size: 14px; font-weight: 100;">{{actionable.fields.state}}</span>
                                                        <span v-if="!actionable.fields.state" style="font-size: 14px; font-weight: 100;">{{profile.actionable.none}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label style="float: left; font-size: 14px; margin-bottom: 0;">{{profile.actionable.recording_date}} & {{ profile.actionable.time }}: </label>
                                                    <p style="float: left; font-size: 14px;  margin: 0 0 0 5px;">{{ actionable.completedDate }} {{ actionable.completedTime }}</p>        
                                                </div>
                                            </div>       
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="row">
                <div class="col">
                    <a class="back-link" @click="back()" style="margin-bottom: 10px;"><i class="fas fa-chevron-left"></i>{{profile.nav.back_link}}</a>
                </div>
            </div> 
            <div class="row">
                <div class="col">
                    <div class="row">
                        <div class="col">
                            <h3 style="font-size: 21px; margin: 0 0 20px 0; overflow: hidden;">
                                <span style="float: left; font-weight: 500;" data-testid="selectedActionName">{{selectedAction.name}}</span>
                                <span class="active-label gray" v-if="selectedAction.status === 'archived'" style="float: left; margin-left: 5px; position: relative; top: auto; right: auto; font-size: 14px; padding: 3px 5px;">{{ profile.actionable.archived }}</span>
                                <span class="active-label gray" v-if="selectedAction.status === 'planned'" style="float: left; margin-left: 5px; position: relative; top: auto; right: auto; font-size: 14px; padding: 3px 5px;">{{ profile.incident_detail.planned }}</span>
                            </h3>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <label>{{profile.incidents.new_incident.id_asset}}</label>
                            <p><router-link tag="a" style="display: inline;" v-bind:to="'/buildingmanager/asset-detail?asset=' + selectedAction.id_asset">{{selectedAction.asset_name}}</router-link><br />{{selectedAction.address}} {{selectedAction.house_number}}, {{profile.incident_detail.floor_name}} {{selectedAction.floor_name}} <br /><router-link tag="a" style="display: inline;" v-bind:to="'/buildingmanager/location-detail?id_place=' + selectedAction.id_place">{{selectedAction.place_name}}</router-link></p>
                        </div>
                        <div class="col-sm-6">
                            <label>{{profile.actionable.latest_recording_date}}</label>
                            <p>{{selectedAction.latestDate}}</p>
                        </div>
                    </div>
                    <div class="row" v-if="selectedAction.completedDate">
                        <div class="col-sm-6">
                            <label>{{profile.actionable.recording_date}}</label>
                            <p>{{ selectedAction.completedDate }}</p>
                        </div>  
                        <div class="col-sm-6">
                            <label>{{profile.actionable.recording_time}}</label>
                            <p>{{ selectedAction.completedTime }}</p>
                        </div>          
                    </div>
                    <p class="invoice-toggler active">{{profile.actionable.actionable}}: <b>{{selectedAction.planned_action_name}} <span v-if="selectedAction.origin === 'mechanic'" class="role-label role-label--mechanic">{{profile.incident_detail.mechanic_comment}}</span></b> <span v-if="selectedAction.id_incident_created" style="border: 1px solid #0867b3; color: #0867b3; border-radius: 3px; font-weight: 500; padding: 3px; font-size: 12px;">{{profile.actionable.incident_created}}</span></p>
                    <div class="invoice-info" v-if="actionInfoToggled">
                        <h2 style="margin-bottom: 10px;">{{ profile.accountPage.account.general }}</h2>
                        <div class="row">
                            <div class="col-sm-6" v-if="selectedAction.status === 'completed' || selectedAction.status === 'archived'">
                                <label>{{profile.actionable.allover_state}}</label>
                                <p v-if="selectedAction.fields.state === 'Slecht'" data-testid="selectedActionState" class="red">{{selectedAction.fields.state}}</p>
                                <p v-if="selectedAction.fields.state === 'Matig'" data-testid="selectedActionState" class="orange">{{selectedAction.fields.state}}</p>
                                <p v-if="selectedAction.fields.state === 'Goed'" data-testid="selectedActionState" class="green">{{selectedAction.fields.state}}</p>
                                <p v-if="selectedAction.fields.state === 'Uitstekend'" data-testid="selectedActionState" class="blue">{{selectedAction.fields.state}}</p>
                                <p v-if="!selectedAction.fields.state" class="gray" data-testid="selectedActionState" >{{profile.actionable.none}}</p>                                        
                            </div>
                            <div class="col-sm-6" v-if="selectedAction.fields.remarks">
                                <label>{{profile.owners.ownertable.description}}</label>
                                <p data-testid="generalRemarks"> {{selectedAction.fields.remarks}}</p>
                            </div>
                        </div>
                        <h2 style="margin-bottom: 10px;" v-if="selectedAction.fields.work || selectedAction.fields.frequency || selectedAction.fields.firstActionDate">{{ profile.actionable.advice_repeating }}</h2>
                        <div class="row">
                            <div class="col-sm-6" v-if="selectedAction.fields.work">
                                <label>{{profile.actionable.work}}</label>
                                <p data-testid="repeatingWorkText">{{selectedAction.fields.work}}</p>
                            </div>
                            <div class="col-sm-6" v-if="selectedAction.fields.frequency">
                                <label>{{profile.actionable.frequency}}</label>
                                <p data-testid="repeatingWorkFrequency">{{selectedAction.fields.frequency}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6" v-if="selectedAction.firstActionDate">
                                <label>{{profile.actionable.suggestion_first_action}}</label>
                                <p data-testid="repeatingWorkFirstDate" style="text-transform: lowercase;">{{selectedAction.firstActionDate}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="selectedAction.media.length || selectedAction.movs.length">
                <div class="col">
                    <div class="incident-media" style="margin-top: 20px;">
                        <label>{{profile.incident_detail.imagery}} <i class="fas fa-info-circle" style="cursor: pointer;"></i></label>
                        <div class="media-wrapper recordings">
                            <div class="item-wrapper" v-for="(item, index) in selectedAction.media" :key="index">
                                <div class="media media--small" data-testid="showMediaItem" :class="{active: item.popover, video: item.content_type === 'video/mp4'}" v-tooltip.left="item.file_upload_description">
                                    <img :src="item.file_url" @click="openPopover(item)" v-if="item.content_type === 'image/jpeg' || item.content_type === null && !item.i_frame_code" />
                                    <div class="iframe_overlay" v-if="item.i_frame_code" @click="openPopover(item)" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 20;"></div>
                                    <div v-html="item.i_frame_code" v-if="item.i_frame_code" style="margin-top: -35px;"></div>
                                    <div class="video_overlay" @click="openPopover(item)" v-if="item.content_type === 'video/mp4'"><i class="fas fa-play"></i></div>
                                    <video v-if="item.content_type === 'video/mp4'" @click="openPopover(item)">
                                        <source :src="item.file_url" type="video/mp4">
                                    </video>
                                    <span class="label" v-if="profile.incident_detail[item.type]">{{profile.incident_detail[item.type]}}</span>
                                    <span class="remove" data-testid="removeMediaItem" @click="selectFileToRemove(item)" style="cursor: pointer;"><i class="far fa-trash-alt"></i></span>
                                    <div class="media-pop-over">
                                        <span class="close-popover" @click="closePopover(selectedAction)"><i class="fa fa-times"></i></span>
                                        <span class="label" v-if="profile.incident_detail[item.type]">{{profile.incident_detail[item.type]}}</span>
                                        <img data-testid="mediaImageOverlay" :src="item.file_url" v-if="item.content_type === 'image/jpeg' || item.content_type === null && !item.i_frame_code" :class="{rotate_2: item.rotate === 2, rotate_3: item.rotate === 3, rotate_4: item.rotate === 4}" />
                                        <div v-html="item.i_frame_code" v-if="item.i_frame_code" style="margin-top: 40px;"></div>
                                        <video v-if="item.content_type === 'video/mp4'" controls><source :src="item.file_url" type="video/mp4"></video>
                                        <p v-if="item.description">{{item.description}}</p>
                                        <span class="media-control left" v-if="index !== 0" @click="selectMedia('prev', index, selectedAction)"><i class="fa fa-chevron-left"></i></span>
                                        <span class="media-control right" v-if="index + 1 !== selectedAction.media.length" @click="selectMedia('next', index, selectedAction)"><i class="fa fa-chevron-right"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div class="media video" v-for="item in selectedAction.movs" :key="item.id_file_upload">
                                <span class="label" v-if="profile.incident_detail[item.type]">{{profile.incident_detail[item.type]}}</span>
                                <span class="placeholder"><i class="fas fa-file-video"></i></span>
                                <a :href="item.file_url" class="video_overlay download" v-if="item.content_type === 'video/quicktime'"></a>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="selectedAction.status !== 'planned' && selectedAction.status !== 'started'">
                <div class="col">
                    <span class="button button--icon" data-testid="archiveActionButton" style="float: right; margin: 20px 0 0 20px;" @click="archiveAction(selectedAction)" v-if="selectedAction.status !== 'archived'"><i class="fas fa-archive"></i> {{ profile.actionable.archive}}</span>
                    <span class="button button--icon disabled" v-tooltip.right="profile.actionable.activate_repeating" style="float: right; margin: 20px 0 0 20px;" @click="newRepeatingActionModalOpen = true" v-if="!selectedAction.recurring_job && !repeatingModule"><i class="fa-solid fa-repeat"></i> {{ profile.actionable.repeating_action}} *</span>
                    <span class="button button--icon" data-testid="makeRepeatingActionButton" style="float: right; margin: 20px 0 0 20px;" @click="newRepeatingActionModalOpen = true" v-if="!selectedAction.recurring_job && repeatingModule"><i class="fa-solid fa-repeat"></i> {{ profile.actionable.repeating_action}}</span>
                    <span class="button button--icon" data-testid="makeNewIncidentButton" style="float: right; margin: 20px 0 0 0;" @click="newIncidentModalOpen = true" v-if="!selectedAction.id_incident_created && selectedAction.qr_code"><i class="fa-solid fa-plus"></i> {{ profile.incident_detail.incident_copy_ok_andnewtab}}</span>
                </div>
            </div>
            <div class="row" v-if="!selectedAction.recurring_job && !repeatingModule">
                <div class="col">
                    <p style="margin: 20px 0 0 0; float: right; font-size: 13px; color: #979797;">* {{ profile.accountPage.account.autodispatch_feature }}</p>
                </div>
            </div>
        </div>
        <NewRepeatingActionModal v-if="newRepeatingActionModalOpen" :selectedAction="selectedAction" @close="newRepeatingActionModalOpen = false" @success="getAssets()" />
        <NewIncidentModal v-if="newIncidentModalOpen" :recording="selectedAction" @close="newIncidentModalOpen = false" @success="getAssets()" />
        <RemoveRecordingFileModal v-if="removeFileModalOpen" @close="removeFileModalOpen = false" @success="spliceMedia(file)" :file="file" :actionListId="selectedAction.id_planned_action_list" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import moment from 'moment';
import { mapState } from 'vuex';
import debounce from 'lodash/debounce';
import NewRepeatingActionModal from "@/components/modals/recordings/NewRepeatingActionModal.vue";
import NewIncidentModal from "@/components/modals/recordings/CreateIncidentModal.vue";
import RemoveRecordingFileModal from "@/components/mechanic/modals/RemoveRecordingFileModal.vue";
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import orderBy from 'lodash/orderBy';
import {Dutch} from 'flatpickr/dist/l10n/nl.js';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {
    mixins: [setLocale],
    components: {
        NewRepeatingActionModal,
        NewIncidentModal,
        flatPickr,
        RemoveRecordingFileModal
    },
    data() {
        return {
            profile: {},
            actionListsCompleted: [],
            searchTerm: '',
            searchActive: false,
            selectedAction: {},
            actionInfoToggled: true,
            media: [],
            movs: [],
            showArchived: false,
            newRepeatingActionModalOpen: false,
            newIncidentModalOpen: false,
            switchType: 'new',
            addressDropdownToggled: false,
            assetsList: [],
            tempAssetsList: [],
            assetsNoResults: false,
            addressList: [],
            selectedAddress: '',
            housenumberDropdownToggled: false,
            housenumberList: [],
            selectedHouseNumber: '',
            listForHousenumbers: [],
            selectedState: '',
            stateDropdownToggled: false,
            assetToScroll: null,
            dateFilter: '',
            dateEpoch: 0,
            flatPickrDateConfig: {
                altInput: true,
                altFormat: "F j, Y",
                dateFormat: "Y-m-d"
            },
            removeFileModalOpen: false,
        }
    },
    computed: mapState(['repeatingModule']),
    created() {
       this.getAssets();
       if (this.locale === 'nl') {
            this.flatPickrDateConfig.locale = Dutch;
            this.flatPickrDateConfig.altFormat = "j F, Y";
        }
    },
    methods: {
        switchTab(type) {
            this.switchType = type;
            this.selectedHouseNumber = '';
            this.selectedAddress = '';
            this.searchTerm = '';
            this.listForHousenumbers = [];
            this.searchActive = false;
            this.selectedState = '';
            this.dateFilter = '';
            this.getAssets();
        },
        search: debounce(function (e) {
            if (this.selectedState || this.selectedAddress || this.selectedHouseNumber || this.dateFilter) {
                this.selectedState = '';
                this.selectedAddress = '';
                this.selectedHouseNumber = '';
                this.dateFilter = '';
                this.getAssets();
            }
            this.tempAssetsList = [];
            let searchTerm = this.searchTerm.toLowerCase();
            if (searchTerm.length > 2) {
                this.searchActive = true;
            }
            if (searchTerm.length < 2 && this.searchActive)  {
                this.searchActive = false;
            }
            if (e) {
                if (e.keyCode === 27) {
                    this.searchActive = false;
                    this.searchTerm = '';
                    this.getAssets();
                }
            }   
            this.selectedState = '';
            this.selectedAddress = '';
            this.selectedHouseNumber = '';
            if (this.searchActive) {
                for (var i = 0; i < this.assetsList.length; i++) {
                    let asset = this.assetsList[i];
                    let assetName = asset.asset_name.toLowerCase();
                    let addressName = asset.address.toLowerCase();
                    let placeName = asset.place_name.toLowerCase();
                    if (assetName.includes(searchTerm) || addressName.includes(searchTerm) || placeName.includes(searchTerm)) {
                        this.tempAssetsList.push(asset);
                    }
                    if (!this.tempAssetsList.length) {
                        this.assetsNoResults = true;
                    }
                    else {
                        this.assetsNoResults = false;
                    }
                }
            }    
        }, 500),
        clearSearch() {
            this.searchActive = false;
            this.searchTerm = '';
            this.selectedHouseNumber = '';
            this.selectedAddress = '';
            this.tempAssetsList = [];
            this.getAssets();
        },
        getAssets: debounce(function (e) {
            this._getAssets(e);
        }, 500),
        _getAssets(e) {
            this.newIncidentModalOpen = false;
            if (!e) {
                this.selectedAction = {};
            }
            this.addressList = [];
            this.housenumberList = [];
            let typeParam  = ''
            let dateParam = '';
            if (this.switchType === 'archived') {
                typeParam = '?archived=true';
            }
            else {
                typeParam = '?archived=false';
            }
            if (this.dateFilter) {
                dateParam = '&filter_completed_date_planned_action=' + this.dateEpoch;
            }
            let self = this;
            fetch('/api/v1/plannedactionlist/niceassets' + typeParam + dateParam)
            .then(response => { response.json().then(
                function(data) {
                    for (var i = 0; i < data.length; i++) {
                        let asset = data[i];
                        asset.active = false;  
                        asset.recordings = [];     
                        asset.generalStateBad = [];
                        asset.generalStateModerate = [];
                        asset.generalStateGood = [];
                        asset.generalStateExcellent = [];     
                        asset.generalStateNone = [];     
                        let locale = localStorage.getItem('S1-locale');
                        if (locale === 'nl') {
                            if (asset.latest_possible) {
                                asset.latestDate  = moment(asset.latest_possible).locale('nl').format('D MMMM YYYY');
                            }
                            if (asset.completed_date) {
                                asset.completedDate  = moment(asset.completed_date).locale('nl').format('D MMMM YYYY');
                            }
                        }
                        if (locale === 'en') {
                            if (asset.latest_possible) {
                                asset.latestDate  = moment(asset.latest_possible).locale('en').format('D MMMM YYYY');
                            }
                        }
                    }
                    self.assetsList = data;
                    for (var i = 0; i < self.assetsList.length; i++) {
                        let completedList = self.assetsList[i];
                        if (completedList.address) {
                            self.addressList.push(completedList.address);
                        }
                        for (var j = 0; j < completedList.statusses.length; j++) {
                            let generalState = completedList.statusses[j];
                            if (generalState === 'Slecht') {
                                completedList.generalStateBad.push(generalState);              
                            }
                            if (generalState === 'Matig') {
                                completedList.generalStateModerate.push(generalState);              
                            }
                            if (generalState === 'Goed') {
                                completedList.generalStateGood.push(generalState);              
                            }
                            if (generalState === 'Uitstekend') {
                                completedList.generalStateExcellent.push(generalState);              
                            }
                            if (generalState === null) {
                                completedList.generalStateNone.push(generalState);              
                            }
                        }
                    }
                    self.addressList = uniqBy(self.addressList);
                    self.addressList = sortBy(self.addressList);
                    self.assetsList = sortBy(self.assetsList, 'asset_name');
                });
            }); 
        },
        getRecordings(asset) {
            asset.active = !asset.active;
            if (asset.active) {
                for (var k = 0; k < asset.planned_action_lists.length; k++) {
                    let list = asset.planned_action_lists[k];
                    let archived = this.switchType === 'archived' ? true : false;
                    let dateParam = '';
                    if (this.dateFilter) {
                        dateParam = '&filter_completed_date_planned_action=' + this.dateEpoch;
                    }
                    fetch('/api/v1/plannedactionlist/nicewithactions?filter_id_planned_action_list=' + list + '&archived=' + archived + dateParam)
                    .then(response => { response.json().then(
                        function(data) {
                            let locale = localStorage.getItem('S1-locale');
                            for (var i = 0; i < data.length; i++) {
                                let list = data[i];
                                list.generalStateBad = [];
                                list.generalStateModerate = [];
                                list.generalStateGood = [];
                                list.generalStateExcellent = [];  
                                list.generalStateNone = []; 
                                for (var j = 0; j < list.planned_actions.length; j++) {
                                    let plannedAction = list.planned_actions[j];
                                    plannedAction.fields = {};
                                    plannedAction.media = [];
                                    plannedAction.movs = [];
                                    plannedAction.active = false;
                                    if (locale === 'nl') {
                                        if (plannedAction.completed_date) {
                                            plannedAction.completedDate = moment(plannedAction.completed_date).locale('nl').format('D MMMM YYYY');
                                            plannedAction.completedTime = moment(plannedAction.completed_date).format('H:mm:ss');
                                        }
                                    }
                                    if (locale === 'en') {
                                        if (plannedAction.completed_date) {
                                            plannedAction.completedDate = moment(plannedAction.completed_date).locale('en').format('D MMMM YYYY');
                                            plannedAction.completedTime  = moment(plannedAction.completed_date).format('H:mm:ss');
                                        }
                                    }
                                    for (var k = 0; k < plannedAction.planned_action_fields.length; k++) {
                                        let field = plannedAction.planned_action_fields[k];
                                        if (field.name === 'Algehele staat' && field.value === 'Slecht') {
                                            list.generalStateBad.push(field.value);
                                        }
                                        if (field.name === 'Algehele staat' && field.value === 'Matig') {
                                            list.generalStateModerate.push(field.value);
                                        }
                                        if (field.name === 'Algehele staat' && field.value === 'Goed') {
                                            list.generalStateGood.push(field.value);
                                        }
                                        if (field.name === 'Algehele staat' && field.value === 'Uitstekend') {
                                            list.generalStateExcellent.push(field.value);
                                        }  
                                        if (field.name === 'Algehele staat' && field.value === null && plannedAction.status === 'completed') {
                                            list.generalStateNone.push(field.value);
                                        }                         
                                    }  
                                }
                                list.active = false;
                                list.completedTime  = moment(list.completed_date).format('H:mm:ss');
                                if (locale === 'nl') {
                                    if (list.latest_possible) {
                                        list.latestDate  = moment(list.latest_possible).locale('nl').format('D MMMM YYYY');
                                    }
                                    if (list.completed_date) {
                                        list.completedDate  = moment(list.completed_date).locale('nl').format('D MMMM YYYY');
                                    }
                                }
                                if (locale === 'en') {
                                    if (list.latest_possible) {
                                        list.latestDate  = moment(list.latest_possible).locale('en').format('D MMMM YYYY');
                                    }
                                    if (list.completed_date) {
                                        list.completedDate  = moment(list.completed_date).locale('en').format('D MMMM YYYY');
                                    }
                                }
                                asset.recordings.push(list);
                            }    
                            asset.recordings = orderBy(asset.recordings, ['status', 'completed_date'], ['asc', 'desc']);
                        });
                    });       
                }
            }
            else {
                asset.recordings = [];
            }
        },
        showActionables(asset, recording) {
            this.assetToScroll = asset.id_asset;
            asset.active = true;
            recording.active = !recording.active;
            for (var i = 0; i < recording.planned_actions.length; i++) {
                let plannedAction = recording.planned_actions[i];
                for (var j = 0; j < plannedAction.planned_action_fields.length; j++) {
                    let field = plannedAction.planned_action_fields[j];
                    if (field.name === 'Algehele staat') {
                        plannedAction.fields.state = field.value;
                    }
                    if (field.name === 'Werk') {
                        plannedAction.fields.work = field.value;
                    }
                    if (field.name === 'Repeterend werk frequentie') {
                        plannedAction.fields.frequency = field.value;
                    }
                    if (field.name === 'Voorstel eerste actie') {
                        plannedAction.fields.firstAction = Number(field.value);     
                    }
                    if (field.name === 'Opmerkingen') {
                        plannedAction.fields.remarks = field.value;
                    }
                }  
                this.getMedia(plannedAction);  
            }
        },
        getMedia(plannedAction) {
            fetch('/api/v1/plannedaction?id_planned_action=' + plannedAction.id_planned_action)
            .then(response => { response.json().then(
                function(data) {
                    for (var k = 0; k < data.planned_action_fields.length; k++) {
                        let field = data.planned_action_fields[k];
                        if (field.actionable_field_type === 'media' && field.content_type !== 'video/quicktime' && field.file_url) {
                            field.toggledAdd = false;
                            field.popover = false;
                            field.order = 1;
                            field.selected = false;
                            plannedAction.media.push(field);
                        }
                        if (field.content_type === 'video/quicktime') {
                            plannedAction.movs.push(field);
                        }
                    }
                });
            });
        },
        spliceMedia(file) {
            this.removeFileModalOpen = false;
            if (file.content_type === 'image/jpeg') {
                for (var i = 0; i < this.selectedAction.media.length; i++) {
                    if (this.selectedAction.media[i].id_planned_action_field === file.id_planned_action_field) {
                        this.selectedAction.media.splice(i, 1);
                    }
                }
            }
            else {
                for (var j = 0; j < this.selectedAction.movs.length; j++) {
                    if (this.selectedAction.movs[j].id_planned_action_field === file.id_planned_action_field) {
                        this.selectedAction.movs.splice(j, 1);
                    }
                }
            }
        }, 
        getFrequencyOptions() {
            let self = this;
            fetch('/api/v1/recurringaction/frequencyoptions')
            .then(response => { response.json().then(
                function(data) {
                    self.selectedAction.frequency_options = data;
                });
            });
        },
        selectMedia(type, index, selectedAction) {
            if (type === 'prev') {
                index = index - 1;
                for (var i = 0; i < selectedAction.media.length; i++) {
                    selectedAction.media[i].popover = false;
                    selectedAction.media[index].popover = true;
                }
            }
            if (type === 'next') {
                index = index + 1;
                for (var j = 0; j < selectedAction.media.length; j++) {
                    selectedAction.media[j].popover = false;
                    selectedAction.media[index].popover = true;
                }
            }
        },
        openPopover(item) {
            item.popover = true;
            document.querySelector('body').classList.add('media-open');
        },
        closePopover(selectedAction) {
            document.querySelector('body').classList.remove('media-open');
            for (var i = 0; i < selectedAction.media.length; i++) {
                selectedAction.media[i].popover = false;
            }
        },
        selectAction(recording, actionable) {
            this.selectedAction = actionable;
            this.selectedAction.id_asset = recording.id_asset;
            this.selectedAction.latestDate = recording.latestDate;
            this.selectedAction.asset_name = recording.asset_name;
            this.selectedAction.address = recording.address;
            this.selectedAction.house_number = recording.house_number;
            this.selectedAction.place_name = recording.place_name;
            this.selectedAction.floor_name = recording.floor_name;
            this.selectedAction.qr_code = recording.qr_code;
            let locale = localStorage.getItem('S1-locale');
            if (locale === 'nl') {
                if (this.selectedAction.fields.firstAction) {
                    this.selectedAction.firstActionDate  = moment(this.selectedAction.fields.firstAction).locale('nl').format('D MMMM YYYY');
                }
                else {
                    this.selectedAction.fields.firstAction  = moment(this.selectedAction.fields.firstAction).locale('en').format('D MMMM YYYY');
                }
            }
            this.getFrequencyOptions();
        },
        archiveAction(actionable) {
            for (let i = 0; i < this.assetsList.length; i++) {
                let asset = this.assetsList[i];
                if (asset.id_asset === actionable.id_asset) {
                    for (let j = 0; j < asset.recordings.length; j++) {
                    let recording = asset.recordings[j];
                    for (let k = 0; k < recording.planned_actions.length; k++) {
                        let action = recording.planned_actions[k];
                        if (actionable.id_planned_action === action.id_planned_action) {
                            const data = new URLSearchParams();
                            data.append('id_planned_action', action.id_planned_action);
                            let self = this;
                            fetch('/api/v1/plannedaction/setarchived', {
                                method: 'post',
                                body: data
                            })
                            .then((resp) => resp.json())
                            .then((data) => {
                                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                                recording.planned_actions.splice(k, 1);
                                 // Remove the status associated with the archived action from the asset's statusses array
                                const statusIndex = asset.statusses.indexOf(action.fields.state);
                                if (statusIndex !== -1) {
                                    asset.statusses.splice(statusIndex, 1);
                                }
                                if (action.fields.state === 'Slecht') {
                                    asset.generalStateBad.splice(asset.generalStateBad.indexOf(action.fields.state), 1);
                                    recording.generalStateBad.splice(recording.generalStateBad.indexOf(action.fields.state), 1);
                                }
                                if (action.fields.state === 'Matig') {
                                    asset.generalStateModerate.splice(asset.generalStateModerate.indexOf(action.fields.state), 1);
                                    recording.generalStateModerate.splice(recording.generalStateModerate.indexOf(action.fields.state), 1);
                                }
                                if (action.fields.state === 'Goed') {
                                    asset.generalStateGood.splice(asset.generalStateGood.indexOf(action.fields.state), 1);
                                    recording.generalStateGood.splice(recording.generalStateGood.indexOf(action.fields.state), 1);
                                }
                                if (action.fields.state === 'Uitstekend') {
                                    asset.generalStateExcellent.splice(asset.generalStateExcellent.indexOf(action.fields.state), 1);
                                    recording.generalStateExcellent.splice(recording.generalStateExcellent.indexOf(action.fields.state), 1);
                                }
                                if (action.fields.state === null) {
                                    asset.generalStateNone.splice(asset.generalStateNone.indexOf(action.fields.state), 1);
                                    recording.generalStateNone.splice(recording.generalStateNone.indexOf(action.fields.state), 1);
                                }
                                // Store the index of the recording to scroll to
                                self.assetToScroll = asset.id_asset;
                                // Check if all recordings have no planned actions
                                const allRecordingsEmpty = asset.recordings.every(recording => recording.planned_actions.length === 0);
                                if (allRecordingsEmpty) {
                                    asset.planned_action_lists = [];
                                }
                                self.back();
                            })
                            .catch((error) => {
                                console.error('Error archiving planned action:', error);
                            });
                            return; 
                            }
                        }
                    }
                }
            }
        },
        toggleDropdown(type) {
            if (type !== 'housenumber' && (this.selectedState || this.selectedAddress || this.selectedHouseNumber || this.dateFilter || this.searchActive)) {
                this.tempAssetsList = [];
                this.searchTerm = '';
                this.searchActive = '';
                this.selectedState = '';
                this.selectedAddress = '';
                this.selectedHouseNumber = '';
                this.dateFilter = '';
                this.getAssets();
            }
            if (type === 'state') {
                this.stateDropdownToggled = !this.stateDropdownToggled;
            }
            if (type === 'address') {
                if (!this.selectedAddress) {
                    this.addressDropdownToggled = !this.addressDropdownToggled;
                }
            }
            if (type === 'housenumber') {
                if (!this.selectedHouseNumber) {
                    this.housenumberDropdownToggled = !this.housenumberDropdownToggled;
                }
            }
            if (type === 'date') {
                this.stateDropdownToggled = false;
                this.addressDropdownToggled = false;
                this.housenumberDropdownToggled = false;
            }
        },
        removeSelectedAddress() {
            this.selectedHouseNumber = '';
            this.selectedAddress = '';
            this.getAssets();
        },
        selectAddress(address) {
            this.tempAssetsList = [];
            this.selectedAddress = address;
            this.addressDropdownToggled = false;
            this.selectedState = '';
            for (var i = 0; i < this.assetsList.length; i++) {
                let list = this.assetsList[i];
                if (list.address === address) {
                    this.tempAssetsList.push(list);
                    if (list.house_number) {
                        this.housenumberList.push(list.house_number);
                    }
                } 
                this.housenumberList = uniqBy(this.housenumberList);
                this.housenumberList = sortBy(this.housenumberList);
            }
            this.assetsList = this.tempAssetsList;
            this.listForHousenumbers = this.tempAssetsList;
        },
        removeSelectedHousenumber() {
            this.selectedHouseNumber = '';
            this.assetsList = this.listForHousenumbers; 
        },
        selectHousenumber(housenumber) {
            this.selectedHouseNumber = housenumber;
            this.housenumberDropdownToggled = false;
            let tempList = [];
            this.selectedState = '';
            for (var i = 0; i < this.assetsList.length; i++) {
                let list = this.assetsList[i];
                if (list.house_number === housenumber) {
                    tempList.push(list);    
                } 
            }
            this.assetsList = tempList;
        },
        selectState(state) {
            this.stateDropdownToggled = false;
            this.selectedAddress = '';
            this.selectedHouseNumber = '';
            if (state === 'Slecht') {
                this.selectedState = this.profile.actionable.bad;
                for (let i = this.assetsList.length - 1; i >= 0; i--) {
                    let list = this.assetsList[i];
                    if (!list.generalStateBad.length) {
                        this.assetsList.splice(i, 1);
                    } 
                }
            }
            if (state === 'Matig') {
                this.selectedState = this.profile.actionable.moderate;
                for (let i = this.assetsList.length - 1; i >= 0; i--) {
                    let list = this.assetsList[i];
                    if (!list.generalStateModerate.length) {
                        this.assetsList.splice(i, 1);
                    } 
                }
            }
            if (state === 'Goed') {
                this.selectedState = this.profile.actionable.good;
                for (let i = this.assetsList.length - 1; i >= 0; i--) {
                    let list = this.assetsList[i];
                    if (!list.generalStateGood.length) {
                        this.assetsList.splice(i, 1);
                    } 
                }
            }
            if (state === 'Uitstekend') {
                this.selectedState = this.profile.actionable.excellent;
                for (let i = this.assetsList.length - 1; i >= 0; i--) {
                    let list = this.assetsList[i];
                    if (!list.generalStateExcellent.length) {
                        this.assetsList.splice(i, 1);
                    } 
                }
            }
            if (state === 'Geen staat') {
                this.selectedState = this.profile.actionable.none;
                for (let i = this.assetsList.length - 1; i >= 0; i--) {
                    let list = this.assetsList[i];
                    if (!list.generalStateNone.length) {
                        this.assetsList.splice(i, 1);
                    } 
                }
            }
            this.assetsList.reverse();
        },  
        removeSelectedState() {
            this.selectedState = '';
            this.getAssets();
        },
        selectDate() {
            this.selectedState = '';
            this.selectedAddress = '';
            this.selectedHouseNumber = '';
            let date = new Date(this.dateFilter);
            this.dateEpoch = date.getTime();
            this.getAssets();
        },  
        removeSelectedDate() {
            this.dateFilter = '';
            this.dateEpoch = 0;
            this.getAssets();
        },
        selectFileToRemove(item) {
            this.removeFileModalOpen = true;
            this.file = item;
        },
        back() {
            this.selectedAction = {};
            setTimeout(function () {
                if (this.assetToScroll !== null) {
                    const assetElement  = this.$el.querySelector('#asset-' + this.assetToScroll);
                    if (assetElement) {
                        assetElement .scrollIntoView({ behavior: 'smooth' });
                    }
                }
            }.bind(this), 300);
        }
    }
}
</script>
