<template>
    <div>
        <div class="main-content">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <a class="back-link" @click="$router.go(-1)"><i class="fas fa-chevron-left"></i>{{profile.nav.back_link}}</a>
                    </div>
                </div>
                <div class="block">
                    <div class="profile-card asset" style="border-right: 0; width: 80%;">
                        <div class="row">
                            <div class="col-xs-12 col-sm-9">
                                <div class="profile-pic-wrapper" v-if="asset.file_upload && asset.file_upload.length">
                                    <div class="profile-pic" style="overflow: hidden;" :style="{ backgroundImage: 'url(' + thumbnail + ')', backgroundSize: 'cover', backgroundPosition: 'center' }"></div>
                                </div>
                                <h4 style="text-transform: capitalize;">{{profile.assets.asset}}</h4>
                                <div class="inactive-label" v-if="!asset.qr_code">
                                    <i class="fas fa-qrcode"></i> {{profile.assets.no_sticker}}
                                </div>
                                <span class="active-label" data-testid="assetActiveText" v-if="!asset.hidden && asset.qr_code">{{profile.assets.assettable.active}}</span>
                                <span class="inactive-label" data-testid="assetInactiveText" v-if="asset.hidden">{{profile.assets.inactive}}</span>
                                <h2>{{asset.name}}</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-9">
                        <div class="block block--location_detail">
                            <div>
                                <section class="incident-head">
                                    <h1 data-testid="assetEditTitle">{{profile.accountPage.account.general}}</h1>
                                </section>
                                <section class="location-contents">
                                    <div class="location-info" style="margin-top: 20px;">
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <label>{{profile.assets.location_name}}</label>
                                                <p data-testid="assetLocationNameText" >{{placeInfo.name}}</p>
                                            </div>
                                            <div class="col-sm-6">
                                                <label>{{profile.assets.assetmodal.id_asset}}</label>
                                                <p data-testid="assetIdText">{{asset.id_asset}}</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <label>{{profile.assets.objectname}}</label>
                                                <p data-testid="assetAssetNameText">{{asset.name}}</p>
                                            </div>
                                            <div class="col-sm-6">
                                                <label>{{profile.assets.assetmodal.key_number}}</label>
                                                <p data-testid="assetKeyText">{{asset.key_number}}</p>
                                            </div>
                                        </div>
                                        <div class="row" v-if="canChangeExternalIds">
                                            <div class="col-sm-6">
                                                <label>{{profile.assets.assetmodal.external_id}}</label>
                                                <p data-testid="assetKeyText" v-if="asset.id_external !== 'null'">{{asset.id_external}}</p>
                                            </div>
                                        </div>
                                        <hr style="margin-top: -10px;" v-if="asset.id_asset_type && asset.id_asset_type !== -1"/>
                                        <div class="row" v-if="asset.id_asset_type && asset.id_asset_type !== -1">
                                            <div class="col-sm-6">
                                                <label>{{profile.assets.assetmodal.assettype}}</label>
                                                <p data-testid="assetTypeText">{{assetType.display_name}}</p>
                                            </div>
                                        </div>
                                        <div class="row" v-if="assetType.extra_description_a || assetType.extra_description_b">
                                            <div class="col-sm-6" v-if="assetType.extra_description_a">
                                                <label>{{assetType.extra_description_a}}</label>
                                                <p data-testid="assetTypeAText">{{asset.extra_description_a}}</p>
                                            </div>
                                            <div class="col-sm-6" v-if="assetType.extra_description_b">
                                                <label>{{assetType.extra_description_b}}</label>
                                                <p data-testid="assetTypeBText">{{asset.extra_description_b}}</p>
                                            </div>
                                        </div>
                                        <div class="row" v-if="assetType.extra_description_c || assetType.extra_description_d">
                                            <div class="col-sm-6" v-if="assetType.extra_description_c">
                                                <label>{{assetType.extra_description_c}}</label>
                                                <p>{{asset.extra_description_c}}</p>
                                            </div>
                                            <div class="col-sm-6" v-if="assetType.extra_description_d">
                                                <label>{{assetType.extra_description_d}}</label>
                                                <p>{{asset.extra_description_d}}</p>
                                            </div>
                                        </div>
                                        <div class="row" v-if="assetType.extra_description_e || assetType.extra_description_f">
                                            <div class="col-sm-6" v-if="assetType.extra_description_e">
                                                <label>{{assetType.extra_description_e}}</label>
                                                <p>{{asset.extra_description_e}}</p>
                                            </div>
                                            <div class="col-sm-6" v-if="assetType.extra_description_f">
                                                <label>{{assetType.extra_description_f}}</label>
                                                <p>{{asset.extra_description_f}}</p>
                                            </div>
                                        </div>
                                        <div class="row" v-if="assetType.extra_description_g || assetType.extra_description_h">
                                            <div class="col-sm-6" v-if="assetType.extra_description_g">
                                                <label>{{assetType.extra_description_g}}</label>
                                                <p>{{asset.extra_description_g}}</p>
                                            </div>
                                            <div class="col-sm-6" v-if="assetType.extra_description_h">
                                                <label>{{assetType.extra_description_h}}</label>
                                                <p>{{asset.extra_description_h}}</p>
                                            </div>
                                        </div>
                                        <div class="row" v-if="assetType.extra_description_i || assetType.extra_description_j">
                                            <div class="col-sm-6" v-if="assetType.extra_description_i">
                                                <label>{{assetType.extra_description_i}}</label>
                                                <p>{{asset.extra_description_i}}</p>
                                            </div>
                                            <div class="col-sm-6" v-if="assetType.extra_description_j">
                                                <label>{{assetType.extra_description_j}}</label>
                                                <p>{{asset.extra_description_j}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </section>                            
                            </div>
                        </div>
                        <Media :media="media" :movs="movs" :asset="asset" :admin="false" @getAsset="getAsset()" v-if="movs.length || media.length" /> 
                    </div>
                    <div class="col-lg-3">
                        <div class="block block--incident_detail location">
                            <section class="incident-head" style="margin-bottom: 20px;">
                                <h1>{{profile.assets.assetmodal.place}}</h1>
                            </section>
                            <div class="row">
                                <div class="col">
                                    <a :href="mapsUrl" target="_blank">
                                        <img :src="mapsApiUrl" id="address-map" />
                                    </a>
                                </div>
                            </div>
                            <p style="margin-bottom: 0;">
                                <span v-if="twinq" style="display: block;">{{placeInfo.name}}</span>
                                <router-link tag="a" v-bind:to="'/buildingmanager/location-detail?id_place=' + asset.id_place" v-else>{{placeInfo.name}}</router-link>
                                {{placeInfo.address}} {{placeInfo.house_number}}<br />
                                {{placeInfo.zipcode}} {{placeInfo.city}}<br />
                                {{profile.incident_detail.floor_name}}: {{placeInfo.floor_name}}<br />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
   </div>
</template>

<script>
import { mapState } from 'vuex';
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import moment from 'moment';
import Media from "@/components/asset/Media.vue";

export default {
    mixins: [setLocale],
    components: {
        Media
    },
    data() {
        return {
            profile: {},
            assetId: 0,
            asset: {},
            assetType: {},
            placeInfo: {},
            mapsApiUrl: '',
            mapsUrl: '',
            locationInfo: {},
            isSpace: true,
            selectedAssetId: 0,
            canChangeExternalIds: false,
            assetInfoEdit: false,
            admin: false,
            locationDropdownToggled: false,
            placeSearchTerm: '',
            noPlacesResults: false,
            selectedPlace: {},
            media: [],
            movs: [],
            totalMedia: 0,
            actionListsPlanned: [],
            actionListsStarted: [],
            actionListsCompleted: [],
            actionListsArchived: [],
            disableEdit: false,
        }
    },
    computed: mapState(['twinq', 'mapsKey']),
    created() {
        document.title = 'S1MONE | ' + this.profile.incidents.incidenttable.asset;
        let queryString = window.location.search;
        if (queryString.includes('id_incident')) {
            this.incidentId = queryString.split('=')[1];
            this.getAssetByIncident();
        }
        else {
            this.assetId = queryString.split('=')[1];
            this.getAsset();
        }
    },
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        getAsset() {
            this.connectAssetModalOpen = false;
            this.removeFileModalOpen = false;
            this.movs = [];
            this.media = [];
            let self = this;
            fetch('/api/v1/asset/byid?id_asset=' + this.assetId)
            .then(response => { response.json().then(
                function(data) {
                    if (data.file_upload.length) {
                        for (var i = 0; i < data.file_upload.length; i++) {
                            let media = data.file_upload[i];
                            if (media.content_type !== 'video/quicktime' && media.file_url) {
                                media.popover = false;
                                media.rotate = 1;
                                media.order = 1; 
                                media.selected = false;
                                let locale = localStorage.getItem('S1-locale');
                                if (locale === 'nl') {
                                    media.createdDate  = moment(media.created).locale('nl').format('D MMMM YYYY');
                                }
                                if (locale === 'en') {
                                    media.createdDate  = moment(media.created).locale('en').format('MMMM D YYYY');
                                }
                                if (data.main_image_id_file_upload && media.id_file_upload === data.main_image_id_file_upload) {
                                    self.thumbnail = media.file_url;
                                }
                                self.media.push(media);
                            }
                            if (media.content_type === 'video/quicktime') {
                                self.movs.push(media);
                            }
                        }
                        if (!data.main_image_id_file_upload && self.media.length) {
                            self.thumbnail = self.media[0].file_url;
                        }
                        self.totalMedia = self.media.length + self.movs.length;
                    }            
                    self.asset = data;
                    if (self.asset.name === null) {
                        self.asset.name = '';
                    }
                    if (self.asset.key_number === null) {
                        self.asset.key_number = '';
                    }
                    if (self.asset.extra_description_a === null) {
                        self.asset.extra_description_a = '';
                    }
                    if (self.asset.extra_description_b === null) {
                        self.asset.extra_description_b = '';
                    }
                    if (self.asset.extra_description_c === null) {
                        self.asset.extra_description_c = '';
                    }
                    if (self.asset.extra_description_d === null) {
                        self.asset.extra_description_d = '';
                    }
                    if (self.asset.extra_description_e === null) {
                        self.asset.extra_description_e = '';
                    }
                    if (self.asset.extra_description_f === null) {
                        self.asset.extra_description_f = '';
                    }
                    if (self.asset.extra_description_g === null) {
                        self.asset.extra_description_g = '';
                    }
                    if (self.asset.extra_description_h === null) {
                        self.asset.extra_description_h = '';
                    }
                    if (self.asset.extra_description_i === null) {
                        self.asset.extra_description_i = '';
                    }
                    if (self.asset.extra_description_j === null) {
                        self.asset.extra_description_j = '';
                    }
                    self.getPlace();
                    self.getAssetTypes();
                    if (self.asset.partof_id_asset) {
                        self.isSpace = false;
                    }
                    if (self.asset.id_external === 'null') {
                        self.asset.id_external = '';
                    }
                });
            });
        },
        getPlace() {
            let self = this;
            fetch('/api/v1/place/byid?id_place=' + this.asset.id_place)
            .then(response => { response.json().then(
                function(data) {
                    self.placeInfo = data;
                    self.selectedPlaceName = self.placeInfo.name;
                    self.selectedPlace.id_place = self.placeInfo.id_place;
                    // Add space in zipcode if it ends in two letters
                    if (/[a-zA-Z]{2}$/.test(self.placeInfo.zipcode)) {
                        self.placeInfo.zipcode = self.placeInfo.zipcode.substring(0, self.placeInfo.zipcode.length - 2) + ' ' + self.placeInfo.zipcode.substring(self.placeInfo.zipcode.length - 2);
                    }
                    let gmapsAddress    = self.placeInfo.address + "+" + self.placeInfo.house_number + "+"+self.placeInfo.city.replace(/ /g , "+");
                    self.mapsApiUrl     = 'https://maps.googleapis.com/maps/api/staticmap?markers='+
                                           gmapsAddress+
                                            '&zoom=16&size=250x100&key=' + self.mapsKey;
                    self.mapsUrl        = "https://www.google.com/maps/place/"+gmapsAddress;
                });
            });
        },
        getAssetByIncident() {
            let self = this;
            fetch('/api/v1/appointmentbyqrcode/idincident?id_incident=' + this.incidentId)
            .then(response => { response.json().then(
                function(data) {
                    self.asset = data.asset;
                    self.placeInfo.name = data.appointment.place_name; 
                    self.placeInfo.address = data.appointment.address;
                    self.placeInfo.house_number = data.appointment.house_number;
                    self.placeInfo.zipcode = data.appointment.zipcode;
                    self.placeInfo.zipcode = self.placeInfo.zipcode.substring(0, data.appointment.zipcode.length - 2) + ' ' + data.appointment.zipcode.substring(data.appointment.zipcode.length - 2);
                    self.placeInfo.city = data.appointment.city;
                    self.placeInfo.floor_name = data.appointment.floor_name;
                    let gmapsAddress    = self.placeInfo.address + "+" + self.placeInfo.house_number + "+"+self.placeInfo.city.replace(/ /g , "+");
                    self.mapsApiUrl     = 'https://maps.googleapis.com/maps/api/staticmap?markers='+
                                           gmapsAddress+
                                            '&zoom=16&size=250x100&key=' + self.mapsKey;
                    self.mapsUrl        = "https://www.google.com/maps/place/"+gmapsAddress;
                    if (self.asset.id_asset_type !== -1) {
                        self.getAssetTypes();
                    }
                });
            });
        },
        getAssetTypes() {
            let self = this;
            fetch('/api/v1/asset_type/byid?id_asset_type=' + this.asset.id_asset_type)
            .then(response => { response.json().then(
                function(data) {
                    self.assetType = data;
                    if (self.assetType.extra_description_a_default && !self.asset.extra_description_a) {
                        self.asset.extra_description_a = self.assetType.extra_description_a_default;
                    }
                    if (self.assetType.extra_description_b_default && !self.asset.extra_description_b) {
                        self.asset.extra_description_b = self.assetType.extra_description_b_default;
                    }
                    if (self.assetType.extra_description_c_default && !self.asset.extra_description_c) {
                        self.asset.extra_description_c = self.assetType.extra_description_c_default;
                    }
                    if (self.assetType.extra_description_d_default && !self.asset.extra_description_d) {
                        self.asset.extra_description_d = self.assetType.extra_description_d_default;
                    }
                    if (self.assetType.extra_description_e_default && !self.asset.extra_description_e) {
                        self.asset.extra_description_e = self.assetType.extra_description_e_default;
                    }
                    if (self.assetType.extra_description_f_default && !self.asset.extra_description_f) {
                        self.asset.extra_description_f = self.assetType.extra_description_f_default;
                    }
                    if (self.assetType.extra_description_g_default && !self.asset.extra_description_g) {
                        self.asset.extra_description_g = self.assetType.extra_description_g_default;
                    }
                    if (self.assetType.extra_description_h_default && !self.asset.extra_description_h) {
                        self.asset.extra_description_h = self.assetType.extra_description_h_default;
                    }
                    if (self.assetType.extra_description_i_default && !self.asset.extra_description_i) {
                        self.asset.extra_description_i = self.assetType.extra_description_i_default;
                    }
                    if (self.assetType.extra_description_j_default && !self.asset.extra_description_j) {
                        self.asset.extra_description_j = self.assetType.extra_description_j_default;
                    }
                });
            });
        },
        zoom(e){
            let offsetX;
            let offsetY;
            let x;
            let y;
            let zoomer = e.currentTarget;
            e.offsetX ? offsetX = e.offsetX : offsetX = e.touches[0].pageX
            e.offsetY ? offsetY = e.offsetY : offsetX = e.touches[0].pageX
            x = offsetX/zoomer.offsetWidth*100
            y = offsetY/zoomer.offsetHeight*100
            zoomer.style.backgroundPosition = x + '% ' + y + '%';
        },
        selectMedia(type, index) {
            if (type === 'prev') {
                index = index - 1;
                for (var i = 0; i < this.media.length; i++) {
                    this.media[i].popover = false;
                    this.media[index].popover = true;
                }
            }
            if (type === 'next') {
                index = index + 1;
                for (var j = 0; j < this.media.length; j++) {
                    this.media[j].popover = false;
                    this.media[index].popover = true;
                }
            }
        },
        openPopover(item) {
            item.popover = true;
            console.log(item);
            document.querySelector('body').classList.add('media-open');
        },
        closePopover() {
            document.querySelector('body').classList.remove('media-open');
            for (var i = 0; i < this.media.length; i++) {
                this.media[i].popover = false;
            }
        },
        rotateMedia(item) {
            item.rotate++;
            if (item.rotate === 5) {
                item.rotate = 1;
            }
        }
    }
}
</script>
