<template>
    <div>
        <div class="row">
            <div class="col" style="position: relative;">
                <h1>Uitgegeven stickers</h1>
            </div>
        </div>
        <div class="table-wrap" style="height: 600px; overflow: auto;">
            <table class="table table-striped" v-if="stickers.length">
                <thead>
                    <tr>
                        <th scope="col" @click="sortTable('amount')" :class="{active: stickerSortType === 'amount'}">Sticker aantal <i class="fas fa-sort"></i></th>
                        <th scope="col" @click="sortTable('size')" :class="{active: stickerSortType === 'size'}">Grootte <i class="fas fa-sort"></i></th>
                        <th scope="col" @click="sortTable('date')" :class="{active: stickerSortType === 'date'}">Datum <i class="fas fa-sort"></i></th>
                        <th scope="col">Pdf</th> 
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(sticker, index) in stickers" :key="index">
                        <td>{{sticker.amount}}</td>
                        <td>{{sticker.size}}</td>
                        <td>{{ formatDate(sticker.created) }}</td>
                        <td><i class="fa-solid fa-qrcode" @click="downloadStickerModalOpen = true; selectedSticker=sticker"></i></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <DownloadStickerModal v-if="downloadStickerModalOpen" :sticker="selectedSticker" v-on:close="getStickers()" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import DownloadStickerModal from '@/views/s1mone/modals/DownloadStickerModal.vue';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';

export default {
    mixins: [setLocale],
    components: {
        DownloadStickerModal
    },
    data() {
        return {
            profile: {},
            stickers: [],
            stickerSortType: '',
            sortOrder: 'asc',
            downloadStickerModalOpen: false,
            selectedSticker: {}
        }
    },
    props: ['managerID'],
    created() {
        this.getStickers();
    },
    methods: {
        getStickers() {
            this.stickers = [];
            this.selectedSticker = {};
            this.downloadStickerModalOpen = false;
            let self = this;     
            fetch('/api/v1/stickerhistory/bybuildingmanager?id_building_manager=' + self.managerID)
            .then(response => { response.json().then(
                function(data) {
                    self.stickers = data.data;
                });
            });
        },
        sortTable(sorter) {
            this.stickerSortType = sorter;
            this.sortOrder = this.sortOrder !== 'asc' ? 'asc' : 'desc';
            if (sorter === 'date') {
            this.stickers = sortBy(this.stickers, (sticker) => new Date(sticker.created));
            } else {
            this.stickers = sortBy(this.stickers, sorter);
            }
            if (this.sortOrder === 'desc') {
            this.stickers.reverse();
            }
        },
        formatDate(date) {
            const d = new Date(date);
            const day = String(d.getDate()).padStart(2, '0');
            const month = String(d.getMonth() + 1).padStart(2, '0');
            const year = d.getFullYear();
            return `${day}/${month}/${year}`;
        }
    }
}
</script>
