<template>
    <div>
        <div class="block block--incident_detail" style="overflow: visible;">
            <div class="incident-head">
                <h1 style="max-width: 100%; font-size: 18px;">{{profile.incident_detail.other_details}}</h1>
            </div>
            <section class="incident-contents">
                <div class="incident-info">
                    <label v-if="incidentInfo.incidentCategory && incidentInfo.incidentCategory.dutch_name !== 'INLOSSALDO' && incidentInfo.incidentCategory.dutch_name !== 'AMORTISATIESCHEMA' && incidentInfo.incidentCategory.dutch_name !== 'RENTETABEL'">{{profile.incident_detail.rating_comment}}</label>
                    <p v-if="incidentInfo.incidentCategory && incidentInfo.incidentCategory.dutch_name !== 'INLOSSALDO' && incidentInfo.incidentCategory.dutch_name !== 'AMORTISATIESCHEMA' && incidentInfo.incidentCategory.dutch_name !== 'RENTETABEL'">
                        <span data-testid="reporterName" style="display: block; margin-bottom: 5px;" v-if="incidentInfo.reporter_name">{{incidentInfo.reporter_name}} 
                            <span class="lang" style="margin-left: 5px; width: 18px; display: inline-block; cursor: pointer;" v-if="incidentInfo.reporter_locale">
                                <img src="@/assets/icons/flags/en.svg" v-if="incidentInfo.reporter_locale === 'en-GB'" v-tooltip.right="profile.nationalities.english">
                                <img src="@/assets/icons/flags/it.svg" v-if="incidentInfo.reporter_locale === 'it-IT'" v-tooltip.right="profile.nationalities.italian">
                                <img src="@/assets/icons/flags/pl.svg" v-if="incidentInfo.reporter_locale === 'pl-PL'" v-tooltip.right="profile.nationalities.polish">
                                <img src="@/assets/icons/flags/ukr.svg" v-if="incidentInfo.reporter_locale === 'uk-UA'" v-tooltip.right="profile.nationalities.ukrainian">
                                <img src="@/assets/icons/flags/ru.svg" v-if="incidentInfo.reporter_locale === 'ru-RU'" v-tooltip.right="profile.nationalities.russian">
                                <img src="@/assets/icons/flags/de.svg" v-if="incidentInfo.reporter_locale === 'de-DE'" v-tooltip.right="profile.nationalities.german">
                                <img src="@/assets/icons/flags/fr.svg" v-if="incidentInfo.reporter_locale === 'fr-FR'" v-tooltip.right="profile.nationalities.french">
                            </span>
                            <span class="role-label role-label--specialist" style="display: inline; float: none;" v-if="incidentInfo.origin === 'mechanic_via_app'">{{profile.incidents.incidenttable.mechanic}}</span>
                        </span>
                        <span v-if="incidentInfo.origin !== 'service_desk_planned_action' && incidentInfo.origin !== 'recurring_action'">
                            <span style="float: left;" class="type-label" data-testid="incidentOwnerLabel" v-if="vve && incidentInfo.reporter_is_owner && industry === 'housing'">{{profile.incident_detail.owner}}</span>
                            <span style="float: left;" class="type-label" data-testid="incidentRenterLabel" v-if="vve && !incidentInfo.reporter_is_owner && industry === 'housing' && incidentInfo.origin !== 'mechanic_via_app'">{{profile.incident_detail.renter}}</span>
                        </span>
                    </p>  
                    <div class="info-row" v-if="(incidentInfo.reporter_address || incidentInfo.reporter_house_number || incidentInfo.reporter_zipcode)">
                        <label style="position: relative;">{{profile.incident_detail.address_reporter}}</label>
                        <p data-testid="addressText"><span v-if="incidentInfo.reporter_address && incidentInfo.reporter_address !== 'null'">{{ incidentInfo.reporter_address }}</span> <span v-if="incidentInfo.reporter_house_number && incidentInfo.reporter_house_number !== 'null'">{{ incidentInfo.reporter_house_number }},</span><span v-if="incidentInfo.reporter_zipcode !== 'null'">{{ incidentInfo.reporter_zipcode }}</span></p>
                    </div>
                    <div class="info-row" v-if="incidentInfo.reporter_phone">
                        <label style="position: relative;">{{profile.accountPage.account.contactmobile}}</label>
                        <p data-testid="phoneText" v-html="incidentInfo.reporter_phone" style="width: 85%;"></p>
                        <span v-if="incidentInfo.reporter_phone && incidentInfo.origin !== 'mechanic_via_app' && incidentInfo.origin !== 'service_desk_planned_action'" class="contactinfo whatsapp" v-tooltip.right="profile.incident_detail.copy_phone_number" style="margin-right: 3px;" @click.stop="copyPhone()"><i class="fas fa-copy" style="color: #80808e; font-size: 14px;"></i></span>
                   </div>  
                    <label style="position: relative;">{{profile.incident_detail.ref_number}}</label>
                    <p data-testid="referenceText" v-if="!refNumberEdit && incidentInfo.id_external" v-html="incidentInfo.id_external"></p>
                    <input type="number" class="form-control" data-testid="inputRefNumber" @keypress="isNumber($event)" v-if="refNumberEdit" v-model="incidentInfo.id_external" style="margin-bottom: 10px;" />
                </div>
                <div class="incident-info wide" style="margin-top: 10px;" v-if="incidentInfo.reporter_feedback || incidentInfo.repaircompany_feedback || incidentInfo.extra_mechanic">
                    <div v-if="incidentInfo.reporter_feedback">
                        <label>{{profile.incident_detail.feedback}} {{profile.incident_detail.no_chat_name}}</label>
                        <p>{{incidentInfo.reporter_feedback}}</p>
                    </div>
                </div>
                <label v-if="incidentInfo.buildingmanager_feedback">{{profile.incident_detail.remark}}</label>
                <p v-if="incidentInfo.buildingmanager_feedback">{{incidentInfo.buildingmanager_feedback}}</p>
            </section>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import { mapState } from 'vuex';

export default {
    name: 'OtherDetails',
    mixins: [setLocale],
    data: function () {
        return {
            refNumberEdit: false,
            mobileEdit: false,
            validPhone: false,
            invalidPhone: false,
            emailEdit: false,
            validEmail: false,
            invalidEmail: false,
            tempPhone: '',
            tempEmail: '',
            bindProps: {
                defaultCountry: "NL",
                disabledFetchingCountry: false,
                disabled: false,
                disabledFormatting: false,
                required: false,
                enabledCountryCode: false,
                enabledFlags: true,
                autocomplete: "off",
                name: "telephone",
                maxLen: 25,
                dropdownOptions: {
                    disabledDialCode: false,
                    showFlags: true
                },
                inputOptions: {
                    showDialCode: false,
                    placeholder: '',
                }
            },
            intCode: ''
        }
    },
    computed: mapState(['vve']),
    props: ['incidentInfo', 'readOnly', 'admin'],
    methods: {
        saveRefNumber() {
            this.refNumberEdit = false;
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentInfo.id_incident);
            data.append('id_external', this.incidentInfo.id_external);
            let self = this;
            fetch('/api/v1/incident/setidexternal', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                this.$emit('getIncidentInfo');
            });
        },
        isNumber(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        validatePhone: function (number, object) {
            this.intCode = object.countryCallingCode
            if (object.formatted) {
                if (object.valid) {
                    this.validPhone = true;
                    this.invalidPhone = false;
                    this.formattedPhone = object.number;
                }
                else {
                    this.validPhone = false;
                    this.invalidPhone = true;
                }
            }
            else {
                this.validPhone = false;
                this.invalidPhone = false;
            }
        },
        validateEmail() {
            if (this.tempEmail) {
                let pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
                if (pattern.test(this.tempEmail)) {
                    this.validEmail = true;
                    this.invalidEmail = false;
                }
                else {
                    this.invalidEmail = true;
                    this.validEmail = false;
                }
            }
            else {
                this.invalidEmail = false;
                this.validEmail = false;
            }
        },
        editMobile() {
            this.mobileEdit = true;
            this.tempPhone = this.incidentInfo.reporter_phone;
        },
        saveMobile() {
            if (this.validPhone) {
                this.mobileEdit = false;
                const data = new URLSearchParams();
                data.append('id_incident', this.incidentInfo.id_incident);
                data.append('reporter_phone', this.formattedPhone);
                let self = this;
                fetch('/api/v1/incident/updatereporterphone', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.$emit('getIncidentInfo');
                });
            }
        },
        editEmail() {
            this.emailEdit = true;
            this.tempEmail = this.incidentInfo.reporter_email_address;
        }, 
        saveEmail() {
            if (this.validEmail) {
                this.emailEdit = false;
                const data = new URLSearchParams();
                data.append('id_incident', this.incidentInfo.id_incident);
                data.append('reporter_email_address', this.tempEmail);
                let self = this;
                fetch('/api/v1/incident/updatereporteremail', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.$emit('getIncidentInfo');
                });
            }
        }, 
        copyPhone() {
            navigator.clipboard.writeText(this.incidentInfo.reporter_phone);
            this.$vToastify.success(this.profile.incident_detail.phone_number_copied, this.profile.toast.changes_saved);
        }
    }
}
</script>
