<template>
    <div>
        <div class="main-content">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <a class="back-link" @click="$router.go(-1)"><i class="fas fa-chevron-left"></i>{{profile.nav.back_link}}</a>
                    </div>
                </div>
                <div class="block">
                    <div class="profile-card asset" style="border-right: 0; width: 80%;">
                        <div class="row">
                            <div class="col-xs-12 col-sm-9">
                                <h4 style="text-transform: capitalize;">{{profile.assets.asset}}</h4>
                                <div class="inactive-label" v-if="!asset.qr_code">
                                    <i class="fas fa-qrcode"></i> {{profile.assets.no_sticker}}
                                </div>
                                <span class="active-label" data-testid="assetActiveText" v-if="!asset.hidden && asset.qr_code">{{profile.assets.assettable.active}}</span>
                                <span class="inactive-label" data-testid="assetInactiveText" v-if="asset.hidden">{{profile.assets.inactive}}</span>
                                <h2>{{asset.name}}</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-9">
                        <div class="block block--location_detail">
                            <div>
                                <section class="incident-head">
                                    <h1 data-testid="assetEditTitle">{{profile.accountPage.account.general}}</h1>
                                </section>
                                <section class="location-contents">
                                    <div class="location-info" style="margin-top: 20px;">
                                        <div>
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <label>{{profile.assets.assetmodal.place}}</label>
                                                    <p data-testid="assetLocationNameText" >{{placeInfo.name}}</p>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>{{profile.assets.assetmodal.id_asset}}</label>
                                                    <p data-testid="assetIdText">{{asset.id_asset}}</p>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <label>{{profile.assets.assetmodal.name}}</label>
                                                    <p data-testid="assetAssetNameText">{{asset.name}}</p>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>{{profile.assets.assetmodal.key_number}}</label>
                                                    <p data-testid="assetKeyText">{{asset.key_number}}</p>
                                                </div>
                                            </div>
                                            <hr style="margin-top: -10px;"/>
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <label>{{profile.assets.assetmodal.assettype}}</label>
                                                    <p data-testid="assetTypeText">{{assetType.display_name}}</p>
                                                </div>
                                            </div>
                                            <div class="row" v-if="assetType.extra_description_a || assetType.extra_description_b">
                                                <div class="col-sm-6" v-if="assetType.extra_description_a">
                                                    <label>{{assetType.extra_description_a}}</label>
                                                    <p data-testid="assetTypeAText">{{asset.extra_description_a}}</p>
                                                </div>
                                                <div class="col-sm-6" v-if="assetType.extra_description_b">
                                                    <label>{{assetType.extra_description_b}}</label>
                                                    <p data-testid="assetTypeBText">{{asset.extra_description_b}}</p>
                                                </div>
                                            </div>
                                            <div class="row" v-if="assetType.extra_description_c || assetType.extra_description_d">
                                                <div class="col-sm-6" v-if="assetType.extra_description_c">
                                                    <label>{{assetType.extra_description_c}}</label>
                                                    <p>{{asset.extra_description_c}}</p>
                                                </div>
                                                <div class="col-sm-6" v-if="assetType.extra_description_d">
                                                    <label>{{assetType.extra_description_d}}</label>
                                                    <p>{{asset.extra_description_d}}</p>
                                                </div>
                                            </div>
                                            <div class="row" v-if="assetType.extra_description_e || assetType.extra_description_f">
                                                <div class="col-sm-6" v-if="assetType.extra_description_e">
                                                    <label>{{assetType.extra_description_e}}</label>
                                                    <p>{{asset.extra_description_e}}</p>
                                                </div>
                                                <div class="col-sm-6" v-if="assetType.extra_description_f">
                                                    <label>{{assetType.extra_description_f}}</label>
                                                    <p>{{asset.extra_description_f}}</p>
                                                </div>
                                            </div>
                                            <div class="row" v-if="assetType.extra_description_g || assetType.extra_description_h">
                                                <div class="col-sm-6" v-if="assetType.extra_description_g">
                                                    <label>{{assetType.extra_description_g}}</label>
                                                    <p>{{asset.extra_description_g}}</p>
                                                </div>
                                                <div class="col-sm-6" v-if="assetType.extra_description_h">
                                                    <label>{{assetType.extra_description_h}}</label>
                                                    <p>{{asset.extra_description_h}}</p>
                                                </div>
                                            </div>
                                            <div class="row" v-if="assetType.extra_description_i || assetType.extra_description_j">
                                                <div class="col-sm-6" v-if="assetType.extra_description_i">
                                                    <label>{{assetType.extra_description_i}}</label>
                                                    <p>{{asset.extra_description_i}}</p>
                                                </div>
                                                <div class="col-sm-6" v-if="assetType.extra_description_j">
                                                    <label>{{assetType.extra_description_j}}</label>
                                                    <p>{{asset.extra_description_j}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                        <div class="block block--incident_detail" v-if="totalMedia">
                            <div class="incident-head">
                                <h1>{{profile.incident_detail.imagery}}</h1>
                            </div>
                            <div class="incident-media">
                                <div class="media-wrapper">
                                    <div class="media media--small" data-testid="showMediaItem" v-for="(item, index) in media" :key="index" :class="{active: item.popover, video: item.content_type === 'video/mp4'}" v-tooltip.left="item.description">
                                        <img :src="item.file_url" @click="openPopover(item)" v-if="item.content_type === 'image/jpeg' || item.content_type === null && !item.i_frame_code" />
                                        <div class="iframe_overlay" v-if="item.i_frame_code" @click="openPopover(item)" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 20;"></div>
                                        <div v-html="item.i_frame_code" v-if="item.i_frame_code" style="margin-top: -35px;"></div>
                                        <div class="video_overlay" @click="openPopover(item)" v-if="item.content_type === 'video/mp4'"><i class="fas fa-play"></i></div>
                                        <video v-if="item.content_type === 'video/mp4'" @click="openPopover(item)">
                                            <source :src="item.file_url" type="video/mp4">
                                        </video>
                                        <span class="label" v-if="profile.incident_detail[item.type]">{{profile.incident_detail[item.type]}}</span>
                                        <span class="remove" data-testid="removeMediaItem" v-if="(item.type === 'incident_building_manager' || admin)" @click="selectFileToRemove(item)"><i class="far fa-trash-alt"></i></span>
                                        <div class="media-pop-over">
                                            <span class="close-popover" @click="closePopover()"><i class="fa fa-times"></i></span>
                                            <span class="label" v-if="profile.incident_detail[item.type]">{{profile.incident_detail[item.type]}}</span>
                                            <span class="media-counter">{{index + 1}} / {{media.length}}</span>
                                            <img src="@/assets/icons/rotate.svg" class="rotate hidden-xs" @click="rotateMedia(item)" />
                                            <figure class="zoom" :style="{ 'background-image': 'url(' + item.file_url + ')' }" @mousemove="zoom" :class="{rotate_2: item.rotate === 2, rotate_3: item.rotate === 3, rotate_4: item.rotate === 4}">
                                                <img data-testid="mediaImageOverlay" @click="closePopover()" :src="item.file_url" v-if="item.content_type === 'image/jpeg' || item.content_type === null && !item.i_frame_code" :class="{rotate_2: item.rotate === 2, rotate_3: item.rotate === 3, rotate_4: item.rotate === 4}" />
                                            </figure>
                                            <div v-html="item.i_frame_code" v-if="item.i_frame_code" style="margin-top: 40px;"></div>
                                            <video data-testid="mediaVideoOverlay" v-if="item.content_type === 'video/mp4'" :class="{rotated: item.rotate}" controls><source :src="item.file_url" type="video/mp4"></video>
                                            <p v-if="item.description">{{item.description}}</p>
                                            <span class="media-control left" v-if="index !== 0" @click="selectMedia('prev', index)"><i class="fa fa-chevron-left"></i></span>
                                            <span class="media-control right" v-if="index + 1 !== media.length" @click="selectMedia('next', index)"><i class="fa fa-chevron-right"></i></span>
                                        </div>
                                        <p style="position: absolute; z-index: 1; background: rgba(0,0,0,.4); font-size: 12px; padding: 3px; color: white; border-radius: 0 0 5px 0;">{{ item.createdDate }}</p>
                                    </div>
                                    <div class="media video" data-testid="showMediaVideo" v-for="item in movs" :key="item.id_file_upload">
                                        <span class="label" v-if="profile.incident_detail[item.type]">{{profile.incident_detail[item.type]}}</span>
                                        <span class="placeholder"><i class="fas fa-file-video"></i></span>
                                        <a :href="item.file_url" class="video_overlay download" v-if="item.content_type === 'video/quicktime'"></a>
                                        <span class="remove" data-testid="buttonRemoveVideo" v-if="(item.type === 'incident_building_manager' || admin)" @click="selectFileToRemove(item)"><i class="far fa-trash-alt"></i></span>
                                        <p style="position: absolute; z-index: 1; background: rgba(0,0,0,.4); font-size: 12px; padding: 3px; color: white; border-radius: 0 0 5px 0;">{{ item.createdDate }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="block block--incident_detail location" style="min-height: 0;" v-if="asset.qr_code">
                            <section class="incident-head">
                                <h1>S1-Code</h1>
                            </section>
                            <img :src="'/api/v1/asset/qrcodeasimage?id_asset=' + asset.id_asset" class="qr-code" style="margin: 10px auto -5px auto; width: 150px; display: block;" v-tooltip.right="asset.qr_code" />
                        </div>
                        <div class="block block--incident_detail location">
                            <section class="incident-head" style="margin-bottom: 20px;">
                                <h1>{{profile.assets.assetmodal.place}}</h1>
                            </section>
                            <div class="row">
                                <div class="col">
                                    <a :href="mapsUrl" target="_blank">
                                        <img :src="mapsApiUrl" id="address-map" />
                                    </a>
                                </div>
                            </div>
                            <p style="margin-bottom: 0;">
                                <router-link tag="a" v-bind:to="'/owner/location-detail?id_place=' + asset.id_place">{{placeInfo.name}}</router-link>
                                {{placeInfo.address}} {{placeInfo.house_number}}<br />
                                {{placeInfo.zipcode}} {{placeInfo.city}}<br />
                                {{profile.incident_detail.floor_name}}: {{placeInfo.floor_name}}<br />
                            </p>
                        </div>
                        <div class="block block--incident_detail location" style="min-height: 0;" v-if="asset.categories && asset.categories.length">
                            <section class="incident-head">
                                <h1>{{ profile.assets.categories }}</h1>
                            </section>
                            <div class="row">
                                <div class="col">
                                    <div style="margin-top: 10px; max-height: 615px; overflow: auto;">
                                        <div v-for="category in asset.categories" :key="category.id_incident_category" class="list-item" style="cursor: auto;">
                                            <span v-if="locale === 'nl'">{{ category.dutch_name }}</span>
                                            <span v-else>{{ category.english_name }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
   </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import { mapState } from 'vuex';
import moment from 'moment';

export default {
    mixins: [setLocale],
    data() {
        return {
            profile: {},
            assetId: 0,
            asset: {},
            placeInfo: {},
            mapsApiUrl: '',
            mapsUrl: '',
            locationInfo: {},
            assetType: {},
            assetTypeChanged: false,
            media: [],
            movs: [],
            totalMedia: 0
        }
    },
    created() {
        document.title = 'S1MONE | ' + this.profile.incidents.incidenttable.asset;
        let queryString = window.location.search;
        this.assetId = queryString.split('=')[1];
        this.getAsset();
    },
    computed: mapState(['mapsKey']),
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        getAsset() {
            this.connectAssetModalOpen = false;
            this.removeFileModalOpen = false;
            this.movs = [];
            this.media = [];
            let self = this;
            fetch('/api/v1/asset/byid?id_asset=' + this.assetId)
            .then(response => { response.json().then(
                function(data) {
                    self.assetType = {};
                    self.assetTypeChanged = false;
                    if (data.file_upload) {
                        for (var i = 0; i < data.file_upload.length; i++) {
                            let media = data.file_upload[i];
                            console.log(media);
                            if (media.content_type !== 'video/quicktime' && media.file_url) {
                                media.popover = false;
                                media.rotate = 1;
                                media.order = 1;
                                media.selected = false;
                                let locale = localStorage.getItem('S1-locale');
                                if (locale === 'nl') {
                                    media.createdDate  = moment(media.created).locale('nl').format('D MMMM YYYY');
                                }
                                if (locale === 'en') {
                                    media.createdDate  = moment(media.created).locale('en').format('MMMM D YYYY');
                                }
                                self.media.push(media);
                            }
                            if (media.content_type === 'video/quicktime') {
                                self.movs.push(media);
                            }
                        }
                        self.totalMedia = self.media.length + self.movs.length;
                    }
                    self.asset = data;
                    self.selectedAssetTypeId = self.asset.id_asset_type;
                    if (self.asset.name === null) {
                        self.asset.name = '';
                    }
                    if (self.asset.key_number === null) {
                        self.asset.key_number = '';
                    }
                    if (self.asset.extra_description_a === null) {
                        self.asset.extra_description_a = '';
                    }
                    if (self.asset.extra_description_b === null) {
                        self.asset.extra_description_b = '';
                    }
                    if (self.asset.extra_description_c === null) {
                        self.asset.extra_description_c = '';
                    }
                    if (self.asset.extra_description_d === null) {
                        self.asset.extra_description_d = '';
                    }
                    if (self.asset.extra_description_e === null) {
                        self.asset.extra_description_e = '';
                    }
                    if (self.asset.extra_description_f === null) {
                        self.asset.extra_description_f = '';
                    }
                    if (self.asset.extra_description_g === null) {
                        self.asset.extra_description_g = '';
                    }
                    if (self.asset.extra_description_h === null) {
                        self.asset.extra_description_h = '';
                    }
                    if (self.asset.extra_description_i === null) {
                        self.asset.extra_description_i = '';
                    }
                    if (self.asset.extra_description_j === null) {
                        self.asset.extra_description_j = '';
                    }
                    self.getPlace();
                    if (self.asset.partof_id_asset) {
                        self.isSpace = false;
                    }
                    if (self.asset.id_external === 'null') {
                        self.asset.id_external = '';
                    }
                });
            });
        },
        getPlace() {
            let self = this;
            fetch('/api/v1/place/byid?id_place=' + this.asset.id_place)
            .then(response => { response.json().then(
                function(data) {
                    self.placeInfo = data;
                    self.selectedPlaceName = self.placeInfo.name;
                    // Add space in zipcode if it ends in two letters
                    if (/[a-zA-Z]{2}$/.test(self.placeInfo.zipcode)) {
                        self.placeInfo.zipcode = self.placeInfo.zipcode.substring(0, self.placeInfo.zipcode.length - 2) + ' ' + self.placeInfo.zipcode.substring(self.placeInfo.zipcode.length - 2);
                    }
                    let gmapsAddress    = self.placeInfo.address + "+" + self.placeInfo.house_number + "+"+self.placeInfo.city.replace(/ /g , "+");
                    self.mapsApiUrl     = 'https://maps.googleapis.com/maps/api/staticmap?markers='+
                                           gmapsAddress+
                                            '&zoom=16&size=150x150&key=' + self.mapsKey;
                    self.mapsUrl        = "https://www.google.com/maps/place/"+gmapsAddress;
                });
            });
        },
        zoom(e){
            let offsetX;
            let offsetY;
            let x;
            let y;
            let zoomer = e.currentTarget;
            e.offsetX ? offsetX = e.offsetX : offsetX = e.touches[0].pageX
            e.offsetY ? offsetY = e.offsetY : offsetX = e.touches[0].pageX
            x = offsetX/zoomer.offsetWidth*100
            y = offsetY/zoomer.offsetHeight*100
            zoomer.style.backgroundPosition = x + '% ' + y + '%';
        },
        selectMedia(type, index) {
            if (type === 'prev') {
                index = index - 1;
                for (var i = 0; i < this.media.length; i++) {
                    this.media[i].popover = false;
                    this.media[index].popover = true;
                }
            }
            if (type === 'next') {
                index = index + 1;
                for (var j = 0; j < this.media.length; j++) {
                    this.media[j].popover = false;
                    this.media[index].popover = true;
                }
            }
        },
        openPopover(item) {
            item.popover = true;
            console.log(item);
            document.querySelector('body').classList.add('media-open');
        },
        closePopover() {
            document.querySelector('body').classList.remove('media-open');
            for (var i = 0; i < this.media.length; i++) {
                this.media[i].popover = false;
            }
        },
        rotateMedia(item) {
            item.rotate++;
            if (item.rotate === 5) {
                item.rotate = 1;
            }
        },
        toIncident(id) {
            this.$router.push('/owner/incident-detail?id=' + id);
        }
    }
}
</script>
