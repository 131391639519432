<template>
    <div class="modal-wrapper" v-if="profile.repair_companies">
        <div class="modal">
            <div class="modal-header">
                <h3 data-testid="newRepTitle"><i class="fas fa-toolbox"></i>{{profile.repair_companies.repaircompanytable.createrepaircompany}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner higher">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="createRepairCompany()" style="overflow: hidden;">
                    <h6 style="margin-bottom: 10px;" v-if="fields.country === 'NL'">{{profile.repair_companies.repaircompanytable.search_name_kvk}}</h6>
                    <div class="row">
                        <div class="col-md-6">
                            <select class="form-select" v-model="fields.country" required @change="clearValues()">
                                <option disabled>{{profile.places.placetable.country}} *</option>
                                <option value="NL">{{profile.places.placetable.NL}}</option>
                                <option value="other">{{profile.repair_companies.repaircompanytable.other}}</option>
                            </select>
                        </div>
                        <div class="col-md-6" v-if="fields.country === 'other'">
                            <div class="form-field" :class="{focus:fields.country_name.length}" style="margin-bottom: 0;">
                                <label>{{profile.places.placetable.country}}</label>
                                <input type="text" class="form-control" v-model="fields.country_name" :class="{active: fields.country_name.length}" style="margin-bottom: 0;">
                            </div>
                        </div>
                    </div> 
                    <div class="row">
                        <div class="col-md-6">
                            <div class="kvk-wrap">
                                <div class="form-field" :class="{focus:fields.name.length}" @keyup="checkKvk('name')" style="margin-bottom: 0;">
                                    <label>{{profile.repair_companies.repaircompanytable.name}} *</label>
                                    <input type="text" ref="name" data-testid="newRepName" class="form-control" v-model="fields.name" :class="{active: kvkCompanies.length}" style="margin-bottom: 0;" required>
                                </div>
                                <div class="companies" v-if="kvkCompanies.length && kvkSearchType === 'Name'">
                                    <div class="company" v-for="(company, index) in kvkCompanies" :key="index" @click="selectKvkCompany(company)">
                                        <h4>{{company.name}}</h4>
                                        <span class="kvknumber">{{company.kvkNumber}}</span>
                                        <span class="address" v-if="company.address && company.address.streetName">{{company.address.streetName}}, {{company.address.city}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="kvk-wrap">
                                <div class="form-field" :class="{focus:fields.kvk.length}"  style="margin-bottom: 0;">
                                    <label v-if="fields.country === 'NL'">{{profile.repair_companies.repaircompanytable.kvk}} *</label>
                                    <label v-if="fields.country === 'other'">{{profile.repair_companies.repaircompanytable.foreign_kvk}} *</label>
                                    <input type="text" data-testid="newRepKvk" class="form-control" v-model="fields.kvk" @keyup="checkKvk('number')" :class="{error: kvkError}" style="margin-bottom: 0;" required>
                                </div>
                                <div class="companies" v-if="kvkCompanies.length && kvkSearchType === 'Number'">
                                    <div class="company" v-for="(company, index) in kvkCompanies" :key="index" @click="selectKvkCompany(company)">
                                        <h4>{{company.name}}</h4>
                                        <span class="kvknumber">{{company.kvkNumber}}</span>
                                        <span class="address" v-if="company.address && company.address.streetName">{{company.address.streetName}}, {{company.address.city}}</span>
                                    </div>
                                </div>
                                <span class="notification notification--error" v-if="kvkError" style="margin-top: 15px;">{{profile.kvk_error}}</span>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="row" v-if="kvkExists === 'false'" style="margin-bottom: 10px;">
                        <div class="col">
                            <div class="switch-wrapper switch-wrapper--full" :class="{active: fields.onlyEmail}">
                                <div class="inner" @click.stop="changeOnlyEmail()">
                                    <i class="fa-regular fa-envelope"></i>
                                    <p>{{profile.repair_companies.repaircompanydetail.onlyemail}} <i class="fas fa-info-circle" v-tooltip.right="profile.repair_companies.repaircompanydetail.onlyemail_tooltip" style="cursor: pointer;"></i></p>
                                    <span class="label">{{profile.switches.only_email}}</span>
                                    <label class="switch">
                                        <span class="slider round" data-testid="newRepOnlyEmail"></span>
                                    </label>
                                </div>    
                            </div>
                            <div class="switch-wrapper switch-wrapper--full" :class="{active: fields.noComm}">
                                <div class="inner" @click.stop="changeNoComm()">
                                    <i class="fa-regular fa-ban"></i>
                                    <p>{{profile.repair_companies.repaircompanydetail.nocommunication}} <i class="fas fa-info-circle" v-tooltip.right="profile.repair_companies.repaircompanydetail.nocommunication_tooltip" style="cursor: pointer;"></i></p>
                                    <span class="label">{{profile.switches.no_comm}}</span>
                                    <label class="switch">
                                        <span class="slider round" data-testid="newRepNoComm"></span>
                                    </label>
                                </div>    
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="kvkExists === 'false'">
                        <div class="col-md-6">
                            <div class="form-field" :class="{focus:fields.email.length, error: emailExists === 'true', valid: validEmail, error: invalidEmail}"  @input="validateEmail()" v-tooltip.right="profile.repair_companies.repaircompanytable.email_tooltip" style="cursor: pointer;">
                                <label>{{profile.repair_companies.repaircompanytable.email}} * <i class="fas fa-info-circle"></i></label>
                                <input type="text" data-testid="newRepEmail" class="form-control" v-model="fields.email" required>
                                <span class="notification notification--error" v-if="emailExists === 'true'">{{profile.email_exists}}</span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-field" :class="{focus:fields.phone.length, valid: validPhoneTwo, error: invalidPhoneTwo}" @input="validatePhoneTwo()">
                                <label>{{profile.repair_companies.repaircompanytable.phone}}</label>
                                <input type="text" data-testid="newRepPhone" class="form-control" v-model="fields.phone">
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="kvkExists === 'false'">
                        <div class="col-md-6">
                            <div class="form-field" :class="{focus:fields.contact_name.length}">
                                <label>{{profile.repair_companies.repaircompanytable.contact_name}} *</label>
                                <input type="text" data-testid="newRepContactName" class="form-control" v-model="fields.contact_name" required>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-field" :class="{focus:fields.mobile.length, valid: validPhone, error: invalidPhone}" @input="validatePhone()">
                                <label>{{profile.repair_companies.repaircompanytable.mobile}}</label>
                                <input type="text"  data-testid="newRepMobile" class="form-control" v-model="fields.mobile">
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="kvkExists === 'false'">
                        <div class="col">
                            <div class="form-field" :class="{focus:fields.corrEmail.length}" v-tooltip.right="profile.repair_companies.repaircompanytable.corrEmail_tooltip" style="cursor: pointer;">
                                <label>{{profile.repair_companies.repaircompanytable.corrEmail}} * <i class="fas fa-info-circle"></i></label>
                                <input type="text" data-testid="newRepCorrEmail" class="form-control" v-model="fields.corrEmail" required>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="kvkExists === 'false'">
                        <div class="col">
                            <div class="form-field" :class="{focus:fields.description.length}">
                                <textarea class="form-control" data-testid="newRepDescription" v-model="fields.description"></textarea>
                                <label>{{profile.repair_companies.repaircompanytable.extra_info}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="kvkExists === 'true'">
                        <div class="col">
                            <div class="notification">
                                <p style="margin-bottom: 20px;">{{profile.repair_companies.repaircompanytable.rep_already_exists}}</p>
                                <h4>{{selectedkvkCompany.name}}</h4>
                                <span class="kvknumber">{{profile.repair_companies.repaircompanytable.kvk}}: {{selectedkvkCompany.kvkNumber}}</span>
                                <span class="address">{{selectedkvkCompany.address.streetName}}, {{selectedkvkCompany.address.city}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="notification notification--error" v-if="noKvkMatch">{{profile.no_kvk_match}}</div>
                    <button type="submit" class="button button--icon" data-testid="newRepSubmit"><i class="fas fa-toolbox"></i>{{profile.repair_companies.repaircompanytable.createrepaircompany}}</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import uniqBy from 'lodash/uniqBy';
import debounce from 'lodash/debounce';

export default {
    mixins: [setLocale],
    name: 'newRepairCompanyModal',
    data() {
        return {
            fields: {
                country: 'NL',
                country_name: '',
                name: '',
                contact_name: '',
                kvk: '',
                email: '',
                corrEmail: '',
                mobile: '',
                phone: '',
                description: '',
                onlyEmail: false,
                noComm: false
            },
            kvkExists: 'false',
            emailExists: 'false',
            kvkError: false,
            validPhone: false,
            invalidPhone: false,
            validPhoneTwo: false,
            invalidPhoneTwo: false,
            validEmail: false,
            invalidEmail: false,
            invalidKvk: false, 
            kvkCompanies: [],
            kvkSearchType: null,
            selectedkvkCompany: {},
            noKvkMatch: false
        }
    },
    created() {
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    mounted() {
        this.$refs.name.focus();
    },
    methods: {
        clearValues() {
            this.fields.name = '';
            this.fields.kvk = '';
            this.fields.kvkExists = 'false';  
            this.kvkError = false;
            this.invalidKvk = false;      
        },
        kvkCheck() {
            if (this.fields.country === 'NL') {
                let self = this;
                fetch('/api/v1/kvkexists?kvk=' + this.fields.kvk)
                .then(response => { response.text().then(
                    function(data) {
                        if (data !== 'false') {
                            self.kvkExists = 'true';
                        }
                        else {
                            self.kvkExists = 'false';
                        }
                    });
                });
            }
        },
        createRepairCompany() {
            if (this.kvkExists === 'true' && this.fields.country === 'NL') {
                this.connectExistingKvk();
            }
            else {
                this.checkEmail();
            }
        },
        connectExistingKvk() {
            const data = new URLSearchParams();
            let kvkNumber = this.fields.kvk.toString();
            data.append('reg_number', kvkNumber);
            let self = this;
            fetch('/api/v1/repaircompany/linkbmtorep', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.closeModal();
            });
        },
        changeOnlyEmail() {
            this.fields.onlyEmail = !this.fields.onlyEmail;
            this.fields.noComm = false;
        },
        changeNoComm() {
            this.fields.noComm = !this.fields.noComm;
            this.fields.onlyEmail = false;
        },
        checkEmail() {
            let self = this;
            fetch('/api/v1/emailexists?email=' + this.fields.email)
            .then(response => { response.text().then(
                function(data) {
                    self.emailExists = data;
                    self.checkKvkMatch();
                });
            });
        },
        checkKvk: debounce(function (e) {
            if (this.fields.country === 'NL') {
                this._checkKvk(e);
            }
        }, 500),
        _checkKvk(e) {
            if (e === 'name') {
                this.kvkSearchType = 'Name';
                let companyName = this.fields.name.toLowerCase();
                if (companyName.length > 2) {
                    let self = this;
                    fetch('/api/v1/kvk/companies?tradename=' + companyName)
                    .then(response => { response.json().then(
                        function(data) {
                            if (data.resultaten && data.resultaten.length) {
                                data.resultaten = uniqBy(data.resultaten, 'kvkNumber');
                                self.kvkCompanies = data.resultaten;
                                self.kvkError = false;
                            }
                            else {
                                self.kvkCompanies = [];
                                self.kvkError = true;
                            }
                        });
                    });
                }
                else {
                    this.kvkCompanies = [];
                    this.kvkExists = 'false';

                }
            }
            if (e === 'number') {
                this.kvkSearchType = 'Number';
                let kvkNumber = this.fields.kvk.toString();
                if (kvkNumber.length > 2) {
                    let self = this;
                    fetch('/api/v1/kvk/companies?regnumber=' + kvkNumber)
                    .then(response => { response.json().then(
                        function(data) {
                            if (data.resultaten && data.resultaten.length) {
                                data.resultaten = uniqBy(data.resultaten, 'kvkNumber');
                                self.kvkCompanies = data.resultaten;
                            }
                            else {
                                self.kvkCompanies = [];
                                self.kvkError = true;
                            }
                        });
                    });
                }
                else {
                    this.kvkCompanies = [];
                    this.kvkExists = 'false';
                }
            }
        },
        selectKvkCompany(company) {
            this.selectedkvkCompany = company;
            this.kvkCompanies = [];
            this.fields.name = company.name;
            this.fields.kvk = company.kvkNumber;
            this.kvkCheck();
        },
        checkKvkMatch() {
            if (this.fields.kvk.length < 8 && this.fields.country === 'NL') {
                this.kvkError = true;
            }
            else {
                this.kvkError = false;
            }
            if (this.fields.name && !this.kvkError) {
                this.saveRepairCompany();
           }       
        },
        saveRepairCompany() {
            const data = new URLSearchParams();
            let nameParam = 'data[0][name]';
            let contactNameParam = 'data[0][contact_name]';
            let kvkParam = 'data[0][kvk]';
            let emailParam = 'data[0][email]';
            let corrEmailParam = 'data[0][email_addresses]';
            let mobileParam = 'data[0][mobile]';
            let phoneParam = 'data[0][phone]';
            let descriptionParam = 'data[0][description]';
            let onlyEmailParam = 'data[0][only_email]';
            let noCommParam = 'data[0][no_communication]';
            let countryParam = 'data[0][country]';
            let countryNameParam = 'data[0][country_name]';
            data.append('action', 'create');
            data.append(nameParam, this.fields.name);
            data.append(contactNameParam, this.fields.contact_name);
            data.append(kvkParam, this.fields.kvk);
            data.append(emailParam, this.fields.email);
            data.append(corrEmailParam, this.fields.corrEmail);
            data.append(mobileParam, this.fields.mobile);
            data.append(phoneParam, this.fields.phone);
            data.append(descriptionParam, this.fields.description);
            data.append(onlyEmailParam, this.fields.onlyEmail);
            data.append(noCommParam, this.fields.noComm);
            if (this.fields.country === 'other' && this.fields.country_name) {
                data.append(countryNameParam, this.fields.country_name);
            }
            if (this.fields.country === 'NL') {
                data.append(countryParam, 'NL');
            }
            else {
                data.append(countryParam, 'other');
            }
            if (!this.fields.mobile) {
                this.invalidPhone = false;
            }
            if (!this.fields.phone) {
                this.invalidPhoneTwo = false;
            }
            if (!this.fields.email) {
                this.invalidEmail = true;
            }
            if (this.emailExists === 'false' && this.kvkExists === 'false' && this.validEmail && !this.invalidPhone && !this.invalidPhoneTwo) {
                let self = this;
                fetch('/api/v1/repaircompany', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.closeModal();
                });
            }
        },
        validateEmail() {
            let pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            if (pattern.test(this.fields.email)) {
                this.validEmail = true;
                this.invalidEmail = false;
                this.fields.corrEmail = this.fields.email;
            }
            else {
                this.invalidEmail = true;
                this.validEmail = false;
                this.fields.corrEmail = '';
            }
        },
        validatePhone() {
            if (this.fields.mobile) {
                let pattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
                if (pattern.test(this.fields.mobile)) {
                    this.validPhone = true;
                    this.invalidPhone = false;
                }
                else {
                    this.invalidPhone = true;
                    this.validPhone = false;
                }
            }
            else {
                this.invalidPhone = false;
                this.validPhone = false;

            }
        },
        validatePhoneTwo() {
            if (this.fields.phone) {
                let pattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
                if (pattern.test(this.fields.phone)) {
                    this.validPhoneTwo = true;
                    this.invalidPhoneTwo = false;
                }
                else {
                    this.invalidPhoneTwo = true;
                    this.validPhoneTwo = false;
                }
            }
            else {
                this.invalidPhoneTwo = false;
                this.validPhoneTwo = false;

            }
        },
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        }
    }
}
</script>
