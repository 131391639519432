<template>
    <div>
        <div class="main-content">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <a class="back-link" @click="$router.go(-1)"><i class="fas fa-chevron-left"></i>Terug naar vorige</a>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="block block--owners" >
                            <section class="incident-head">
                                <h1>Leverancier detail</h1>
                            </section> 
                            <div>
                                <h3 style="margin-top: 30px;">{{ supplier.name }}</h3>
                                <img :src="qrCodeUrl" alt="QR Code" v-if="qrCodeUrl" />
                            </div>                        
                        </div>
                    </div>
                </div>
            </div>    
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';

export default {
    mixins: [setLocale],
    data() {
        return {
            supplierID: null,
            supplier: {},
            qrCodeUrl: null
        }
    },
    created() {
        document.title = 'S1MONE | Supplierdetail';
        let queryString = window.location.search;
        this.supplierID = queryString.split('=')[1];
        this.supplierID = this.supplierID.split('&')[0];
        this.loadSupplier();
    },
    methods: {
        loadSupplier() {
            let self = this;
            fetch('/api/v1/repaircompany/byid?id_repair_company=' + self.supplierID)
            .then(response => { response.json().then(
                function(data) {
                    self.supplier = data;
                    self.getQrCode(); // Fetch QR code after loading supplier
                });
            });
        },
        getQrCode(){
            let self = this;
            fetch('/api/v1/repaircompany/qrcodeasimage?id_repair_company=' + self.supplierID)
            .then(response => { response.blob().then(
                function(data) {
                    self.qrCodeUrl = window.URL.createObjectURL(data);
                });
            });
        }
    }
}
</script>
