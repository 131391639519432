<template>
    <div class="modal-wrapper">
        <div class="modal">
            <div class="modal-header">
                <h3> Wijs stickers toe aan beheerder </h3>
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <div class="row">
                    <div class="col-sm-3">
                        <span class="control-label" style="right:10px; position: absolute; margin-top: 14px">Vanaf object</span>
                    </div>
                    <div class="col-sm-9">
                        <div class="dropdown-section">
                            <span class="dropdown" v-on:click="fromAssetDropdownToggle = !fromAssetDropdownToggle" :class="{active: fromAssetDropdownToggle}">
                                <span v-if="fields.selectedStickerRange.from_id_asset" class="selected">{{fields.selectedStickerRange.from_id_asset}}</span>
                                <span v-else class="selected">Kies een object</span>
                                <i class="fas fa-chevron-down chevron"></i>
                            </span>
                            <div class="dropdown-list" v-if="fromAssetDropdownToggle" :class="{search: stickerRanges.length > 9}">
                                <ul>
                                    <li v-for="range in stickerRanges" v-on:click="selectStickerRange(range)" :key="range.id_sticker_history">
                                        {{range.from_id_asset}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-1"></div>
                </div>
                <div class="row">
                    <div class="col-sm-3">
                        <span class="control-label" style="right:10px; position: absolute; margin-top: 14px">Tot en met object</span>
                    </div>
                    <div class="col-sm-9">
                        <div class="dropdown-section">
                            <span class="dropdown" v-on:click="toAssetDropdownToggle = !toAssetDropdownToggle" :class="{active: toAssetDropdownToggle}">
                                <span v-if="fields.to_id_asset" class="selected">{{fields.to_id_asset}}</span>
                                <span v-else-if="fields.from_id_asset" class="selected">Kies een object</span>
                                <i class="fas fa-chevron-down chevron"></i>
                            </span>
                            <div class="dropdown-list" v-if="toAssetDropdownToggle" :class="{search: stickerRanges.length > 9}">
                                <ul>
                                    <li v-for="number in generateNumberRange(fields.selectedStickerRange.from_id_asset, fields.selectedStickerRange.to_id_asset)" v-on:click="selectToAsset(number)" :key="number">
                                        {{ number }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-1"></div>
                </div>
                <div class="row">
                    <div class="col-sm-3">
                        <span class="control-label" style="right:10px; position: absolute; margin-top: 14px">Beheerder</span>
                    </div>
                    <div class="col-sm-9">
                        <div class="dropdown-section">
                            <span class="dropdown" v-on:click="managerDropdownToggle = !managerDropdownToggle" :class="{active: managerDropdownToggle}">
                                <span v-if="fields.selectedManager.id_building_manager" class="selected">{{fields.selectedManager.name}}</span>
                                <span v-else class="selected">Kies een beheerder</span>
                                <i class="fas fa-chevron-down chevron"></i>
                            </span>
                            <div class="dropdown-list" v-if="managerDropdownToggle" :class="{search: managers.length > 9}">
                                <ul>
                                    <li v-for="manager in managers" v-on:click="selectManager(manager)" :key="manager.id_building_manager">
                                        {{manager.name}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-1"></div>
                </div>
                <button class="button button--icon button--right" style="margin-top:20px" v-on:click="assignStickers()"><i class="fa-solid fa-arrow-right"></i>Wijs stickers toe</button>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import sortBy from 'lodash/sortBy';
export default {
    mixins: [setLocale],
    name: 'NewStickerModal',
    data() {
        return {
            profile: {},
            managers: {},
            stickerRanges: {},
            fields: {
                selectedManager: {},
                to_id_asset: '',
                selectedStickerRange: {},
            },
            fromAssetDropdownToggle: false,
            toAssetDropdownToggle: false,
            managerDropdownToggle: false,
        }
    },
    created() {
        document.querySelector('body').classList.add('modal-open');
        this.loadManagers();
        this.getStickerRanges();
        document.onkeydown = (evt) => {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                this.closeModal();
            }
        };
    },
    methods: {
        getStickerRanges(){
            let self = this;
            fetch('/api/v1/stickercreation/getavailablestickerranges')
            .then(response => { response.json().then(
                function(data) {
                    self.stickerRanges = data;
                });
            });
        },
        loadManagers(){
            let self = this;
            fetch('/api/v1/buildingmanager?')
            .then(response => { response.json().then(
                function(data) {
                    self.managers = data.data;
                });
            });
        },
        selectManager(manager) {
            this.managerDropdownToggle = false;
            this.fields.selectedManager = manager;
        },
        selectStickerRange(range) {
            this.fromAssetDropdownToggle = false;
            this.fields.selectedStickerRange = range;
            this.fields.from_id_asset = range.from_id_asset;
        },
        selectToAsset(number) {
            this.toAssetDropdownToggle = false;
            this.fields.to_id_asset = number;
        },
        generateNumberRange(from, to){
            let range = [];
            for (let i = from; i <= to; i++) {
                range.push(i);
            }
            return range;
        },
        assignStickers(){
            let self = this;
            fetch('/api/v1/stickercreation/assign?id_building_manager='+this.fields.selectedManager.id_building_manager 
                +'&id_sticker_history=' + this.fields.selectedStickerRange.id_sticker_history 
                +'&from_id_asset='+ this.fields.selectedStickerRange.from_id_asset
                +'&to_id_asset=' + this.fields.to_id_asset, {
                method: 'get',
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.closeModal();
            });
        },
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        }
    }
}


</script>
